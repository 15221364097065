<template>
  <main id="main" class="pt-4 pb-0">
  
    <!-- ▼ ボタン＆バナー -->
    <div class="container normal mb-5">
      <div class="row gx-5">
        <div class="col-md-6 mb-4 mb-md-5" v-if="!isLogin"><a :href="cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+rtn" class="btn-arrow"><span class="tag">会員の方</span><br><span class="big">ログイン</span>はこちら</a></div>
        <div class="col-md-6 mb-4 mb-md-5" v-if="!isLogin"><a :href="cart+'/v2/fcJoin.aspx?ccode=BYU'" class="btn-arrow transparent"><span class="tag">入会ご希望の方</span><br><span class="big">入会</span>はこちら</a></div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 29.3" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1839" data-name="グループ 1839"><g id="FAQ" class="cls-1"><g class="cls-1"><path class="cls-2" d="M8.5,8.23v6.3H7.25V8.23Zm-.43,0H10.9V9.37H8.07Zm0,2.54h2.74v1.08H8.07Z"/><path class="cls-2" d="M13,12.45l0,.29-.82,1.79H10.78L14,7.86l3.21,6.67H15.7l-.8-1.72v-.35L14,10.29Zm-.56-.37h3l.16,1H12.32Z"/><path class="cls-2" d="M17.48,10.06a3.22,3.22,0,0,1,.71-1,3.27,3.27,0,0,1,1.06-.68,3.78,3.78,0,0,1,2.65,0A3.22,3.22,0,0,1,23,9a3.18,3.18,0,0,1,.68,1,3.26,3.26,0,0,1,.25,1.31,3.6,3.6,0,0,1-.24,1.32A3,3,0,0,1,23,13.75a3.27,3.27,0,0,1-1.05.7,3.62,3.62,0,0,1-1.33.25,3.68,3.68,0,0,1-1.35-.25,3.21,3.21,0,0,1-1.06-.7,3.07,3.07,0,0,1-.7-1.05,3.52,3.52,0,0,1-.24-1.32A3.34,3.34,0,0,1,17.48,10.06Zm1.27,2.2a1.87,1.87,0,0,0,1.09,1.11,2.11,2.11,0,0,0,.8.16,2.07,2.07,0,0,0,.8-.15,1.78,1.78,0,0,0,1-1.05,2.43,2.43,0,0,0,.14-.82,2.66,2.66,0,0,0-.14-.85,2.31,2.31,0,0,0-.41-.7,2.07,2.07,0,0,0-.65-.48,2.16,2.16,0,0,0-.86-.17,1.89,1.89,0,0,0-.79.16,1.76,1.76,0,0,0-.64.43,2,2,0,0,0-.41.66,2.43,2.43,0,0,0-.14.82A2.3,2.3,0,0,0,18.75,12.26Zm2.82-.43,2.94,2.78H23.07l-2.9-2.78Z"/></g></g><path class="cls-2" d="M8.06,29.3V23H5.5A5.52,5.52,0,0,1,0,17.5V5.5A5.52,5.52,0,0,1,5.5,0h19A5.52,5.52,0,0,1,30,5.5v12A5.52,5.52,0,0,1,24.5,23H14.35ZM9.56,20h1.5v2.05l2-2.05H24.5A2.51,2.51,0,0,0,27,17.5V5.5A2.52,2.52,0,0,0,24.5,3H5.5A2.51,2.51,0,0,0,3,5.5v12A2.51,2.51,0,0,0,5.5,20Z"/></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                ファンクラブよくある質問
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq/FC" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 29.65" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1842" data-name="グループ 1842"><path id="パス_2370" data-name="パス 2370" class="cls-1" d="M24.91,17.74a11.57,11.57,0,0,1-12.8,9.79l8.4-12A10.16,10.16,0,0,1,19.09,14l-2.66,3.81L11,10h5.14L14,13.11l1.24,1.77,2.45-3.51A9.93,9.93,0,0,1,17,8H9A1,1,0,0,0,8,9a1,1,0,0,0,.19.58l7,10L10,27.05a10.86,10.86,0,0,1-2.49-1.16l5.64-8.06-1.24-1.77-1.24,1.77L4.48,9A11.51,11.51,0,0,1,17.42,5.29a9.8,9.8,0,0,1,.74-1.88,13.53,13.53,0,1,0,8.08,17.34A13.39,13.39,0,0,0,26.93,18,9.84,9.84,0,0,1,24.91,17.74ZM2,16.12A11.54,11.54,0,0,1,3.3,10.87L9.41,19.6,5.86,24.68A11.44,11.44,0,0,1,2,16.12Z"/><path id="パス_2371" data-name="パス 2371" class="cls-1" d="M27,0a8,8,0,1,0,8,8A8,8,0,0,0,27,0Zm-.1,13a1.22,1.22,0,1,1,1.22-1.22h0A1.22,1.22,0,0,1,26.93,13Zm1.91-5.35c-.6.46-.82.63-.82,1.22C28,9.32,28,10,27,10c-.82,0-1.12-.27-1.12-1.07a2.43,2.43,0,0,1,1-2.1c1-.7,1.25-.9,1.25-1.26,0-.64-.86-.64-1-.64-1.06,0-1.13.6-1.17,1s-.53.56-1.12.56c-.38,0-1.14-.08-1.14-.83s.76-2.68,3.51-2.68c2.1,0,3.22,1.3,3.22,2.58,0,1-.51,1.35-1.55,2.18Z"/></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                ファンクラブご利用ガイド
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq/EC" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 30.34" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1834" data-name="グループ 1834"><g id="グループ_1833" data-name="グループ 1833"><path id="パス_2372" data-name="パス 2372" class="cls-1" d="M22.32,23.48a3.39,3.39,0,0,0-2.61,1.23H11.65a3.43,3.43,0,0,0-1.92-1.16l-.41-2H24.59l.61-4.13a9.49,9.49,0,0,1-2.27-1l-.41,2.76H16.78V16.16h5.83a10,10,0,0,1-2.5-2.4H16.78V10.81h1.84a9.36,9.36,0,0,1-.41-2.4H6.65l-.6-2.95H0v2.4H4.08L7.36,23.94a3.42,3.42,0,1,0,5.09,3.18h6.46a3.42,3.42,0,1,0,3.41-3.64Zm-7.93-4.37H8.83l-.6-2.95h6.16Zm-7.25-8.3h7.25v2.95H7.74ZM10,27.11a1,1,0,0,1-2,0,.65.65,0,0,1,0-.2,1,1,0,1,1,2.05,0,.78.78,0,0,1,0,.21Zm12.29.82a1,1,0,0,1-1-.82,1.18,1.18,0,0,1,0-.2,1,1,0,0,1,1-1h0a1,1,0,0,1,0,2.05Z"/><path id="パス_2373" data-name="パス 2373" class="cls-1" d="M28.08,0A7.92,7.92,0,1,0,36,7.92,7.91,7.91,0,0,0,28.08,0ZM28,12.93a1.21,1.21,0,1,1,1.21-1.21h0A1.21,1.21,0,0,1,28,12.93Zm1.89-5.3c-.59.45-.81.62-.81,1.2,0,.41,0,1.07-1,1.07-.82,0-1.12-.27-1.12-1.07a2.4,2.4,0,0,1,1-2.07c1-.7,1.24-.9,1.24-1.26,0-.63-.86-.63-1-.63-1,0-1.11.6-1.16,1s-.52.55-1.1.55c-.38,0-1.14-.08-1.14-.82S25.5,2.9,28.22,2.9c2.09,0,3.2,1.29,3.2,2.57,0,.94-.51,1.33-1.54,2.16Z"/></g></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                通販ご利用ガイド
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- ▲ ボタン＆バナー -->
    
    <!-- ▼ ファンクラブよくある質問 -->
    <div id="info">
      <div class="container title-block">
        <h2><div class="container normal">ファンクラブよくある質問</div></h2>
      </div>
      <div class="container normal faq">
        <h3>ログインについて</h3>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_1" aria-expanded="false" aria-controls="faq1_1">ログインできません</h4>
          <div class="collapse multi-collapse answer" id="faq1_1">
            <p>「お客様の会員番号もしくは登録のメールアドレス」と「会員様自身で設定されたパスワード」でログインしてください。<br>会員番号やメールアドレス、パスワードは半角英数字でご入力ください。その際、大文字・小文字などの間違いがないことをご確認ください。</p>
            <p>ログインパスワードを忘れた方は下記からパスワードの再設定をおこなってください。<br><a :href="cart+'/v2/Passforgot.aspx?ccode=BYU'" target="_blank">>>パスワードの再設定</a></p>
          </div>
        </div>
        <div class="faq-container border-bottom">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_2" aria-expanded="false" aria-controls="faq1_2">ログインパスワードを忘れました、変更したいです</h4>
          <div class="collapse multi-collapse answer" id="faq1_2">
            <p>下記よりパスワードの再設定を行ってください。<br><a :href="cart+'/v2/Passforgot.aspx?ccode=BYU'" target="_blank">>>パスワードの再設定</a></p>
            <p><strong>＜パスワード再設定方法＞</strong></p>
            <ul class="number mt-0">
              <li>ファンクラブにご登録のメールアドレスを入力します。</li>
              <li>ファンクラブにご登録のメールアドレスあてに、再設定ページのURLが送られます。
                <ul class="asterisk small">
                  <li>迷惑メール対策を実施されている方は「@besideyu.club」のメールが受信できる様にご設定ください。</li>
                </ul>
              </li>
              <li>再設定ページにアクセスし、新しいパスワードを入力してください。</li>
              <li>入力が終わりましたら【変更する】ボタンを押してください。</li>
            </ul>
          </div>
        </div>
        <h3 class="margin">発送物・発送先住所</h3>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_3" aria-expanded="false" aria-controls="faq1_3">入会しましたが、入会特典が届きません</h4>
          <div class="collapse multi-collapse answer" id="faq1_3">
            <p>入会特典がお手元に届くまでに、お申し込み後1ヶ月半程かかります。2ヶ月経過しても入会特典が届かない場合は「<a :href="cart+'/v2/help/fc_contact.aspx?ccode=BYU'" target="_blank">コンタクト</a>」よりファンクラブまでお問い合わせください。<br>なお、時期によっては会報誌等の発送物に同封してお送りする場合もございます。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_4" aria-expanded="false" aria-controls="faq1_4">発送物が届きません</h4>
          <div class="collapse multi-collapse answer" id="faq1_4">
            <p>発送物があった場合は、ファンクラブページ「Info」にてお知らせいたします。</p>
            <p>未着の際は、告知しているお問い合わせ期間内に「<a :href="cart+'/v2/help/fc_contact.aspx?ccode=BYU'" target="_blank">コンタクト</a>」よりファンクラブまでご連絡ください。<br>期間を過ぎてのお問い合わせの場合、対応いたしかねる事もございますので予めご了承ください。</p>
            <ul class="asterisk small">
              <li>転居・転送サービスをご利用の場合、通常より届くのが遅くなる場合がございます。</li>
              <li>お引越しをされた方はファンクラブ登録住所を今一度会員専用ページ内「マイページ」よりご確認ください。<br>ファンクラブページ「Info」でご案内の期日以降に住所変更をした場合、旧住所に届いている場合がございます。<br>また、併せて郵便局へ転居届（https://www.post.japanpost.jp/service/tenkyo/）をご提出ください。</li>
              <li>ご住所の登録変更を行わなかった場合の再発送は、追加送付費用を頂戴する場合もございます。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container border-bottom">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_5" aria-expanded="false" aria-controls="faq1_5">住所や名前の変更方法がわかりません</h4>
          <div class="collapse multi-collapse answer" id="faq1_5">
            <p>会員専用ページにログイン後、マイページ内「会員情報」の「会員情報の変更」をクリックし、変更されたい箇所のみ書き換えていただき、「変更する」ボタンを押してください。<br>変更が完了しますと「【Beside YU】登録情報変更完了のご案内」のメールが配信されますのでご確認ください。</p>
          </div>
        </div>
        <h3 class="margin">メール</h3>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_6" aria-expanded="false" aria-controls="faq1_6">登録メールアドレスの変更方法がわかりません</h4>
          <div class="collapse multi-collapse answer" id="faq1_6">
            <p>会員専用ページにログイン後、マイページ内「会員情報」の「会員情報の変更」をクリックし、メールアドレス欄を書き換えていただき、「変更する」ボタンを押してください。<br>変更が完了しますと「【Beside YU】登録情報変更完了のご案内」のメールが配信されますのでご確認ください。</p>
          </div>
        </div>
        <div class="faq-container border-bottom">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_7" aria-expanded="false" aria-controls="faq1_7">ファンクラブからのメールが届きません</h4>
          <div class="collapse multi-collapse answer" id="faq1_7">
            <p>メールが届かない場合は、下記をご確認ください。</p>
            <ul class="dot wide">
              <li><strong>ファンクラブに登録しているメールアドレスに誤りはありませんか？</strong><br>会員専用ページログイン後、マイページにてご登録しているメールアドレスをご確認ください。</li>
              <li><strong>パソコンからのメールを受信拒否にしていませんか？</strong><br>ご自身で設定していなくても、自動的に拒否設定になっている場合がございます。<br>「@besideyu.club」からのメールが受信できるように設定を今一度ご確認ください。<br>なお、ドメイン受信設定をしていても、迷惑メールフィルターやなりすまし規制のレベルによって受信拒否されてしまう場合がございます。<br>その場合は、ご契約されている携帯電話会社またはプロバイダまでお問い合わせください。</li>
              <li><strong>迷惑メールフォルダに分類されていませんか？</strong><br>Yahoo!メールやGmail、Hotmail等のフリーメールやウイルスソフトをご利用になっている場合、自動的に迷惑メールフォルダに振り分けられている可能性がございます。<br>「ゴミ箱」や「迷惑メール」フォルダを今一度ご確認ください。</li>
              <li><strong>メールボックスの容量がいっぱいになっていませんか？</strong><br>容量がいっぱいになっていると、新着のメールを受け取ることができません。<br>メールサーバーをご確認ください。</li>
            </ul>
            <p>なお、メールの再配信は行っておりません。<br>また、迷惑メールフィルター等の影響でお送りしたメールが数回に渡り送信エラーになってしまった場合、一時的にメール配信が停止されることもございます。</p>
            <p>メール未着による各種手続き遅れや、申し込み忘れに関しては一切対応を承ることができません。<br>ご自身で管理くださいますようお願いいたします。</p>
          </div>
        </div>
        <h3 class="margin">会員期限</h3>
        <div class="faq-container border-bottom">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_8" aria-expanded="false" aria-controls="faq1_8">会員期限がわかりません</h4>
          <div class="collapse multi-collapse answer" id="faq1_8">
            <p>会員専用ページにログイン後、マイページからご確認ください。<br>会員期限はファンクラブからお送りする発送物の宛名欄にも記載しております。</p>
          </div>
        </div>
        <h3 class="margin">継続手続き</h3>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_9" aria-expanded="false" aria-controls="faq1_9">継続の手続き方法がわかりません</h4>
          <div class="collapse multi-collapse answer" id="faq1_9">
            <p><a href="/keizoku">こちら</a>をご確認ください。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_10" aria-expanded="false" aria-controls="faq1_10">継続の手続きはいつからできますか、いつまでにすればよいですか</h4>
          <div class="collapse multi-collapse answer" id="faq1_10">
            <p>会員期限が切れる3ヶ月前から、会員期限が切れる前までに継続の入金・決済まで完了してください。</p>
            <p>例：会員期限「2022年11月30日」の場合<br>2022年9月1日～2022年11月30日が継続手続き可能期間となります</p>
            <p>会員期限終了間際に継続手続きをされますと、入金確認に時間を要する場合があり、入金の確認が取れるまで一時的に会員期限が切れる可能性がございます。その場合、継続手続きが完了するまでの間、会員専用ページにログインができませんので予めご了承ください。<br>会員期限終了間際に継続手続きをされますと、入れ違いで、「会員期限終了のご案内」メールが配信される場合がございます。<br>会員期限が切れた後に継続手続きをすることは一切できません。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_11" aria-expanded="false" aria-controls="faq1_11">クレジットカードによる自動継続の登録・変更・削除（登録解除）はどのようにすればよいですか？</h4>
          <div class="collapse multi-collapse answer" id="faq1_11">
            <p>会員専用ページにログイン後、マイページ内「ファンクラブ情報」の「クレジットカード情報の登録・変更・削除」から設定変更が可能です。</p>
            <p>変更方法は下記の通りです。</p>
            <h5>【登録】</h5>
            <p>登録したいクレジットカード情報を入力し、「確認する」→「登録する」</p>
            <h5>【変更】</h5>
            <p>変更希望のクレジットカード情報を入力し、「確認する」→「変更する」</p>
            <h5>【削除（登録解除）】</h5>
            <p>「削除する」→「削除する」</p>
            <p>クレジットカードによる自動継続とは、クレジットカード情報をあらかじめ登録しておくことで、登録したクレジットカードにて自動で継続手続きを行うシステムです。<br>クレジットカード決済でご入会・ご継続された方は、ご利用されたクレジットカード情報が登録され、次年度以降はクレジットカードでの自動継続となります。<br>登録後はクレジットカード情報を削除（登録解除）しない限り、毎年決済がかかります。継続をご希望でない場合は、必ず削除（登録解除）手続きを行ってください。<br>自動継続実施日に何らかの理由で決済エラーになった場合、会員期限までにご自身で手続きをしないと会員期限が切れてしまいます。ご注意ください。</p>
            <h5>＜自動継続実施日＞</h5>
            <ul class="dot mt-0">
              <li>「会員期限前々月の末日」までにクレジットカード情報の登録を行った場合<br>→「会員期限前月の1日」</li>
              <li>「会員期限前月の1日から会員期限前月末」までにクレジットカード情報の登録を行った場合<br>→「会員期限当月の1日」</li>
              <li>「会員期限当月の1日から会員期限」までにクレジットカード情報の登録を行った場合<br>→「自動継続設定時」</li>
            </ul>
            <p>自動継続を希望されない場合は、<br>必ず「会員期限前々月の末日」までに、会員専用ページにログイン後、マイページ内ファンクラブ情報の「クレジットカード情報の登録・変更・削除」からクレジットカード情報の削除を行なってください。</p>
            <p>例：会員期限「2024年11月30日」の場合の継続手続き日<br>2024年9月30日までに設定→2024年10月1日<br>2024年10月1日～2024年10月31日までに設定→2024年11月1日<br>2024年11月1日～2024年11月30日までに設定→設定時に即時決済</p>
            <ul class="asterisk small mt-2">
              <li>自動継続を希望されない場合は「2024年9月30日」までにクレジットカード情報を削除してください</li>
            </ul>
            <ul class="asterisk small">
              <li>自動継続完了後、「ご継続手続き完了のご案内」メールが配信されます。メールが届かない場合は、正常に継続のお手続きが完了していない場合がございますので、マイページにて会員期限をご確認いただくか、会員期限が切れる前にファンクラブにお問い合わせください。</li>
              <li>自動継続手続き完了後のキャンセル・返金はいたしかねます。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_12" aria-expanded="false" aria-controls="faq1_12">クレジットカードによる自動継続の登録をしましたが、決済されませんでした</h4>
          <div class="collapse multi-collapse answer" id="faq1_12">
            <p>通常通り決済がされなかった場合には、「クレジットカード自動継続エラーのお知らせ」メールを配信しておりますので、メール内容をご確認ください。<br>メールが届いていない場合には、「<a :href="cart+'/v2/help/fc_contact.aspx?ccode=BYU'" target="_blank">コンタクト</a>」よりファンクラブへお問い合わせください。</p>
            <ul class="asterisk small">
              <li>メールの返信には1週間ほどお時間をいただく場合がございます。お時間に余裕をもってお問い合わせください。</li>
              <li>メールの返信前に会員期限が切れてしまった場合であっても、ファンクラブでは対応できかねます。 いかなる理由においても会員期限切れ後のご継続は承れませんので、ご了承ください。</li>
            </ul>
            <p>継続を希望される方は、次回自動継続実施日までにクレジットカード登録情報を変更いただくか、公式サイトから継続手続きを会員期限が切れる前までに行ってください。</p>
          </div>
        </div>
        <div class="faq-container border-bottom">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_13" aria-expanded="false" aria-controls="faq1_13">継続を希望していないのに、クレジットカードによる自動継続設定を登録したままで、自動継続がされてしまいました</h4>
          <div class="collapse multi-collapse answer" id="faq1_13">
            <p>いかなる理由におきましても、自動継続手続き完了後のキャンセル・返金はいたしかねます。</p>
            <p>なお、会員期限の約2ヶ月前に「クレジットカード自動継続のご案内」メールにて自動継続実施日をご案内しております。継続を希望されない場合、自動継続実施日前までにクレジットカード情報の削除を行ってください。</p>
          </div>
        </div>
        <h3 class="margin">支払い方法</h3>
        <div class="faq-container border-bottom">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_14" aria-expanded="false" aria-controls="faq1_14">コンビニエンスストアでの入金方法詳細がわかりません</h4>
          <div class="collapse multi-collapse answer" id="faq1_14">
            <h5>● ファミリーマート</h5>
            <ul class="number">
              <li>店内に設置されているFamiポート端末トップページより「代金支払い」を選択してください。</li>
              <li>メールに記載の"受付番号（6桁）"を入力し、「OK」を選択してください。</li>
              <li>ファンクラブに登録の"電話番号"を入力し、「OK」を選択してください。</li>
              <li>お支払い内容を確認のうえ、「OK」を選択してください。</li>
              <li>出力される「申込券」を持って、レジにて30分以内にお支払いください。</li>
            </ul>
            <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/familymart.html" target="_blank">こちら</a>にてご確認ください。</p>
            <ul class="asterisk small">
              <li>お支払いは、現金もしくはファミマTカードでのクレジット決済となります。</li>
              <li>代金と引き換えに「受領書」をお渡ししますので、必ずお受取りください。</li>
              <li>「受領書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
            </ul>
            <h5>● ローソン・ミニストップ（MINISTOP Loppi設置店）</h5>
            <ul class="number">
              <li>店内に設置されているLoppi端末より「各種番号をお持ちの方」を選択してください。</li>
              <li>メールに記載の"受付番号（6桁）"を入力し、「次へ」を選択してください。</li>
              <li>ファンクラブに登録の“電話番号”を入力し、「次へ」を選択してください。</li>
              <li>お申し込み内容を確認のうえ、「了解」を選択してください。</li>
              <li>出力される「申込券」を持って、レジにて30分以内にお支払いください。</li>
            </ul>
            <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/lawson.html" target="_blank">こちら</a>にてご確認ください。</p>
            <ul class="asterisk small">
              <li>お支払いは、現金のみとなります。</li>
              <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
              <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
            </ul>
            <h5>● デイリーヤマザキ・ヤマザキデイリーストア</h5>
            <p>オンライン決済番号、もしくはご購入履歴画面から決済画面を印刷したものをレジにてご提示ください。店員に「オンライン決済」とお伝えの上、レジにて代金をお支払いください。お支払いは現金のみとなります。</p>
            <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/yamazaki.html" target="_blank">こちら</a>にてご確認ください。</p>
            <ul class="asterisk small">
              <li>お支払いは現金のみとなります。</li>
              <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
              <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
            </ul>
            <h5>● セイコーマート</h5>
            <ul class="number">
              <li>レジにて「インターネット支払い」とお伝えください。</li>
              <li>メールに記載の"受付番号（6桁）"を入力してください。</li>
              <li>ファンクラブに登録の"電話番号"を入力してください。</li>
              <li>注文内容をご確認の上、代金をお支払いください。お支払いは現金のみとなります。</li>
            </ul>
            <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/seicomart.html" target="_blank">こちら</a>にてご確認ください。</p>
            <ul class="asterisk small">
              <li>お支払いは現金のみとなります。</li>
              <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
              <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
            </ul>
          </div>
        </div>
        <h3 class="margin">退会・発送物の停止</h3>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_15" aria-expanded="false" aria-controls="faq1_15">ファンクラブを退会したいです</h4>
          <div class="collapse multi-collapse answer" id="faq1_15">
            <p>会員期限までに更新手続きを行わない場合、自動的に退会となります。</p>
          </div>
        </div>
        <div class="faq-container border-bottom">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_16" aria-expanded="false" aria-controls="faq1_16">ファンクラブからの郵送物を止めたいです</h4>
          <div class="collapse multi-collapse answer" id="faq1_16">
            <p>発送物の停止は行っておりませんので、郵便局に「受取拒否」としてお戻しください。</p>
          </div>
        </div>
        <h3 class="margin">その他</h3>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_17" aria-expanded="false" aria-controls="faq1_17">ポイント制とはなんですか？</h4>
          <div class="collapse multi-collapse answer" id="faq1_17">
            <p>入会や継続等に応じて、ポイントを付与いたします。<br>たまったポイント数によってステイタスが変動し、デジタル会員証の表示が変わっていきます。</p>
            <h5>【ポイント付与数】</h5>
            <ul class="dot">
              <li>入会・継続をしてくださった方：10pt</li>
              <li>アンケートへ回答してくださった方：5pt</li>
              <li>お友達紹介キャンペーンを利用した紹介者の方：5pt</li>
            </ul>
            <ul class="asterisk small">
              <li>アンケートおよびお友達紹介キャンペーンの付与については、2022年12月1日以降からが対象となります。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_18" aria-expanded="false" aria-controls="faq1_18">お友達紹介キャンペーンとはなんですか？</h4>
          <div class="collapse multi-collapse answer" id="faq1_18">
            <p>会員の方がBeside YUを紹介し、紹介された方が新規入会すると、紹介者・入会者に『Beside YUオリジナルペーパーコースター』を差し上げます！</p>
            <h5>【申込手順】</h5>
            <ul class="number">
              <li>会員の方が、お友達にBeside YUを紹介</li>
              <li>お友達が入会ページから入会手続きを行い、その際に紹介者の「会員番号」と「氏名」を入力</li>
            </ul>
            <p>ご入会は<a :href="iam+'/tokuten'">こちら</a></p>
            <ul class="asterisk small">
              <li>必ずファンクラブページからご入会ください。Loppi/ローチケ.comからの入会では適用されませんのでご注意ください。</li>
              <li>入会時に紹介者として登録ができる会員は1名のみとなります。</li>
              <li>紹介者の登録は入会時のみとなり、登録後の変更はできませんのであらかじめご了承ください。</li>
              <li>お友達の紹介は何名でも可能です。</li>
              <li>相手が望まない無理な勧誘などはおやめください。</li>
              <li>登録された会員番号・氏名が既存会員と一致しない場合は、対象外となります。</li>
              <li>特典は入会後に発送する会報と同封してお送りします。</li>
            </ul>
          </div>
        </div>
        <div v-if="chkDate" class="faq-container">
          <h4 class="normal" data-bs-toggle="collapse" data-bs-target="#faq1_19" aria-expanded="false" aria-controls="faq1_19">ファンレターの送り先を教えてください</h4>
          <div class="collapse multi-collapse answer" id="faq1_19">
            <p>内田雄馬宛のファンレターは、所属事務所の株式会社アイムエンタープライズまでお送りください。</p>
            <p>〒151-0053<br>東京都渋谷区代々木1-14-3-1F<br>株式会社アイムエンタープライズ「内田雄馬」宛</p>
          </div>
        </div>
      </div>
      
    </div>
    <!-- ▲ ファンクラブよくある質問 -->
    
    
  </main>
</template>

<script>
  export default{
    data(){
      return{
        iam: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        isLogin:false,
        rtn:'',
      }
    },
    created(){
      this.rtn = encodeURIComponent(this.cms+'/system/login?action=login')
      this.chkLogin()
    },
    computed:{
      chkDate(){
        const date = new Date()
        const limit = new Date(2022, 11, 31, 19, 0, 0)
        if(date >= limit){ return false }
        else { return true }
      }
    },
    methods:{
      async chkLogin(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=static', { withCredentials: true })
          if(response.data.pageMeta.isAuthenticated){
            this.isLogin = true
          }else{
            this.isLogin = false
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      }
    },
  }
</script>