<template>
  <main id="main">
    <div class="single-content">
      
      <!-- ▼ タイトル部分 -->
      <div class="container">
        <header>
          <div class="row">
            <div class="col-12 date">{{datefilter(date)}}<span class="new d-none d-lg-inline-block" v-if="chkNew(date)">New!</span></div>
            <div class="col-lg-10"><h1>{{title}}</h1></div>
            <div class="col-lg-2"><span class="new d-lg-none" v-if="chkNew(date)">New!</span></div>
          </div>
        </header>
      </div>
      <!-- ▲ タイトル部分 -->
      
      <!-- ▼ テキスト -->
      <div class="container contents px-lg-5" v-html="text"></div>
      <!-- ▲ テキスト -->
      
      <!-- ▼ 一覧に戻る -->
      <div class="container minimum">
        <div class="back text-center">
          <div class="row">
            <div class="col-6" :style="{visibility : isFirst ? 'hidden' : 'visible'}">
              <a class="canClick btn-theme auto w-100" @click="pageMove('pre')">前へ</a>
            </div>
            <div class="col-6" :style="{visibility : isLast ? 'hidden' : 'visible'}">
              <a class="canClick btn-theme auto w-100" @click="pageMove('next')">次へ</a>
            </div>
            <div class="col-12 mt-4">
              <a class="canClick btn-theme auto w-100" @click="toInfo()">一覧に戻る</a>
            </div>
          </div>
        </div>
      </div>
      <!-- ▲ 一覧に戻る -->
      
    </div>
  </main>
</template>

<script>
  export default{
    data(){
      return{
        host: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        num: this.$store.getters.get_pnum,
        id: this.$route.params['infoid'],
        pMeta:[],
        listContents:[],
        bodyContents:[],
        uInfo:[],
        fcnList:[],
        detailBody:[],
        date:'',
        title:'',
        text:'',
        year:new Date().getFullYear(),
        month:new Date().getMonth()+1,
        day:new Date().getDate(),
        hour:new Date().getHours(),
        minutes:new Date().getMinutes(),
        seconds:new Date().getSeconds(),
        lastPage:Number,
        fadeapp:true,
        isFirst:true,
        isLast:true,
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    methods:{
       async getData(){
        try{
          const newsList = await this.$axios.get(this.$uri+'/data_output?type=fcinfo', { withCredentials: true })
          const newsBody = await this.$axios.get(this.$uri+'/data_output?type=fcinfo&id='+this.id, { withCredentials: true })
          this.pMeta = newsList.data.pageMeta
          let loginAuth = this.pMeta.isAuthenticated
          this.uInfo = this.pMeta.userInfo
          this.listContents = newsList.data.contents
          this.bodyContents = newsBody.data.contents
          this.getPageCount()
          if(newsList.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.dataSet()
            window.scrollTo(0,0)
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      dataSet(){
        this.fcnList = this.listContents.filter((v)=> v.type == 'fcinfo')
        this.detailBody = this.bodyContents.filter((v)=> v.type == 'fcinfo')[0].content
        this.date = this.detailBody.date
        this.title = this.detailBody.title
        this.text = this.detailBody.text
      },
      pageMove(to){
        // scrollTo(0,0)
        let nextid
        if(to == 'pre'){
          for(var i=0; i<this.listContents.length; i++){
            if(this.listContents[i].id == this.id){
              nextid = this.listContents[i-1].id
            }
          }
        }else if(to == 'next'){
          for(var k=0; k<this.listContents.length; k++){
            if(this.listContents[k].id == this.id){
              nextid = this.listContents[k+1].id
            }
          }
        }
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/info/detail/'+nextid});
        this.$router.push('/fanclubtop/info/detail/'+nextid)
      },
      getPageCount(){
        if(this.listContents.length>1){
          for(var i=0; i<this.listContents.length; i++){
            if(this.listContents[i].id == this.id){
              if(i == 0){
                this.$nextTick(() => {
                  this.isFirst = true
                  this.isLast = false
                })
              }else if(i == this.listContents.length-1){
                this.$nextTick(() => {
                  this.isLast = true
                  this.isFirst = false
                })
              }else{
                this.$nextTick(() => {
                  this.isFirst = false
                  this.isLast = false
                })
              }
            }
          }
        }else{
          this.isFirst = true
          this.isLast = true
        }
      },
      chkNew(date){
        if(this.day.length==1){
          this.day = '0'+this.day
        }
        if(this.month.length==1){
          this.month = '0'+this.month
        }
        let now = Date.parse(this.year+'-'+this.month+'-'+this.day)
        let release = Date.parse(date)
        if(now>=release && now<release+604800000){
          return true
        }else{
          return false
        }
      },
      toInfo(){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/info/list/'+this.num});
        this.$router.push('/fanclubtop/info/list/'+this.num)
      },
      datefilter(date){
        if(date != void 0){
          return date.replace(/-/g,'.')
        }
      },
    },
    watch:{
      $route(){
        this.id = this.$route.params['infoid']
        this.getData()
      }
    }
  }
</script>