<template>
  <main id="main">
    <div class="single-content">
      
      <!-- ▼ タイトル部分 -->
      <div class="container">
        <header>
          <div class="row">
            <div class="col-12 date">{{datefilter(date)}}<span class="new d-none d-lg-inline-block" v-if="chkNew(date)">New!</span></div>
            <div class="col-lg-10"><h1>{{title}}</h1></div>
            <div class="col-lg-2"><a class="canClick category" @click="catMove()">{{category}}</a><span class="new d-lg-none" v-if="chkNew(date)">New!</span></div>
          </div>
        </header>
      </div>
      <!-- ▲ タイトル部分 -->
      
      <!-- ▼ 画像と説明 -->
      <div class="container narrow">
        <div class="main-text" v-html="text"></div>
      </div>
      <!-- ▲ 画像と説明 -->
      
      <!-- ▼ 注意事項 -->
      <div class="container">
        <div class="attention">
          <p>※画像のダウンロード・無断複製・転載・加工を禁止いたします。</p>
        </div>
      </div>
      <!-- ▲ 注意事項 -->
      
      <!-- ▼ 一覧に戻る -->
      <div class="container minimum">
        <div class="back text-center">
          <div class="row">
            <div class="col-6" :style="{visibility : isFirst ? 'hidden' : 'visible'}">
              <a class="canClick btn-theme auto w-100" @click="pageMove('pre')">前へ</a>
            </div>
            <div class="col-6" :style="{visibility : isLast ? 'hidden' : 'visible'}">
              <a class="canClick btn-theme auto w-100" @click="pageMove('next')">次へ</a>
            </div>
            <div class="col-12 mt-4">
              <a class="canClick btn-theme auto w-100" @click="toBlog()">一覧に戻る</a>
            </div>
          </div>
        </div>
      </div>
      <!-- ▲ 一覧に戻る -->
      
    </div>
    
  </main>
</template>

<script>
  export default{
    data(){
      return{
        host: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        num: this.$store.getters.get_pnum,
        cat: '',
        id: this.$route.params['blogid'],
        pMeta:[],
        listContents:[],
        bodyContents:[],
        uInfo:[],
        fcbList:[],
        detailBody:[],
        disList:[],
        date:'',
        title:'',
        category:'',
        text:'',
        year:new Date().getFullYear(),
        month:new Date().getMonth()+1,
        day:new Date().getDate(),
        hour:new Date().getHours(),
        minutes:new Date().getMinutes(),
        seconds:new Date().getSeconds(),
        lastPage:Number,
        fadeapp:true,
        isFirst:true,
        isLast:true,
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    methods:{
       async getData(){
        try{
          const blogList = await this.$axios.get(this.$uri+'/data_output?type=blog', { withCredentials: true })
          const blogBody = await this.$axios.get(this.$uri+'/data_output?type=blog&id='+this.id, { withCredentials: true })
          this.pMeta = blogList.data.pageMeta
          this.uInfo = this.pMeta.userInfo
          let loginAuth = this.pMeta.isAuthenticated
          this.listContents = blogList.data.contents
          this.bodyContents = blogBody.data.contents
          this.getPageCount()
          if(blogList.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.fcbList = this.listContents.filter((v)=> v.type == 'blog')
            this.detailBody = this.bodyContents[0].content
            this.date = this.detailBody.date
            this.title = this.detailBody.title
            this.text = this.detailBody.text
            if(this.$store.getters.get_category == 'all'){
              this.cat = 'all'
            }else{
              this.cat = this.bodyContents[0].categories[0].name
            }
            this.category = this.bodyContents[0].categories[0].displayName
            if(this.cat == 'all'){
              this.disList = this.fcbList
            }else{
              this.disList = this.fcbList.filter((v)=> v.categories[0].name == this.cat)              
            }
            this.getPageCount()
            window.scrollTo(0,0)
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      pageMove(to){
        // scrollTo(0,0)
        let nextid
        if(to == 'pre'){
          for(var i=0; i<this.listContents.length; i++){
            if(this.listContents[i].id == this.id){
              nextid = this.listContents[i-1].id
            }
          }
        }else if(to == 'next'){
          for(var k=0; k<this.listContents.length; k++){
            if(this.listContents[k].id == this.id){
              nextid = this.listContents[k+1].id
            }
          }
        }
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/blog/detail/'+nextid});
        this.$router.push('/fanclubtop/blog/detail/'+nextid)
      },
      getPageCount(){
        if(this.disList.length>1){
          for(var i=0; i<this.disList.length; i++){
            if(this.disList[i].id == this.id){
              if(i == 0){
                this.$nextTick(() => {
                  this.isFirst = true
                  this.isLast = false
                })
              }else if(i == this.disList.length-1){
                this.$nextTick(() => {
                  this.isLast = true
                  this.isFirst = false
                })
              }else{
                this.$nextTick(() => {
                  this.isFirst = false
                  this.isLast = false
                })
              }
            }
          }
        }else{
          this.isFirst = true
          this.isLast = true
        }
      },
      chkNew(date){
        if(this.day.length==1){
          this.day = '0'+this.day
        }
        if(this.month.length==1){
          this.month = '0'+this.month
        }
        let now = Date.parse(this.year+'-'+this.month+'-'+this.day)
        let release = Date.parse(date)
        if(now>=release && now<release+604800000){
          return true
        }else{
          return false
        }
      },
      toBlog(){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path':'/fanclubtop/blog/list/'+this.num+'?cat='+this.cat});
        this.$router.push('/fanclubtop/blog/list/'+this.num+'?cat='+this.cat)
      },
      catMove(){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path':'/fanclubtop/blog/list/1?cat='+this.bodyContents[0].categories[0].name});
        this.$router.push('/fanclubtop/blog/list/1?cat='+this.bodyContents[0].categories[0].name)
      },
      datefilter(date){
        if(date != void 0){
          return date.replace(/-/g,'.')
        }
      },
    },
    watch:{
      $route(){
        this.id = this.$route.params['blogid']
        this.getData()
      }
    }
  }
</script>