<template>
  <main id="main" class="pt-0">
    <div class="container">
      
      <!-- ▼ メインビジュアル -->
      <header id="topHeader" class="p-0 mb-4 member">
        <h1>
          <picture> 
            <source type="image/jpeg"
              media="(min-width: 768px)"
              srcset="/images/main_member_pc02.jpg">
            <img src="/images/main_member_sp02.jpg" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="640" height="540" class="w-100">
          </picture>
        </h1>
        <div class="line container">
            <img src="/images/line.png" alt="line" width="380" height="138">
          </div>
      </header>
      <!-- ▲ メインビジュアル -->
      
      <!-- ▼ 会員情報(SP) -->
      <div class="member-info-sp_area" :class="isMessage ? '' : 'messageOff'">
        <div class="member-info-sp_wrap container">
          <div class="member-info-sp">
            <div class="msg" v-if="isMessage">
              <span>メッセージ</span>
              <div class="login_msg">
                <p>{{ expireMessage }}</p>
              </div>
            </div>
            <table>
              <tbody>
                <tr>
                  <th class="member-number">No.</th>
                  <td>{{uInfo.fc_no}}</td>
                </tr>
                <tr>
                  <th>お名前</th>
                  <td class="member-name">{{uInfo.user_name}}</td>
                </tr>
                <tr>
                  <th :class="isDeadMark ? 'deadline':''">有効期限</th>
                  <td :class="isThisMonth ? 'text-red':''">{{uInfo.fc_expire_on | dateFilter}}</td>
                </tr>
              </tbody>
            </table>
            <div class="member-info-button">
              <a :href="cart+'/v2/member/MyPage.aspx?ccode=BYU'" class="mypage">
                <img src="/images/mypage.svg" alt="" width="14">マイページ</a>
              <a class="logout canClick" @click="logout()">
                <img src="/images/logout.svg" alt="" width="17">ログアウト</a>
            </div>
          </div>
        </div>
      </div>
      <!-- ▲ 会員情報(SP) -->

      <div class="row">
        <!-- ▼ メインカラム -->
        <div class="col-lg-9 order-2 order-lg-1 index-content pe-lg-4">
          
          <!-- ▼ タイトル -->
          <h1><div class="container visual">Info</div></h1>
          <!-- ▲ タイトル -->
          
          <!-- ▼ お知らせリスト -->
          <div class="container visual">
            <div class="thread">
              <!-- ▼ 繰り返しここから -->
              <article v-for="fcnews in fcnList.slice(0,10)" :key="fcnews.index">
                <div class="container normal">
                  <div class="row">
                    <div class="col-12 col-sm-auto pe-0 order-2 order-sm-1"><span class="date">{{datefilter(fcnews.content.date)}}</span></div>
                    <!-- ▼ 新着記事に以下挿入 -->
                    <div class="col-auto order-1 order-sm-2" v-if="chkNew(fcnews.content.date)"><span class="new">New!</span></div>
                    <!-- ▲ 新着記事に以下挿入 -->
                    <div class="col-12 order-3">
                      <h3 class="title"><a class="canClick" @click="toFcInfoDetail(fcnews.id)">{{fcnews.content.title}}</a></h3>
                    </div>
                  </div>
                </div>
              </article>
              <!-- ▲ 繰り返しここまで -->
            </div>
            <!-- ▼ 一覧を見る -->
            <div class="text-end readmore">
              <a class="canClick btn-theme rounded small" @click="toFcInfo()">一覧を見る</a>
            </div>
            <!-- ▲ 一覧を見る -->
          </div>
          <!-- ▲ お知らせリスト -->
          
        </div>
        <!-- ▲ メインカラム -->
        
        <!-- ▼ サイドバー -->
        <aside class="col-lg-3 order-1 order-lg-2 ps-lg-4 mb-5 mb-lg-0" id="asideMember">
          <!-- ▼ 会員証（SP） -->
          <div class="card-block-sp d-block d-lg-none">
            <div class="container text-center">
              <a href="#" class="btn-theme large" data-bs-toggle="modal" data-bs-target="#cardModal">会員証を表示する<span class="icon"><object type="image/svg+xml" data="/images/top/card_white.svg" width="37" height="22"></object></span></a>
            </div>
          </div>
          <!-- ▲ 会員証（SP） -->
          <!-- ▼ 会員証（PC） -->
          <div class="card-block-pc d-none d-lg-block">
            <h2>会員証</h2>
            <div class="card-data">
              <!--
              クラスrank_*の*部分を該当するメンバーランクの数字に差し替えてください
              -->
              <div class="member-card">
                <div class="content">
                  <div class="container p-0">
                    <div class="row align-items-center m-0">
                      <div class="col-12 datetime text-end px-0">{{now}}</div>
                      <div class="col-6 logo"><img src="/images/card/logo.png" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="246" height="208" class="w-100"></div>
                      <div class="col-6 profile pe-0">
                        <!--
                        images/card/rank_*.pngの*部分を該当するメンバーランクの数字に差し替えてください
                        -->
                        <div class="rank"><img :src="'/images/card/rank_'+uInfo.fc_rank+'.png'" :alt="uInfo.fc_rank" width="213" height="15"></div>
                        <div class="data">
                          <div class="label">No.</div>
                          <div class="text">{{uInfo.fc_no}}</div>
                          <div class="label">有効期限</div>
                          <div class="text">{{uInfo.fc_expire_on}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide">
                  <!--
                  images/card/text_*.pngの*部分を該当するメンバーランクの数字に差し替えてください
                  -->
                  <img :src="'/images/card/text_'+uInfo.fc_rank+'.png'" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="600" height="34">
                  <img :src="'/images/card/text_'+uInfo.fc_rank+'.png'" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="600" height="34">
                </div>
              </div>
            </div>
          </div>
          <!-- ▲ 会員証（PC） -->
          <!-- ▼ バナー -->
          <div class="container visual banners text-center">
            <!-- ▼ 繰り返しここから -->
            <a :href="banner.content.bannerLink" class="banner" v-for="banner in bList" :key="banner.index">
              <picture>
                  <img :src="host+banner.content.bannerImage" :alt="banner.type" width="500" height="160" class="w-100">
                </picture>
              </a>
            <!-- ▲ 繰り返しここまで -->
          </div>
          <!-- ▲ バナー -->
        </aside>
        <!-- ▲ サイドバー -->
        
      </div>
    </div>
    
    
    <!-- ▼ Modal（メンバーカード） -->
    <div class="modal fade card-modal" id="cardModal" tabindex="-1" aria-labelledby="cardModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-body text-center">
                <div class="card-container">
                  <!--
              クラスrank_*の*部分を該当するメンバーランクの数字に差し替えてください
              -->
                  <div class="member-card">
                <div class="content">
                  <div class="container p-0">
                    <div class="row align-items-center m-0">
                      <div class="col-12 datetime text-end px-0">{{now}}</div>
                      <div class="col-6 logo"><img src="/images/card/logo.png" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="246" height="208" class="w-100"></div>
                      <div class="col-6 profile pe-0">
                        <!--
                        images/card/rank_*.pngの*部分を該当するメンバーランクの数字に差し替えてください
                        -->
                        <div class="rank"><img :src="'/images/card/rank_'+uInfo.fc_rank+'.png'" :alt="uInfo.fc_rank" width="213" height="15"></div>
                        <div class="data">
                          <div class="label">No.</div>
                          <div class="text">{{uInfo.fc_no}}</div>
                          <div class="label">有効期限</div>
                          <div class="text">{{uInfo.fc_expire_on}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide">
                  <!--
                  images/card/text_*.pngの*部分を該当するメンバーランクの数字に差し替えてください
                  -->
                  <img :src="'/images/card/text_'+uInfo.fc_rank+'.png'" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="600" height="34">
                  <img :src="'/images/card/text_'+uInfo.fc_rank+'.png'" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="600" height="34">
                </div>
              </div>
            </div>
            <div class="mt-4">
              <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Close</button>
            </div>
              </div>
          </div>
        </div>
    </div>
    <!-- ▲ Modal（メンバーカード） -->
    
  </main>
</template>

<script>
  export default{
    filters: {
      dateFilter(v){
        if(v){
          return v.replace(/\//g,'.')
        }
      }
    },
    data(){
      return{
        host: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        pMeta:[],
        contents:[],
        uInfo:[],
        isMessage: false,
        isDeadMark: false,
        isThisMonth: false,
        bList:[],
        fcnList:[],
        date:'',
        time:'',
        now:'',
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    mounted(){
      setInterval(this.timeUpdate, 1000); 
    },
    methods:{
       async getData(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=fctop', { withCredentials: true })
          this.pMeta = response.data.pageMeta
          this.uInfo = this.pMeta.userInfo
          let loginAuth = this.pMeta.isAuthenticated
          this.contents = response.data.contents
          if(response.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.chkExpire(this.uInfo.fc_expire_on)
            let card = document.getElementsByClassName('member-card')
            for(var i=0; i<card.length; i++){
              card[i].classList.add('rank_'+this.uInfo.fc_rank)
            }
            this.bList = this.contents.filter((v)=> v.type == 'banner')
            this.fcnList = this.contents.filter((v)=> v.type == 'fcinfo')
            window.scrollTo(0,0)
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      chkExpire(expire){
        const now = new Date()
        const ex = new Date(expire)

        if(now <= ex){
          //期限以内
          if(now.getFullYear() == ex.getFullYear()){
            //同年内
            now.setDate('1')
            //各月1日以降のフック
            if(now.getMonth() >= ex.getMonth()-2 && now.getMonth() <= ex.getMonth()){
              //2か月以内同月まで
              this.isDeadMark = true
              if(now.getMonth() == ex.getMonth()-1){
                //前月
                this.isMessage = true
                this.expireMessage = '継続手続き受付中！マイページから継続手続きを行ってください'
              }else if(now.getMonth() == ex.getMonth()){
                //当月
                this.isThisMonth = true
                this.isMessage = true
                this.expireMessage = 'まもなく会員期限が終了します！マイページから継続手続きを行ってください'
              }
            }
          } else if(now.getFullYear() == ex.getFullYear()-1) {
						//年跨ぎ
						if((now.getMonth() == 10 && ex.getMonth() == 0) || (now.getMonth() == 11 && ex.getMonth() == 1)) {
							//"現在11月期限1月"と"現在12月期限2月"の2か月後
							this.isDeadMark = true
						} else if(now.getMonth() == 11 && ex.getMonth() == 0) {
							//"現在12月期限1月"の1か月後
							this.isDeadMark = true
							this.isMessage = true
							this.expireMessage = '継続手続き受付中！マイページから継続手続きを行ってください'
						}
						//同月だったら同じ年なので省略
						//年末と年始以外は比較の必要がないので省略
					}
        } else {
          //期限以降
          this.isDeadMark = true
          this.isThisMonth = true
        }
      },
      chkNew(date){
        let currentdate = new Date()
        let day = this.chkDigit(currentdate.getDate())
        let month = this.chkDigit(currentdate.getMonth()+1)
        let year = this.chkDigit(currentdate.getFullYear())
        let now = Date.parse(year+'-'+month+'-'+day)
        let release = Date.parse(date)
        if(now>=release && now<release+604800000){
          return true
        }else{
          return false
        }
      },
      toFcInfo(){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/info/list'});
        this.$router.push('/fanclubtop/info/list')
      },
      toFcInfoDetail(id){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/info/detail/'+id});
        this.$router.push('/fanclubtop/info/detail/'+id)
      },
      datefilter(date){
        return date.replace(/-/g,'.')
      },
      timeUpdate(){
        let currentdate = new Date()
        this.time = this.chkDigit(currentdate.getHours()) + ':' + this.chkDigit(currentdate.getMinutes()) + ':' + this.chkDigit(currentdate.getSeconds())
        this.date = currentdate.getFullYear()+ '.' +this.chkDigit(currentdate.getMonth()+1)+ '.' +this.chkDigit(currentdate.getDate())
        this.now = this.date + ' ' + this.time
      },
      chkDigit: function (value) {
        let rtnVal
        rtnVal = ('0'+value).slice(-2)
        return rtnVal
      },
      logout(){
        window.location.href = this.$uri+'/logout'
      },
    },
  }
</script>

<style scoped>
.messageOff.member-info{
  width: 34.6%;
  min-width: 450px;
}
.messageOff .member-info-button{
  flex-direction: column;
  justify-content: space-evenly;
  width: 32.8%;
}
.logo-container .messageOff .mypage,
.member-info-sp .messageOff .mypage{
  width: 100%;
  padding: 1rem 2rem 0.86rem 2.71rem;
  height: 40px;
}
.logo-container .messageOff .logout,
.member-info-sp .messageOff .logout{
  width: 100%;
  height: 30px;
}
.messageOff.member-info-sp_area{
  padding-top: 1.3rem;
}
.messageOff .member-info-sp .member-info-button{
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.member-info-sp td.text-red {
  color: #e01f17;
}
</style>