<template>
  <main id="main">
    <div class="container">
    
      <!-- ▼ タイトル -->
      <div class="row title-wrapper">
        <div class="title-content col-lg-9 pe-lg-4">
          <h1><div class="container visual">PIC</div></h1>
        </div>
      </div>
      <!-- ▲ タイトル -->
      
      <div class="row">
      
        <!-- ▼ メインカラム -->
        <div class="col-lg-9 order-2 order-lg-1 main-content pe-lg-4">
          
          <div class="container visual" :class="fadeapp ? 'appear':'vanish'">
            <!-- ▼ ギャラリー -->
            <div class="row gallery">
              <!-- ▼ 繰り返しここから -->
              <div class="col-6 col-md-4 mb-4" v-for="pic in disList.slice(minSlice, minSlice+perPage)" :key="pic.index">
                <a class="image canClick" @click="toPicDetail(pic.id)">
                  <img :src="host+pic.content.thumbnail" alt="pic.content.title" width="1000" height="1000">
                </a>
              </div>
              <!-- ▲ 繰り返しここまで -->
            </div>
            <!-- ▲ ギャラリー -->
          </div>
        </div>
        <!-- ▲ メインカラム -->
        
        <!-- ▼ サイドバー -->
        <aside class="col-lg-3 order-1 order-lg-2 ps-lg-4" id="aside">
          <div class="container visual">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
              <h2>Category</h2>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAside" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"><object type="image/svg+xml" data="/images/aside_toggle.svg" width="30" height="30"></object></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavAside">
                    <ul class="category">
                      <li><a class="canClick" @click="pagemove('all')">All</a></li>
                      <li v-for="cat in catList" :key="cat.index"><a class="canClick" @click="pagemove(cat.name)">{{cat.displayName}}</a></li>
                  </ul>
                </div>
            </nav>
          </div>
        </aside>
        <!-- ▲ サイドバー -->
        
        <!-- ▼ ページャー -->
        <div class="col-12 order-3" id="pager" :hidden="!isVisible">
          <div class="container visual">
            <ul class="list-group list-group-horizontal justify-content-center">
              <li class="list-group-item before" :class="isFirst ? 'none':''"><a class="canClick" @click="preClick()"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16.8" width="13" height="18"><g id="レイヤー_2" data-name="レイヤー 2"><g id="レイヤー_2-2" data-name="レイヤー 2"><path id="パス_714" data-name="パス 714" class="cls-1" d="M4.7,8.4l2.84-2L9.3,5.18v6.45L7.54,10.4,4.7,8.4M0,8.4l6,4.2,6,4.2V0L6,4.2,0,8.4Z"/></g></g></svg></span></a></li>
              <li class="list-group-item" v-if="(lastPage>5)&&(curPage>=4)"><a class="dotted"><span>…</span></a></li>
              <span v-for="page of lastPage" :key="page.index">
                <li
                  class="list-group-item"
                  v-if="chkVisiblePage(page)"
                  :class="activeNum(page)"
                >
                  <a class="canClick" @click="pageClick(page)"><span>{{page}}</span></a>
                </li>
              </span>
              <li class="list-group-item" v-if="(lastPage>5) && ((Number(curPage)+2)<lastPage)"><a class="dotted"><span>…</span></a></li>
              <li class="list-group-item after" :class="isLast ? 'none':''"><a class="canClick" @click="nextClick()"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16.8" width="13" height="18"><g id="レイヤー_2" data-name="レイヤー 2"><g id="レイヤー_2-2" data-name="レイヤー 2"><path id="パス_714" data-name="パス 714" class="cls-1" d="M7.3,8.4l-2.84,2L2.7,11.63V5.18L4.46,6.41l2.84,2m4.7,0L6,4.2,0,0V16.8l6-4.2,6-4.2Z"/></g></g></svg></span></a></li>
            </ul>
          </div>
        </div>
        <!-- ▲ ページャー -->
        
      </div>
    </div>
    
  </main>
</template>

<script>
  export default{
    data(){
      return{
        host: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        pMeta:[],
        contents:[],
        uInfo:[],
        fcpList:[],
        disList:[],
        catList:[],
        category:'',
        year:new Date().getFullYear(),
        month:new Date().getMonth()+1,
        day:new Date().getDate(),
        hour:new Date().getHours(),
        minutes:new Date().getMinutes(),
        seconds:new Date().getSeconds(),
        num:Number,
        minSlice:Number,
        perPage:9,
        curPage:Number,
        lastPage:Number,
        fadeapp:true,
        isFirst:false,
        isLast:false,
        isVisible:false,
        rtn:'',
      }
    },
    created(){
      this.getCategory()
      this.getData()
    },
    methods:{
       async getData(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=pic', { withCredentials: true })
          this.pMeta = response.data.pageMeta
          this.uInfo = this.pMeta.userInfo
          let loginAuth = this.pMeta.isAuthenticated
          this.catList = response.data.categories
          this.contents = response.data.contents
          if(response.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.fcpList = this.contents.filter((v)=> v.type == 'pic')
            this.setData(this.category)
            this.getPageCount()
            this.setPaging()
            window.scrollTo(0,0)
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      setData(val){
        this.disList.splice(-this.disList.length)
        if(val=='all'){
          for(var i=0; i<this.fcpList.length; i++){
              this.disList.splice(i,0,this.fcpList[i])
          }
        }else{
          let subList = this.fcpList.filter((v)=> v.categories[0].name == val)
          for(var k=0; k<subList.length; k++){
            this.disList.splice(k,0,subList[k])
          }
        }
        this.$store.commit('CHANGE_CATEGORY',val)
        this.getPageCount()
        this.setPaging()
      }, 
      pagemove(val){
        // scrollTo(0,0)
        if(this.$route.params['num']!=1){
          this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path':'/fanclubtop/pic/list/1?cat='+val});
          this.$router.push('/fanclubtop/pic/list/1?cat='+val)
        }else{
          this.path=this.$route.path
          this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': this.path+'?cat='+val});
          this.$router.push(this.path+'?cat='+val)         
        }
        this.$store.commit('PAGE_MOVE',1)
        this.curPage=1
        this.category = val
        this.setData(val)
      },
      setPaging(){
        this.num = this.$route.params['num']
        if(isNaN(this.num)){
          this.num = this.$store.getters.get_pnum
        }
        if(this.num <= this.lastPage){
          this.$store.commit('PAGE_MOVE',this.num)
          this.minSlice = (this.num-1)*this.perPage;
          this.curPage = this.num;
        }else{
          this.minSlice = 0;
          this.curPage = 1;
          this.$store.commit('PAGE_MOVE',Number(this.curPage))
        }
      },
      pageClick(page){
        this.fadeOut()
        // scrollTo(0,0)
        if(page<this.curPage){
          this.curPage=this.curPage-1
        }else if(page>this.curPage){
          this.curPage=this.curPage+1
        }
        this.minSlice = (page-1)*this.perPage;
        this.curPage = Number(page);
        this.$store.commit('PAGE_MOVE',this.curPage)
        this.tagMove({path:'/fanclubtop/pic/list/'+page+'?cat='+this.category, params:{}})
        setTimeout(this.fadeIn,400)
        //window.history.pushState(null,null,'/products/'+page);
      },
      preClick(){
        if(!(Number(this.curPage)<=1)){
          this.pageClick(Number(Number(this.curPage))-1);
        }
      },
      nextClick(){
        if(Number(this.curPage)!==this.lastPage){
          this.pageClick(Number(Number(this.curPage))+1);
        }
      },
      activeNum(page){
        if (Number(this.curPage)==1) {
          this.isFirst = true
          this.isLast = false
        }else if(Number(this.curPage)==this.lastPage){
          this.isLast = true
          this.isFirst = false
        }else{
          this.isFirst = false
          this.isLast = false
        }
        if(page==Number(this.curPage)){
          return 'active';
        }
      },
      getPageCount(){
        this.lastPage = Math.ceil(this.disList.length / this.perPage);
        if(this.lastPage<=1){
          this.isVisible=false;
        }else{
          this.isVisible=true;
        }
      },
      getCategory(){
        if(this.$route.query.cat !== void 0) {
          this.category = this.$route.query.cat
          this.$store.commit('CHANGE_CATEGORY',this.category)
        } else {
          this.category = 'all'
          this.$store.commit('CHANGE_CATEGORY',this.category)
        }
      },
      chkVisiblePage(num){
        if(this.lastPage>5){
          //総ページ５以上
          if(num>=Number(this.curPage)-2 && num<=Number(this.curPage)+2){
            //総ページ５以上 && 現在の前後２ページ
            return true
          }else if(Number(this.curPage)>this.lastPage-4){
            if(Number(this.curPage)>=this.lastPage-1){
              if(this.lastPage-num<=4){
                return true
              }else{
                return false
              }
            }else{
              return false
            }
          }else if(Number(this.curPage)<=2){
            if(num<=5){
              return true
            }else{
              return false
            }
          }else{
            return false
          }
        } else {
          //5ページまでは無条件にok
          return true
        }
      },
      chkNew(date){
        if(this.day.length==1){
          this.day = '0'+this.day
        }
        if(this.month.length==1){
          this.month = '0'+this.month
        }
        let now = Date.parse(this.year+'-'+this.month+'-'+this.day)
        let release = Date.parse(date)
        if(now>=release && now<release+604800000){
          return true
        }else{
          return false
        }
      },
      toPicDetail(id){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/pic/detail/'+id});
        this.$router.push('/fanclubtop/pic/detail/'+id)
      },
      tagMove(to){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': to.path});
        this.$router.push({'path':to.path, 'params':to.params})
      },
      datefilter(date){
        return date.replace(/-/g,'.')
      },
      fadeIn(){
        this.fadeapp = true
      },
      fadeOut(){
        this.fadeapp = false
      },
    }
  }
</script>