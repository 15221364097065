<template>
	<main id="main">	
		<div class="container">
		
			<!-- ▼ タイトル -->
			<div class="row title-wrapper m-0">
				<div class="title-content">
					<h1 class="questionnaire"><div class="container visual">内田雄馬と内田雄馬でカラオケ行かない？vol.2「チキンは持ったか？俺は持ってる。」アンケート</div></h1>
				</div>
			</div>
			<!-- ▲ タイトル -->
			
			<!-- ▼ コンテンツ -->
			<div class="main-content questionnaire pt-0">
				<div class="container visual">
					<div class="container medium">
					
						<!-- ▼ 冒頭テキスト -->
						<p>12月24日（水）20:00からの生配信、内田雄馬と内田雄馬でカラオケ行かない？vol.2「チキンは持ったか？俺は持ってる。」でご紹介するメッセージを大募集します！</p>
						<p>テーマは「みなさんのカラオケの十八番(おはこ)とそれにまつわるエピソード」です。<br>
							カラオケに行くと必ず歌ってしまう楽曲と、そのエピソードを教えてください♪</p>
						<!-- ▲ 冒頭テキスト -->
						
						<!-- ▼ 応募締切 -->
						<div class="oubo">
							<p>【 募集期間 】</p>
							<p>2024年12月1日（日）12:00 ～ 2024年12月19日（木）23:59</p>
						</div>
						<!-- ▲ 応募締切 -->

						<!-- ▼ 注意事項 -->
						<p>【注意事項】</p>
						<ul class="asterisk mt-0 text-small">
							<li>おひとり様１回のみのご応募となります。</li>
							<li>会報、ファンクラブページ（WEB）に掲載する可能性があります。</li>
							<li>メッセージは編集してご紹介する場合があります。</li>
						</ul>
						<!-- ▲ 注意事項 -->

						<p class="font-weight-500">【 <span class="text-orange">※</span> 】がついている項目は、入力必須です。</p>
					</div>
					
					<hr>
					
					<div class="container medium">
						<!-- ▼ アンケートフォーム -->
						<form>
							
							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2><span class="required-mark">※</span>Q1.あなたのカラオケの十八番(おはこ)を教えてください。（50文字以内）</h2>
								<p>※例：内田雄馬／Congrats!!</p>
								<textarea ref="i01_textarea" v-model="textarea01" @blur="i01TextareaChange"></textarea>
							</div>
							<!-- ▲ テキストエリア -->
							
							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2>Q2.その曲にまつわるエピソードを教えてください。（150文字以内）</h2>
								<textarea ref="i02_textarea" v-model="textarea02" @blur="i02TextareaChange"></textarea>
							</div>
							<!-- ▲ テキストエリア -->

							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2>Q3.ペンネームをお書きください。（50文字以内）</h2>
								<p>※記載がない場合は、イニシャルで紹介する場合がございます。</p>
								<textarea ref="i03_textarea" v-model="textarea03" @blur="i03TextareaChange"></textarea>
							</div>
							<!-- ▲ テキストエリア -->

							<!-- ▼ fcid -->
							<input type="hidden" name="i00_form_id" v-model="id">
							<!-- ▲ fcid -->



							<div class="pt-2 pt-md-5">
								<p class="mt-5 alert text-center font-weight-600" ref="errorRequired">※未入力の項目があります。</p>
								<p class="mt-5 alert text-center font-weight-600" ref="errorOverlength01">※Q1は50文字以内でご入力ください。</p>
								<p class="mt-5 alert text-center font-weight-600" ref="errorOverlength02">※Q2は150文字以内でご入力ください。</p>
								<p class="mt-5 alert text-center font-weight-600" ref="errorOverlength03">※Q3は50文字以内でご入力ください。</p>
								<div class="row justify-content-center mt-5">
									<div class="col-auto">
										<a href="/fanclubtop" class="btn-border big auto">TOP</a>
									</div>
									<div class="col-auto">
										<a class="canClick btn-theme auto px-5" @click="chkForms">確認する</a>
									</div>
								</div>
							</div>

							
						</form>
						<!-- ▲ アンケートフォーム -->
					</div>
				</div>
			</div>
			<!-- ▲ コンテンツ -->
			
		</div>
	</main>
</template>

<style>
.alert{
	display: none;
}
</style>

<script>
export default{
	data(){
		return{
			host: process.env.VUE_APP_IAM,
			cart: process.env.VUE_APP_CARTURI,
			cms: process.env.VUE_APP_CLIENTAPI,
			pMeta:[],
			id:'',
			textarea01:'',
			textarea02:'',
			textarea03:'',
			sendData:this.$store.getters.get_qnkaraoke2024,
			required01:false,
			required11:false,
			required12:false,
			required13:false
		}
	},
	created(){
		// scrollTo(0,0)
		this.getData()
	},
	methods:{
		async getData(){
			try{
				const response = await this.$axios.get(this.$uri+'/data_output', { withCredentials: true })
				this.pMeta = response.data.pageMeta
				let loginAuth = this.pMeta.isAuthenticated
				this.id = this.pMeta.userInfo.fc_no
				if(response.data.siteMeta.isInMaintenance){
					window.location.href="/maintenance/maintenance.html"
				}else if(!loginAuth){
					this.rtn = encodeURIComponent(this.cms+'/system/login?action=login')
					window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
				}else{
					this.textarea01 = this.sendData[1]
					this.textarea02 = this.sendData[2]
					this.textarea03 = this.sendData[3]
				}
			}catch(e){
				window.location.href="/error/504.html"
			}
		},
		i01TextareaChange(){
			//required
			let target = this.$refs.i01_textarea
			if(this.textarea01 == '' || this.textarea01.length > 50) {
				if(!target.classList.contains('error')){
					target.classList.add('error')
				}
				if(this.textarea01 == '') {
					this.required01 = true
				} else {
					this.required01 = false
				}
				if(this.textarea01.length > 50) {
					this.required11 = true
				} else {
					this.required11 = false
				}
			} else {
				if(target.classList.contains('error')){
					target.classList.remove('error')
				}
				this.textarea01 = this.textarea01.replace(/</g, '&lt;')
					.replace(/>/g, '&gt;')
					.replace(/"/g, '&quot;')
					.replace(/'/g, '&#x27')
				this.required01 = false
				this.required11 = false
			}
		},
		i02TextareaChange(){
			//norequired
			let target = this.$refs.i02_textarea
			if(this.textarea02.length > 150) {
				target.classList.add('error')
				this.required12 = true
			} else {
				if(target.classList.contains('error')){
					target.classList.remove('error')
				}
				if(this.textarea02 != '') {
					this.textarea02 = this.textarea02.replace(/</g, '&lt;')
							.replace(/>/g, '&gt;')
							.replace(/"/g, '&quot;')
							.replace(/'/g, '&#x27')
				}
				this.required12 = false
			}
		},
		i03TextareaChange(){
			//norequired
			let target = this.$refs.i03_textarea
			if(this.textarea03.length > 50) {
				target.classList.add('error')
				this.required13 = true
			} else {
				if(target.classList.contains('error')){
					target.classList.remove('error')
				}
				if(this.textarea03 != '') {
					this.textarea03 = this.textarea03.replace(/</g, '&lt;')
							.replace(/>/g, '&gt;')
							.replace(/"/g, '&quot;')
							.replace(/'/g, '&#x27')
				}
				this.required13 = false
			}
		},
		chkForms(){
			let alertBlockRequired = this.$refs.errorRequired
			let alertBlockOver01 = this.$refs.errorOverlength01
			let alertBlockOver02 = this.$refs.errorOverlength02
			let alertBlockOver03 = this.$refs.errorOverlength03
			this.i01TextareaChange()
			this.i02TextareaChange()
			this.i03TextareaChange()

			if(this.required01){
				alertBlockRequired.style.display = 'block'
			} else {
				alertBlockRequired.style.display = 'none'				
			}
			if(this.required11) {
				alertBlockOver01.style.display = 'block'
			} else {
				alertBlockOver01.style.display = 'none'				
			}
			if(this.required12) {
				alertBlockOver02.style.display = 'block'
			} else {
				alertBlockOver02.style.display = 'none'				
			}
			if(this.required13) {
				alertBlockOver03.style.display = 'block'
			} else {
				alertBlockOver03.style.display = 'none'				
			}
			if(!this.required01 && !this.required11 && !this.required12 && !this.required13){
				this.sendData[0] = this.id
				this.sendData[1] = this.textarea01
				this.sendData[2] = this.textarea02
				this.sendData[3] = this.textarea03

				this.$store.commit('CHANGE_QNKARAOKE2024',this.sendData)

				this.$router.push('/fanclubtop/questionnaire/yumatokaraoke2024-confirm')
			}
		}
	},
}
</script>