import Vue from 'vue'
import Vuex from 'vuex'
import multidata from './modules/multidata'
import qnforyuma2024 from './modules/qnforyuma2024'
import qnkaraoke2024 from './modules/qnkaraoke2024'
// import questionnaire01 from './modules/questionnaire01'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    multidata,
    qnforyuma2024,
    qnkaraoke2024,
  },
})