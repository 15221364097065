<template>
  <footer id="footer" class="text-center">
    <div class="footer-nav">
      <div class="container">
        <ul class="row justify-content-center">
          <li class="col-lg-auto"><a href="/faq">ヘルプ</a></li>
          <li class="col-lg-auto"><a :href="cart+'/v2/help/fc_contact.aspx?ccode=BYU'">コンタクト</a></li>
          <li class="col-lg-auto"><a href="/tokuten">会員特典</a></li>
          <li class="col-lg-auto"><a href="/keizoku">継続案内</a></li>
          <li class="col-lg-auto"><a :href="cart+'/v2/help/fc_kiyaku.aspx?ccode=BYU'">会員規約</a></li>
          <li class="col-lg-auto"><a :href="cart+'/v2/help/tokutei.aspx?ccode=BYU'">特定商取引法に関する表記</a></li>
          <li class="col-lg-auto"><a :href="cart+'/v2/help/privacy.aspx?ccode=BYU'">プライバシーポリシー</a></li>
          <li class="col-lg-auto"><a href="https://www.uchidayuma.com/" class="letter-wide">Official Site</a></li>
        </ul>
      </div>
    </div>
    <div class="footer-content">
      <div class="container">
        <object type="image/svg+xml" data="/images/footer.svg" width="520" height="121"></object>
        <p>©︎Beside YU All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
  export default{
    data(){
      return{
        cart: process.env.VUE_APP_CARTURI,
      }
    },
  }
</script>