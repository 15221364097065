import Vue from 'vue'
import router from './router'
import store from './store/store'
import {sync} from 'vuex-router-sync'
import slick from 'vue-slick'
import axios from 'axios'
import vueAxios from 'vue-axios'
import jQuery from 'jquery'

import header from './components/header.vue'
import footer from './components/footer.vue'
import index from './views/index.vue'
import top from './components/top.vue'
import fctop from './components/fctop.vue'
import fcBirthday from './components/fcBirthday.vue'
import fcMovie from './components/fcMovie.vue'
import fcMovieDetail from './components/fcMovieDetail.vue'
import fcPic from './components/fcPic.vue'
import fcPicDetail from './components/fcPicDetail.vue'
import fcBlog from './components/fcBlog.vue'
import fcBlogDetail from './components/fcBlogDetail.vue'
import fcLetter from './components/fcLetter.vue'
import fcWallpaper from './components/fcWallpaper.vue'
import fcNewsletter from './components/fcNewsletter.vue'
import fcNewsletterDetail from './components/fcNewsletterDetail.vue'
import fcInfomation from './components/fcInfomation.vue'
import fcInfomationDetail from './components/fcInfomationDetail.vue'
// import fcGoods from './components/fcGoods.vue'
// import fcGoodsDetail from './components/fcGoodsDetail.vue'
import tokuten from './components/tokuten.vue'
import keizoku from './components/keizoku.vue'
import faq from './components/faq.vue'
import faqEc from './components/faqEc.vue'
import faqFc from './components/faqFc.vue'


const URL = process.env.VUE_APP_CLIENTAPI+'/system'
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$uri = URL
Vue.prototype.$slick = slick
global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')
Vue.use(vueAxios,axios)

sync(store,router)


new Vue({
  router,
  store,
  render: h => h(header)
}).$mount('#header')

new Vue({
  router,
  store,
  // scrollTo,
  render: h => h(footer)
}).$mount('#footer')

new Vue({
  router,
  store,
  top,
  fctop,
  fcBirthday,
  fcMovie,
  fcMovieDetail,
  fcPic,
  fcPicDetail,
  fcBlog,
  fcBlogDetail,
  fcLetter,
  fcWallpaper,
  fcNewsletter,
  fcNewsletterDetail,
  fcInfomation,
  fcInfomationDetail,
  // fcGoods,
  // fcGoodsDetail,
  tokuten,
  keizoku,
  faq,
  faqEc,
  faqFc,
  render: h =>h(index)
}).$mount("#index")