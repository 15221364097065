<template>
  <main id="main">
    <div class="container">
    
      <!-- ▼ タイトル -->
      <div class="row title-wrapper m-0">
        <div class="title-content">
          <h1><div class="container visual">Goods</div></h1>
        </div>
      </div>
      <!-- ▲ タイトル -->
      
      <div class="row">
        <div class="main-content pt-0">
          <div class="container visual">
          
            <!-- ▼ メニューリンク -->
            <div class="row mx-lg-0 menu-links">
              <div class="col-md-auto mb-4 mb-md-0">
                <a :href="cart+'/v2/member/OrderList.aspx?ccode=BYU'" class="btn-border">申し込み履歴を見る</a>
              </div>
              <div class="col-md-auto">
                <a :href="cart+'/v2/shoppingcart.aspx?ccode=BYU'" class="btn-border">カートを見る</a>
              </div>
            </div>
            <!-- ▲ メニューリンク -->
            
            <!-- ▼ 注意書き -->
            <div class="row mx-lg-0">
              <div v-if="isAfter20240124" class="col-12">
                <div class="attention mt-5" v-show="isLoad">
                  <p><big><span class="text-theme">Beside YU 会員限定グッズ通信販売</span></big></p>
                  <p>
                    <strong>【商品発送時期】</strong><br>
                    商品のお届けは、ご注文をいただいてから 2 週間～1 ヶ月程度お時間をいただきます。<br>
                    <ul class="asterisk mt-0">
                      <li class="">「会報ファイル」をご注文の場合は、3 月下旬以降の発送となります。<br>
                      そのため、CD「Not Fighter／Foot on the melody」と「会報ファイル」を一緒にご注文された場合、
                      全て 3 月下旬以降の発送となりますので、ご注意ください。</li>
                    </ul>
                  </p>
                  <p><strong>【注意事項】</strong><br>
                    <ul class="asterisk mt-0">
                      <li>1回のお申込みにつき、商品の発送手数料として770円、ウェブ申し込み手数料として330円を頂戴いたします。</li>
                      <li>表示価格はすべて消費税込です。</li>
                      <li>商品の画像は実際の色味やデザインとは異なる場合がございます。</li>
                      <li>数量限定販売のため、お申込みが上限数に達した時点で受付は終了となり、ショッピングカートに入っている状態でもご購入いただけなくなりますのでご注意ください。</li>
                      <li>ご注文内容の配達予定日（発売日）はあくまでお届け日の目安となっております。発送が完了しましたら「商品発送完了メール」をお送りいたします。</li>
                      <li>一度受付を終了した商品でも、状況により予告なく販売を再開させていただく場合がございます。</li>
                      <li>購入手続き完了後のキャンセル及び注文内容変更、また不良品以外の返品・交換は対応いたしかねます。あらかじめご了承ください。</li>
                    </ul>
                  </p>
                  <p>『Beside YU “2nd” Fanclub Event ～YUnion～』グッズをご注文された方への商品発送は、3 月下旬を予定しております。ご到着まで今しばらくお待ちください。</p>
                  <p>
                    通信販売のご利用ガイドは<a href="/faq/EC" target="_blank">こちら</a>
                  </p>
                </div>
              </div>
              <div v-else class="col-12">
                <div class="attention mt-5" v-show="isLoad">
                  <p><big><span class="text-theme">『Beside YU “2nd” Fanclub Event ～YUnion～』グッズ事後通信販売</span></big></p>
                  <p>
                    <strong>【受付期間】</strong><br>
                    2024 年 1 月 16 日(火) 20:00～1 月 23 日(火) 23:59
                  </p>
                  <p>
                    <strong>【商品発送時期】</strong><br>
                    商品のお届けは、3 月下旬頃から随時発送予定となります。
                  </p>
                  <p><strong>【注意事項】</strong><br>
                    <ul class="asterisk mt-0">
                      <li>1回のお申込みにつき、商品の発送手数料として770円、ウェブ申し込み手数料として330円を頂戴いたします。</li>
                      <li>表示価格はすべて消費税込です。</li>
                      <li>商品の画像は実際の色味やデザインとは異なる場合がございます。</li>
                      <li>数量限定販売のため、お申込みが上限数に達した時点で受付は終了となり、ショッピングカートに入っている状態でもご購入いただけなくなりますのでご注意ください。</li>
                      <li>ご注文内容の配達予定日（発売日）はあくまでお届け日の目安となっております。発送が完了しましたら「商品発送完了メール」をお送りいたします。</li>
                      <li>一度受付を終了した商品でも、状況により予告なく販売を再開させていただく場合がございます。</li>
                      <li>購入手続き完了後のキャンセル及び注文内容変更、また不良品以外の返品・交換は対応いたしかねます。あらかじめご了承ください。</li>
                    </ul>
                  </p>
                  <p>
                    通信販売のご利用ガイドは<a href="/faq/EC" target="_blank">こちら</a>
                  </p>
                </div>
              </div>
            </div>
            <!-- ▲ 注意書き -->
            
            <!-- ▼ カテゴリ選択 -->
            <div class="row mx-lg-0 category-select">
              <div class="col-12">
                <h2>Category</h2>
                <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                      Category
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li><a class="dropdown-item canClick" @click="pagemove('all')">All</a></li>
                      <li v-for="cat in catList" :key="cat.index"><a class="dropdown-item canClick" @click="pagemove(cat.name)">{{cat.displayName}}</a></li>
                    </ul>
                </div>
              </div>
            </div>
            <!-- ▲ カテゴリ選択 -->
          
            <!-- ▼ 一覧 -->
            <div class="row wallpaper mx-lg-0">
              <!-- ▼ 繰り返しここから -->
              <div class="col-6 col-lg-4 goods-wrapper" v-for="goods in disList.slice(minSlice, minSlice+perPage)" :key="goods.index">
                <article class="goods position-relative">
                  <figure>
                    <picture>
                      <img :src="host+goods.images[0].url" :alt="goods.images[0].title" width="566" height="477">
                    </picture>
                  </figure>
                  
                  <h2>{{goods.title}}</h2>
                  <div class="cost">¥ {{goods.variations[0].price | addcomma}}</div>
                  <a class="stretched-link canClick" @click="toGoodsDetail(goods.id)"></a>
                </article>
              </div>
              <!-- ▲ 繰り返しここまで -->            
            </div>
            <!-- ▲ 一覧 -->
            
          </div>
        </div>
      </div>
        
      <!-- ▼ ページャー -->
      <div id="pager" :hidden="!isVisible">
        <ul class="list-group list-group-horizontal justify-content-center">
          <li class="list-group-item before" :class="isFirst ? 'none':''"><a class="canClick" @click="preClick()"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16.8" width="13" height="18"><defs></defs><g id="レイヤー_2" data-name="レイヤー 2"><g id="レイヤー_2-2" data-name="レイヤー 2"><path id="パス_714" data-name="パス 714" class="cls-1" d="M4.7,8.4l2.84-2L9.3,5.18v6.45L7.54,10.4,4.7,8.4M0,8.4l6,4.2,6,4.2V0L6,4.2,0,8.4Z"/></g></g></svg></span></a></li>
          <li class="list-group-item" v-if="(lastPage>5)&&(curPage>=4)"><a class="dotted"><span>…</span></a></li>
          <span v-for="page of lastPage" :key="page.index">
            <li class="list-group-item" v-if="chkVisiblePage(page)" :class="activeNum(page)"><a class="canClick" @click="pageClick(page)"><span>{{page}}</span></a></li>
          </span>
          <li class="list-group-item" v-if="(lastPage>5) && (Number(curPage)+2<lastPage)"><a class="dotted"><span>…</span></a></li>
          <li class="list-group-item after" :class="isLast ? 'none':''"><a class="canClick" @click="nextClick()"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16.8" width="13" height="18"><defs></defs><g id="レイヤー_2" data-name="レイヤー 2"><g id="レイヤー_2-2" data-name="レイヤー 2"><path id="パス_714" data-name="パス 714" class="cls-1" d="M7.3,8.4l-2.84,2L2.7,11.63V5.18L4.46,6.41l2.84,2m4.7,0L6,4.2,0,0V16.8l6-4.2,6-4.2Z"/></g></g></svg></span></a></li>
        </ul>
      </div>
      <!-- ▲ ページャー -->
      
    </div>
    
  </main>
</template>


<script>
  export default{
    data(){
      return{
        host: process.env.VUE_APP_IAM,
        cart:process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        pMeta:[],
        contents:[],
        uInfo:[],
        fcgList:[],
        disList:[],
        catList:[],
        category:'',
        year:new Date().getFullYear(),
        month:new Date().getMonth()+1,
        day:new Date().getDate(),
        hour:new Date().getHours(),
        minutes:new Date().getMinutes(),
        seconds:new Date().getSeconds(),
        num:Number,
        minSlice:Number,
        perPage:9,
        curPage:Number,
        lastPage:Number,
        fadeapp:true,
        isFirst:false,
        isLast:false,
        isVisible:false,
        isLoad:false,
        rtn:'',
      }
    },
    computed: {
      isAfter20240124() {
        const date = new Date()
        const limit = new Date(2024, 0, 24, 0, 0, 0)
        if(date >= limit){ return true }
        else { return false }
      }
    },
    created(){
      this.getCategory()
      this.getData()
    },
    methods:{
       async getData(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=goods', { withCredentials: true })
          this.pMeta = response.data.pageMeta
          this.uInfo = this.pMeta.userInfo
          let loginAuth = this.pMeta.isAuthenticated
          this.catList = response.data.categories
          this.contents = response.data.contents
          if(response.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.fcgList = this.contents.filter((v)=> v.type == 'goods')
            if(this.fcgList.length == 0){
              window.location.href="/fanclubtop"
            }else{
              this.isLoad=true
              this.setData(this.category)
              this.getPageCount()
              this.setPaging()
              window.scrollTo(0,0)
            }
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      setData(val){
        this.disList.splice(-this.disList.length)
        if(val=='all'){
          for(var i=0; i<this.fcgList.length; i++){
              this.disList.splice(i,0,this.fcgList[i])
          }
        }else{
          let subList = this.fcgList.filter((v)=> v.categories[0].name == val)
          for(var k=0; k<subList.length; k++){
            this.disList.splice(k,0,subList[k])
          }
        }
        this.$store.commit('CHANGE_CATEGORY',val)
        this.getPageCount()
        this.setPaging()
      }, 
      pagemove(val){
        // scrollTo(0,0)
        this.path=this.$route.path
        if(this.$route.params['num']!=1){
          const to = '/fanclubtop/goods/list/1?cat='+val
          if(this.path+'?cat='+this.$route.query.cat != to){
            this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path':'/fanclubtop/goods/list/1?cat='+val});
            this.$router.push('/fanclubtop/goods/list/1?cat='+val)
          }
        }else{
          const to = this.path+'?cat='+val
          if(this.path+'?cat='+this.$route.query.cat != to){
            this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': this.path+'?cat='+val});
            this.$router.push(this.path+'?cat='+val)
          }
        }
        this.$store.commit('PAGE_MOVE',1)
        this.curPage=1
        this.category = val
        this.setData(val)
      },
      setPaging(){
        this.num = this.$route.params['num']
        if(isNaN(this.num)){
          this.num = this.$store.getters.get_pnum
        }
        if(this.num <= this.lastPage){
          this.$store.commit('PAGE_MOVE',this.num)
          this.minSlice = (this.num-1)*this.perPage;
          this.curPage = this.num;
        }else{
          this.minSlice = 0;
          this.curPage = 1;
          this.$store.commit('PAGE_MOVE',Number(this.curPage))
        }
      },
      pageClick(page){
        this.fadeOut()
        // scrollTo(0,0)
        if(page<this.curPage){
          this.curPage=this.curPage-1
        }else if(page>this.curPage){
          this.curPage=this.curPage+1
        }
        this.minSlice = (page-1)*this.perPage;
        this.curPage = Number(page);
        this.$store.commit('PAGE_MOVE',this.curPage)
        this.tagMove({path:'/fanclubtop/goods/list/'+page+'?cat='+this.category, params:{}})
        setTimeout(this.fadeIn,400)
        //window.history.pushState(null,null,'/products/'+page);
      },
      preClick(){
        if(!(Number(this.curPage)<=1)){
          this.pageClick(Number(Number(this.curPage))-1);
        }
      },
      nextClick(){
        if(Number(this.curPage)!==this.lastPage){
          this.pageClick(Number(Number(this.curPage))+1);
        }
      },
      activeNum(page){
        if (Number(this.curPage)==1) {
          this.isFirst = true
          this.isLast = false
        }else if(Number(this.curPage)==this.lastPage){
          this.isLast = true
          this.isFirst = false
        }else{
          this.isFirst = false
          this.isLast = false
        }
        if(page==Number(this.curPage)){
          return 'active';
        }
      },
      getPageCount(){
        this.lastPage = Math.ceil(this.disList.length / this.perPage);
        if(this.lastPage<=1){
          this.isVisible=false;
        }else{
          this.isVisible=true;
        }
      },
      getCategory(){
        if(this.$route.query.cat !== void 0) {
          this.category = this.$route.query.cat
          this.$store.commit('CHANGE_CATEGORY',this.category)
        } else {
          this.category = 'all'
          this.$store.commit('CHANGE_CATEGORY',this.category)
        }
      },
      chkNew(date){
        if(this.day.length==1){
          this.day = '0'+this.day
        }
        if(this.month.length==1){
          this.month = '0'+this.month
        }
        let now = Date.parse(this.year+'-'+this.month+'-'+this.day)
        let release = Date.parse(date)
        if(now>=release && now<release+604800000){
          return true
        }else{
          return false
        }
      },
      chkVisiblePage(num){
        if(this.lastPage>5){
          //総ページ５以上
          if(num>=Number(this.curPage)-2 && num<=Number(this.curPage)+2){
            //総ページ５以上 && 現在の前後２ページ
            return true
          }else if(Number(this.curPage)>this.lastPage-4){
            if(Number(this.curPage)>=this.lastPage-1){
              if(this.lastPage-num<=4){
                return true
              }else{
                return false
              }
            }else{
              return false
            }
          }else if(Number(this.curPage)<=2){
            if(num<=5){
              return true
            }else{
              return false
            }
          }else{
            return false
          }
        } else {
          //5ページまでは無条件にok
          return true
        }
      },
      tagMove(to){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': to.path});
        this.$router.push({'path':to.path, 'params':to.params})
      },
      toGoodsDetail(id){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path':'/fanclubtop/goods/detail/'+id});
        this.$router.push('/fanclubtop/goods/detail/'+id)
      },
      datefilter(date){
        return date.replace(/-/g,'.')
      },
      fadeIn(){
        this.fadeapp = true
      },
      fadeOut(){
        this.fadeapp = false
      },
    },
    filters: {
      addcomma: function (value) {
        if (!value) return ''
        return value.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,')
      },
    },
  }
</script>