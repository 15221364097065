<template>
	<main id="main">
		<div class="container">

			<!-- ▼ タイトル -->
			<div class="row title-wrapper m-0">
				<div class="title-content">
					<h1 class="questionnaire"><div class="container visual">内田雄馬と内田雄馬でカラオケ行かない？vol.2「チキンは持ったか？俺は持ってる。」アンケート</div></h1>
				</div>
			</div>
			<!-- ▲ タイトル -->
			
			<!-- ▼ コンテンツ -->
			<div class="main-content questionnaire pt-0">
				<div class="container visual">
					<div class="container medium">
						
						<!-- ▼ 冒頭テキスト -->
						<p>12月24日（水）20:00からの生配信、内田雄馬と内田雄馬でカラオケ行かない？vol.2「チキンは持ったか？俺は持ってる。」でご紹介するメッセージを大募集します！</p>
						<p>テーマは「みなさんのカラオケの十八番(おはこ)とそれにまつわるエピソード」です。<br>
							カラオケに行くと必ず歌ってしまう楽曲と、そのエピソードを教えてください♪</p>
						<!-- ▲ 冒頭テキスト -->
						
						<!-- ▼ 応募締切 -->
						<div class="oubo">
							<p>【 募集期間 】</p>
							<p>2024年12月1日（日）12:00 ～ 2024年12月19日（木）23:59</p>
						</div>
						<!-- ▲ 応募締切 -->

						<!-- ▼ 注意事項 -->
						<p>【注意事項】</p>
						<ul class="asterisk mt-0 text-small">
							<li>おひとり様１回のみのご応募となります。</li>
							<li>会報、ファンクラブページ（WEB）に掲載する可能性があります。</li>
							<li>メッセージは編集してご紹介する場合があります。</li>
						</ul>
						<!-- ▲ 注意事項 -->

						<p class="font-weight-500">【 <span class="text-orange">※</span> 】がついている項目は、入力必須です。</p>
					</div>
					
					<hr>
					
					<div class="container medium">
						<!-- ▼ アンケートフォーム -->
						<form method="post" action="https://survey.modd.com/public/webapi/surveys/a5e15c2c-7068-4d57-abdf-67b921fba32f/answers/form" enctype="application/x-www-form-urlencoded">
							
							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2 class="confirm"><span class="required-mark">※</span>Q1.あなたのカラオケの十八番(おはこ)を教えてください。（50文字以内）</h2>
								<p>※例：内田雄馬／Congrats!!</p>
								<div class="answer"><p>{{data[1]}}</p></div>
							</div>
							<!-- ▲ テキストエリア -->
							
							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2 class="confirm">Q2.その曲にまつわるエピソードを教えてください。（150文字以内）</h2>
								<div class="answer">
									<p>{{data[2]}}</p>
								</div>
							</div>
							<!-- ▲ テキストエリア -->

							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2 class="confirm">Q3.ペンネームをお書きください。（50文字以内）</h2>
								<p>※記載がない場合は、イニシャルで紹介する場合がございます。</p>
								<div class="answer">
									<p>{{data[3]}}</p>
								</div>
							</div>
							<!-- ▲ テキストエリア -->
							
							<input type="hidden" name="i00_userid" :value="data[0]" hidden>
							<input type="hidden" name="i01_textarea01" :value="data[1]" hidden>
							<input type="hidden" name="i02_textarea02" :value="data[2]" hidden>
							<input type="hidden" name="i02_textarea02" :value="data[3]" hidden>

							<div class="pt-2 pt-md-5">
								<div class="row justify-content-center mt-5">
									<div class="col-auto">
										<a class="canClick btn-border big auto" @click="backInput">戻る</a>
									</div>
									<div class="col-auto">
										<button type="submit" style="border:none; background:unset;"><a class="canClick btn-theme auto px-5">回答する</a></button>
									</div>
								</div>
							</div>

						</form>
						<!-- ▲ アンケートフォーム -->
					</div>
				</div>
			</div>
			<!-- ▲ コンテンツ -->
		</div>
	</main>
</template>

<script>
	export default{
		data(){
			return{
				data:[],
				referrer:this.$router.referrer,
			}
		},
		created(){
			// scrollTo(0,0)
			this.chkReferrer()
		},
		methods:{
			chkReferrer(){
				if(this.referrer.path != '/fanclubtop/questionnaire/yumatokaraoke2024'){
					window.location.href = '/fanclubtop'
				}else{
					this.getData()
				}
			},
			getData(){
				this.data = this.$store.getters.get_qnkaraoke2024
				
			},
			backInput(){
				this.$router.push('/fanclubtop/questionnaire/yumatokaraoke2024')
			},
		},
	}
</script>