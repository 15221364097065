<template>
  <main id="main" class="pt-4 pb-0">
  
    <!-- ▼ ボタン＆バナー -->
    <div class="container normal mb-5">
      <div class="row gx-5">
        <div class="col-md-6 mb-4 mb-md-5" v-if="!isLogin"><a :href="cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+rtn" class="btn-arrow"><span class="tag">会員の方</span><br><span class="big">ログイン</span>はこちら</a></div>
        <div class="col-md-6 mb-4 mb-md-5" v-if="!isLogin"><a :href="cart+'/v2/fcJoin.aspx?ccode=BYU'" class="btn-arrow transparent"><span class="tag">入会ご希望の方</span><br><span class="big">入会</span>はこちら</a></div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 29.3" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1839" data-name="グループ 1839"><g id="FAQ" class="cls-1"><g class="cls-1"><path class="cls-2" d="M8.5,8.23v6.3H7.25V8.23Zm-.43,0H10.9V9.37H8.07Zm0,2.54h2.74v1.08H8.07Z"/><path class="cls-2" d="M13,12.45l0,.29-.82,1.79H10.78L14,7.86l3.21,6.67H15.7l-.8-1.72v-.35L14,10.29Zm-.56-.37h3l.16,1H12.32Z"/><path class="cls-2" d="M17.48,10.06a3.22,3.22,0,0,1,.71-1,3.27,3.27,0,0,1,1.06-.68,3.78,3.78,0,0,1,2.65,0A3.22,3.22,0,0,1,23,9a3.18,3.18,0,0,1,.68,1,3.26,3.26,0,0,1,.25,1.31,3.6,3.6,0,0,1-.24,1.32A3,3,0,0,1,23,13.75a3.27,3.27,0,0,1-1.05.7,3.62,3.62,0,0,1-1.33.25,3.68,3.68,0,0,1-1.35-.25,3.21,3.21,0,0,1-1.06-.7,3.07,3.07,0,0,1-.7-1.05,3.52,3.52,0,0,1-.24-1.32A3.34,3.34,0,0,1,17.48,10.06Zm1.27,2.2a1.87,1.87,0,0,0,1.09,1.11,2.11,2.11,0,0,0,.8.16,2.07,2.07,0,0,0,.8-.15,1.78,1.78,0,0,0,1-1.05,2.43,2.43,0,0,0,.14-.82,2.66,2.66,0,0,0-.14-.85,2.31,2.31,0,0,0-.41-.7,2.07,2.07,0,0,0-.65-.48,2.16,2.16,0,0,0-.86-.17,1.89,1.89,0,0,0-.79.16,1.76,1.76,0,0,0-.64.43,2,2,0,0,0-.41.66,2.43,2.43,0,0,0-.14.82A2.3,2.3,0,0,0,18.75,12.26Zm2.82-.43,2.94,2.78H23.07l-2.9-2.78Z"/></g></g><path class="cls-2" d="M8.06,29.3V23H5.5A5.52,5.52,0,0,1,0,17.5V5.5A5.52,5.52,0,0,1,5.5,0h19A5.52,5.52,0,0,1,30,5.5v12A5.52,5.52,0,0,1,24.5,23H14.35ZM9.56,20h1.5v2.05l2-2.05H24.5A2.51,2.51,0,0,0,27,17.5V5.5A2.52,2.52,0,0,0,24.5,3H5.5A2.51,2.51,0,0,0,3,5.5v12A2.51,2.51,0,0,0,5.5,20Z"/></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                ファンクラブよくある質問
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq/FC" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 29.65" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1842" data-name="グループ 1842"><path id="パス_2370" data-name="パス 2370" class="cls-1" d="M24.91,17.74a11.57,11.57,0,0,1-12.8,9.79l8.4-12A10.16,10.16,0,0,1,19.09,14l-2.66,3.81L11,10h5.14L14,13.11l1.24,1.77,2.45-3.51A9.93,9.93,0,0,1,17,8H9A1,1,0,0,0,8,9a1,1,0,0,0,.19.58l7,10L10,27.05a10.86,10.86,0,0,1-2.49-1.16l5.64-8.06-1.24-1.77-1.24,1.77L4.48,9A11.51,11.51,0,0,1,17.42,5.29a9.8,9.8,0,0,1,.74-1.88,13.53,13.53,0,1,0,8.08,17.34A13.39,13.39,0,0,0,26.93,18,9.84,9.84,0,0,1,24.91,17.74ZM2,16.12A11.54,11.54,0,0,1,3.3,10.87L9.41,19.6,5.86,24.68A11.44,11.44,0,0,1,2,16.12Z"/><path id="パス_2371" data-name="パス 2371" class="cls-1" d="M27,0a8,8,0,1,0,8,8A8,8,0,0,0,27,0Zm-.1,13a1.22,1.22,0,1,1,1.22-1.22h0A1.22,1.22,0,0,1,26.93,13Zm1.91-5.35c-.6.46-.82.63-.82,1.22C28,9.32,28,10,27,10c-.82,0-1.12-.27-1.12-1.07a2.43,2.43,0,0,1,1-2.1c1-.7,1.25-.9,1.25-1.26,0-.64-.86-.64-1-.64-1.06,0-1.13.6-1.17,1s-.53.56-1.12.56c-.38,0-1.14-.08-1.14-.83s.76-2.68,3.51-2.68c2.1,0,3.22,1.3,3.22,2.58,0,1-.51,1.35-1.55,2.18Z"/></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                ファンクラブご利用ガイド
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq/EC" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 30.34" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1834" data-name="グループ 1834"><g id="グループ_1833" data-name="グループ 1833"><path id="パス_2372" data-name="パス 2372" class="cls-1" d="M22.32,23.48a3.39,3.39,0,0,0-2.61,1.23H11.65a3.43,3.43,0,0,0-1.92-1.16l-.41-2H24.59l.61-4.13a9.49,9.49,0,0,1-2.27-1l-.41,2.76H16.78V16.16h5.83a10,10,0,0,1-2.5-2.4H16.78V10.81h1.84a9.36,9.36,0,0,1-.41-2.4H6.65l-.6-2.95H0v2.4H4.08L7.36,23.94a3.42,3.42,0,1,0,5.09,3.18h6.46a3.42,3.42,0,1,0,3.41-3.64Zm-7.93-4.37H8.83l-.6-2.95h6.16Zm-7.25-8.3h7.25v2.95H7.74ZM10,27.11a1,1,0,0,1-2,0,.65.65,0,0,1,0-.2,1,1,0,1,1,2.05,0,.78.78,0,0,1,0,.21Zm12.29.82a1,1,0,0,1-1-.82,1.18,1.18,0,0,1,0-.2,1,1,0,0,1,1-1h0a1,1,0,0,1,0,2.05Z"/><path id="パス_2373" data-name="パス 2373" class="cls-1" d="M28.08,0A7.92,7.92,0,1,0,36,7.92,7.91,7.91,0,0,0,28.08,0ZM28,12.93a1.21,1.21,0,1,1,1.21-1.21h0A1.21,1.21,0,0,1,28,12.93Zm1.89-5.3c-.59.45-.81.62-.81,1.2,0,.41,0,1.07-1,1.07-.82,0-1.12-.27-1.12-1.07a2.4,2.4,0,0,1,1-2.07c1-.7,1.24-.9,1.24-1.26,0-.63-.86-.63-1-.63-1,0-1.11.6-1.16,1s-.52.55-1.1.55c-.38,0-1.14-.08-1.14-.82S25.5,2.9,28.22,2.9c2.09,0,3.2,1.29,3.2,2.57,0,.94-.51,1.33-1.54,2.16Z"/></g></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                通販ご利用ガイド
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- ▲ ボタン＆バナー -->
    
    <!-- ▼ 通販ご利用ガイド -->
    <div id="info">
      <div class="container title-block">
        <h2><div class="container normal">通販ご利用ガイド</div></h2>
      </div>
      
      <div class="container normal faq">
        <div class="faq-others mt-4">
          <h3>注文方法</h3>
          <h4>① ファンクラブ会員専用ページにログインする</h4>
          <p>「会員番号もしくは登録のメールアドレス」と「Beside YUにご登録いただいたパスワード（ご自身で設定された半角英数字混合8～20文字）」でログインしてください。 会員番号やメールアドレス、パスワードは半角英数字でご入力ください。その際、大文字・小文字などの間違いがないことをご確認ください。</p>
          <p>パスワードを忘れた方は下記からパスワードの再設定をおこなってください。<br><a :href="cart+'/v2/Passforgot.aspx?ccode=BYU'" target="_blank">>>パスワードの再設定</a></p>
          <p>通信販売をご利用いただけるのは「Beside YU」会員のみとなります。<br>まだ会員でない方は、新規入会手続きを行ってください。<br><a :href="cart+'/v2/fcJoin.aspx?ccode=BYU'" target="_blank">>>新規入会</a></p>
          <h4>② グッズ通信販売ページにいく</h4>
          <p>会員専用ページにログイン後、MENU項目の「Goods」をクリックしてください。</p>
          <h4>③ 商品をカートに入れる</h4>
          <p>ご希望の商品が決まったら［購入する］ボタンを押してください。<br>ご希望の商品を全てショッピングカートへ入れたら、［購入する］を押して、「ご注文情報を入力」画面に進みます。</p>
          <p>カート内［買い物を続ける］ボタンを押すと、 商品ページに戻ります。複数商品をショッピングカートに入れる場合に押してください。</p>
          <ul class="asterisk small">
            <li>一部商品は予約販売です。ご注文完了をもって予約商品の確保となります。</li>
            <li>発売前の商品および発送までにお時間をいただく商品には、「予約商品です」と表示されます。</li>
            <li>複数商品を同時にご注文の場合は、商品発送時期がもっとも遅い商品にあわせて一括でのお届けになります。</li>
          </ul>
          <h4>④ 配送先登録</h4>
          <p>ファンクラブに登録されている住所・氏名・電話番号・メールアドレスが表示されます。<br>ご登録住所と異なる住所に配送をご希望の場合は、「配送先の指定」の入力フォームに新たにお届け先の住所を入力してください。</p>
          <ul class="asterisk small mt-0">
            <li>配送先は日本国内のみとさせていただいております。</li>
          </ul>
          <p>注文完了後にお届け先の変更をご希望の場合は、該当の注文番号を記載の上、<a :href="cart+'/v2/help/fc_contact.aspx?ccode=BYU'" target="_blank">こちら</a>よりファンクラブまでご連絡ください。</p>
          <p>なお、ファンクラブ登録住所の変更も必要な場合は、下記から変更手続きを行ってください。<br><a :href="cart+'/v2/member/CustomerEdit.aspx?ccode=BYU'" target="_blank">>>登録情報の変更</a></p>
          <p>ファンクラブ登録情報を変更されても、注文済みの通信販売の配送先・メールアドレス等の情報は更新されません。必ずファンクラブまでご連絡ください。</p>
          <h4>⑤ お支払い方法の指定</h4>
          <p>下記の方法からお選びいただけます。</p>
          <ul class="dot mt-0">
            <li>クレジットカード支払い</li>
            <li>コンビニエンスストア支払い
            </li>
          </ul>
          <p>コンビニエンスストア支払いをお選びいただいた場合、ご注文完了画面で支払い情報をご案内いたしますので、受付番号等ご確認ください（「ご注文完了のご案内」メールにも記載しております）。</p>
          <p>なお、ご注文完了後、お支払い方法の変更はできません。あらかじめご了承ください。<br>ただし、クレジットカードでお支払いいただいたお客様でカード会社の承認がされない場合には、別途ご案内メールを差し上げますので クレジットカード番号・有効期限の変更をお願いいたします。</p>
          <h4>⑥ ご注文内容の最終確認</h4>
          <p>ご注文内容の最終確認を行います。ご注文商品、お届け先、お支払い方法、ご注文金額などのご確認をお願いします。</p>
          <p>［注文を完了する]を押すと、ご注文が確定します。</p>
          <h4>⑦ ご注文受付完了メールの受信</h4>
          <p>ご注文完了後、「ご注文情報を入力」画面にてご注文時に登録されたメールアドレス宛に「ご注文完了のご案内」メールを配信いたしますので、ご注文内容をご確認ください。</p>
          <ul class="asterisk small mt-0">
            <li>迷惑メール対策を実施されている方は「@besideyu.club」のメールが受信できる様にご設定ください。</li>
          </ul>
          <p>また、ご注文内容は「ご注文履歴（申込み履歴）」からもご確認いただけます。</p>
          <h4>⑧ 出荷完了メールの受信</h4>
          <p>ご注文の商品が出荷されると、ご注文時に登録されたメールアドレス宛に「商品出荷」メールを配信しております。<br>このメールの到着後、ご購入履歴からお荷物問い合わせサービスにて、配送状況をご確認いただけるようになります。</p>
          <ul class="asterisk small mt-0">
            <li>迷惑メール対策を実施されている方は「@besideyu.club」のメールが受信できる様にご設定ください。</li>
            <li>会場受取販売の場合は「商品出荷」メールはお送りしておりません。</li>
          </ul>
          <p>商品注文時からメールアドレスが変更になった方は、商品出荷前までにメールアドレスを変更いただくとメールの受信が可能となります。<br>
            変更をご希望の場合は、該当の注文番号を記載の上、<a :href="cart+'/v2/help/fc_contact.aspx?ccode=BYU'" target="_blank">こちら</a>よりファンクラブまでご連絡ください。</p>
          <p>なお、ファンクラブ登録メールアドレスの変更も必要な場合は、下記から変更手続きを行ってください。<br><a :href="cart+'/v2/member/CustomerEdit.aspx?ccode=BYU'" target="_blank">>>登録情報の変更</a></p>
        </div>
      </div>
      
      <div class="container normal faq">
        <h3>支払い方法</h3>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq2_1" aria-expanded="false" aria-controls="faq2_1">お支払い方法</h4>
          <div class="collapse multi-collapse answer" id="faq2_1">
            <p>お支払方法は下記の方法からお選びいただけます。</p>
            <ul class="dot mt-0">
              <li>クレジットカード支払い</li>
              <li>コンビニエンスストア支払い</li>
            </ul>
            <ul class="asterisk small mt-0">
              <li>1回にご注文いただける金額は、手数料を含め¥299,999（税込）までとさせていただきます。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq2_2" aria-expanded="false" aria-controls="faq2_2">手数料</h4>
          <div class="collapse multi-collapse answer" id="faq2_2">
            <p>1回の発送につき、商品の発送手数料として770円(税込)、ウェブ申込み手数料として330円(税込)を頂戴いたします。</p>
            <ul class="asterisk small mt-0">
              <li>日本国内への配送のみとさせていただいております。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq2_3" aria-expanded="false" aria-controls="faq2_3">クレジットカード決済</h4>
          <div class="collapse multi-collapse answer" id="faq2_3">
            <p>下記のカードおよび下記の表示のある提携カードがご利用いただけます。</p>
            <p><img src="/images/credit.png" alt="利用可能なクレジットカード" width="285" height="61"></p>
            <h5>● お支払い回数</h5>
            <p>一括払いのみとなります。</p>
            <h5>● 請求日</h5>
            <p>ご利用日に対する引き落とし時期は、ご利用のカード会社の規定に準じます。</p>
            <h5>● カードの有効確認について</h5>
            <p>発売日が先の商品をご予約の際、注文時にご登録いただいたクレジットカードが有効かどうかを再度カード会社に確認させていただく場合がございます。<br>ご利用のクレジットカード会社でお取引が承認されない場合は、配送手続きを中断し、注文時に登録されたメールアドレスあてにお支払い方法の変更手順をご連絡いたします。<br>メールの内容をご確認の上、早急に支払い方法をご変更ください。<br>この場合、ご注文の商品を希望日時にお届け出来ない可能性がございます。<br>また、一定期間内に決済可能な支払い方法をご指定いただけない場合は、ご注文を無効とさせていただく場合もございますので、あらかじめご了承ください。</p>
            <h5>● デビットカード機能付きクレジットカード、プリペイド式クレジットカードのご利用について</h5>
            <ul class="dot wide">
              <li>一部のデビットカード機能付きクレジットカードやプリペイド式クレジットカードには、ご注文時にお客様の口座からご請求予定額が一旦引き落としされるタイプがございます。</li>
              <li>ご注文後、一定期間が経過いたしますと、注文時にご登録いただいたクレジットカードが有効かどうかを再度、クレジットカード会社に確認させていただく場合がございます。その際、<span class="danger">一時的に二重引落としになる可能性</span>がございます。ご注文時の引落とし金額は、ご利用のカード会社の規定に準じ口座に戻されます。</li>
              <li>商品がご購入いただけない場合も、ご利用のカード会社の規定に準じ口座に戻されます。<br>返金までの正確な期間は、お客様とカード発行元会社との契約により異なります。 詳細はお持ちのカードの発行元（デビットカードの場合は各金融機関、プリペイド式クレジットカードの場合はカード会社）までお問い合わせください。<br>ご利用の場合は、上記をご了承の上、ご利用いただけますようお願い申し上げます。</li>
            </ul>
            <h5>● セキュリティコードについて</h5>
            <p>クレジットカードの裏面、ご署名欄上部に記載されている番号の末尾3桁または4桁の数字を指します（一部のクレジットカードは表面に記載されています）。<br>セキュリティコードがわからない場合、または印字されていない場合は、お手数ですが、カード発行会社（カード裏面に記載されている連絡先）にお問い合わせください。</p>
            <p>カード裏面に記載されている場合</p>
            <div class="row mt-4">
              <div class="col-12 text-center col-sm-auto mb-4">
                <img src="/images/faq/card_securitycode_left.png" alt="カード裏面に記載されている場合" width="140" height="90">
              </div>
              <div class="col-12 text-center col-sm-auto mb-4">
                <img src="/images/faq/card_securitycode_center.png" alt="カード裏面に記載されている場合" width="140" height="90">
              </div>
              <div class="col-12 text-center col-sm-auto mb-4">
                <img src="/images/faq/card_securitycode_right.png" alt="カード裏面に記載されている場合" width="140" height="90">
              </div>
            </div>
            <p>カード表面に記載されている場合</p>
            <div class="row mt-4">
              <div class="col-12 text-center col-sm-auto mb-4">
                <img src="/images/faq/other_card_securitycode_left.png" alt="カード表面に記載されている場合" width="140" height="90">
              </div>
              <div class="col-12 text-center col-sm-auto mb-4">
                <img src="/images/faq/other_card_securitycode_right.png" alt="カード表面に記載されている場合" width="140" height="90">
              </div>
            </div>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq2_4" aria-expanded="false" aria-controls="faq2_4">コンビニエンスストア支払い</h4>
          <div class="collapse multi-collapse answer" id="faq2_4">
            <p>コンビニエンスストア支払いを選択された場合、下記のコンビニをご利用いただけます。 店頭にてご注文金額をお支払いください（商品のお受け取りはできません）。</p>
            <p><img src="/images/convenience.png" alt="利用可能なクレジットカード" width="402" height="60"></p>
            <p>ローソン、ファミリーマート、セイコーマート、ミニストップ、デイリーヤマザキ、ヤマザキデイリーストア</p>
            <ul class="asterisk small">
              <li>コンビニへの代金のお支払い期限はお申込み日から 3日以内となります。<br>入金期間を過ぎてのご入金はすべて無効となります。その場合は未入金扱いとなり、今後ファンクラブでのグッズ・チケット等のご購入を一切お断りいたします。</li>
              <li>コンビニエンスストア手数料は必要ありません。</li>
            </ul>
            <h5>【 ご利用方法 】</h5>
            <h5>● ファミリーマート</h5>
            <ul class="number">
              <li>店内に設置されているFamiポート端末トップページより「代金支払い」を選択してください。</li>
              <li>メールに記載の"受付番号（6桁）"を入力し、「OK」を選択してください。</li>
              <li>ファンクラブに登録の"電話番号"を入力し、「OK」を選択してください。</li>
              <li>お支払い内容を確認のうえ、「OK」を選択してください。</li>
              <li>出力される「申込券」を持って、レジにて30分以内にお支払いください。</li>
            </ul>
            <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/familymart.html" target="_blank">こちら</a>にてご確認ください。</p>
            <ul class="asterisk small">
              <li>お支払いは、現金もしくはファミマTカードでのクレジット決済となります。</li>
              <li>代金と引き換えに「受領書」をお渡ししますので、必ずお受取りください。</li>
              <li>「受領書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
            </ul>
            <h5>● ローソン・ミニストップ（MINISTOP Loppi設置店）</h5>
            <ul class="number">
              <li>店内に設置されているLoppi端末より「各種番号をお持ちの方」を選択してください。</li>
              <li>メールに記載の"受付番号（6桁）"を入力し、「次へ」を選択してください。</li>
              <li>ファンクラブに登録の“電話番号”を入力し、「次へ」を選択してください。</li>
              <li>お申し込み内容を確認のうえ、「了解」を選択してください。</li>
              <li>出力される「申込券」を持って、レジにて30分以内にお支払いください。</li>
            </ul>
            <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/lawson.html" target="_blank">こちら</a>にてご確認ください。</p>
            <ul class="asterisk small">
              <li>お支払いは、現金のみとなります。</li>
              <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
              <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
            </ul>
            <h5>● デイリーヤマザキ・ヤマザキデイリーストア</h5>
            <p>オンライン決済番号、もしくはご購入履歴画面から決済画面を印刷したものをレジにてご提示ください。店員に「オンライン決済」とお伝えの上、レジにて代金をお支払いください。お支払いは現金のみとなります。</p>
            <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/yamazaki.html" target="_blank">こちら</a>にてご確認ください。</p>
            <ul class="asterisk small">
              <li>お支払いは現金のみとなります。</li>
              <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
              <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
            </ul>
            <h5>● セイコーマート</h5>
            <ul class="number">
              <li>レジにて「インターネット支払い」とお伝えください。</li>
              <li>メールに記載の"受付番号（6桁）"を入力してください。</li>
              <li>ファンクラブに登録の"電話番号"を入力してください。</li>
              <li>注文内容をご確認の上、代金をお支払いください。お支払いは現金のみとなります。</li>
            </ul>
            <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/seicomart.html" target="_blank">こちら</a>にてご確認ください。</p>
            <ul class="asterisk small">
              <li>お支払いは現金のみとなります。</li>
              <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
              <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq2_5" aria-expanded="false" aria-controls="faq2_5">お支払方法の変更</h4>
          <div class="collapse multi-collapse answer" id="faq2_5">
            <p>ご注文完了後、お支払い方法の変更はできません。あらかじめご了承ください。</p>
            <p>ただし、お支払い方法にクレジットカード支払いをご選択いただいた方でカード会社の承認がされない場合には、別途お支払い方法の変更手順をメールにてご連絡いたします。メール内容をご確認の上、早急に支払い方法をご変更ください。<br>お支払い方法をご変更されず商品の発送ができなかった場合には、今後ファンクラブでのチケット・グッズの購入をお断りさせていただきます。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq2_6" aria-expanded="false" aria-controls="faq2_6">領収書</h4>
          <div class="collapse multi-collapse answer" id="faq2_6">
            <h5>● クレジットカード決済の場合</h5>
            <p>商品に同梱されている「納品書 兼 領収書」を領収書としてご利用ください。</p>
            <ul class="asterisk small mt-0">
              <li>納品書の再発行は承っておりませんので、あらかじめご了承ください。</li>
            </ul>
            <h5>● コンビニエンスストア支払いの場合</h5>
            <p>各コンビニエンスストア払込受領証をもって領収書とさせていただきます。<br>領収書はご入金いただいた証明になります。お手元に商品が届くまで大切に保管してください。</p>
          </div>
        </div>
      </div>
      <div class="container normal faq">
        <h3>配送</h3>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq3_1" aria-expanded="false" aria-controls="faq3_1">お届け日数について</h4>
          <div class="collapse multi-collapse answer" id="faq3_1">
            <h5>● 予約商品のお届日</h5>
            <p>商品の発売日はお届け日の目安となっており、多少前後します。<br>発送が完了しましたら「商品発送完了メール」をお送りいたします。</p>
            <ul class="asterisk small mt-0">
              <li>予約商品を含む複数商品を同時にご注文の場合は、発売日がもっとも遅い予約商品にあわせて一括でのお届けとなります。</li>
            </ul>
            <h5>● 複数の商品を同時にご注文の場合のお届け日</h5>
            <p>予約商品を含む複数商品を同時にご注文の場合は、発売日がもっとも遅い予約商品にあわせて一括でのお届けになります。</p>
            <ul class="asterisk small">
              <li>コンビニ支払いの場合は、お支払完了日を注文完了日とさせていただきます。</li>
              <li>配送期間内に日曜日・祝日・弊社または配送業者の指定休日を含む場合はお時間がかかります。</li>
              <li>天候や配送状況により、お届けが遅れる場合がございます。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq3_2" aria-expanded="false" aria-controls="faq3_2">配送状況の確認</h4>
          <div class="collapse multi-collapse answer" id="faq3_2">
            <p>ご注文の商品が出荷されると、ご注文時に登録されたメールアドレスあてに商品出荷のお知らせが届きます。<br>このメールの到着後、ご購入履歴からお荷物問い合わせサービスにて、配送状況をご確認いただけるようになります。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq3_3" aria-expanded="false" aria-controls="faq3_3">別々の注文をまとめて配送してほしい</h4>
          <div class="collapse multi-collapse answer" id="faq3_3">
            <p>ご注文ごとに発送するため、ご注文完了後は一括配送へ変更することはできません。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq3_4" aria-expanded="false" aria-controls="faq3_4">配達時に不在の場合</h4>
          <div class="collapse multi-collapse answer" id="faq3_4">
            <p>「ご不在票」に記載された配送業者の連絡先へお問い合わせください。</p>
            <ul class="asterisk small">
              <li>配送業者の保管期間が過ぎた商品は、発送元へ返送されます。</li>
              <li>返送されたご注文は、キャンセル扱いとなる場合がございます。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq3_5" aria-expanded="false" aria-controls="faq3_5">返送商品の再発送について</h4>
          <div class="collapse multi-collapse answer" id="faq3_5">
            <p>商品を受け取らず、発送元に返送された場合、こちらよりご案内メールをお送りいたします。<br>再出荷に関しては「着払い」でのお届けとなり、配送料はお客様にてご負担いただきます（この際、着払い配送料はお届け先地域によって異なります。あらかじめご了承ください）。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq3_6" aria-expanded="false" aria-controls="faq3_6">プレゼント包装</h4>
          <div class="collapse multi-collapse answer" id="faq3_6">
            <p>プレゼント包装は承っておりません。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq3_7" aria-expanded="false" aria-controls="faq3_7">配送業者</h4>
          <div class="collapse multi-collapse answer" id="faq3_7">
            <p>弊社指定配送業者</p>
          </div>
        </div>
      </div>
      <div class="container normal faq">
        <h3>キャンセル・返品・交換</h3>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq4_1" aria-expanded="false" aria-controls="faq4_1">ご注文のキャンセル</h4>
          <div class="collapse multi-collapse answer" id="faq4_1">
            <p>お申し込み後のキャンセルおよび変更は一切できません。</p>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq4_2" aria-expanded="false" aria-controls="faq4_2">返品・交換</h4>
          <div class="collapse multi-collapse answer" id="faq4_2">
            <p>お届けした商品に破損が見られる場合、またはご注文と異なる商品が届いた場合は商品を交換させていただきます。<br>商品お受け取り後、8日以内にファンクラブへお問い合わせください。</p>
            <p><a :href="cart+'/v2/help/fc_contact.aspx?ccode=BYU'" target="_blank">>>お問い合わせメールフォーム</a></p>
            <ul class="asterisk small">
              <li>商品到着から8日を過ぎた商品・開封済み商品・不良が確認できない場合、またはご注文間違いなどお客様のご都合による商品の返品・交換はお受けできません。</li>
              <li>商品が在庫切れの場合や、生産終了時は商品の代金を返金させていただく場合がございます。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq4_3" aria-expanded="false" aria-controls="faq4_3">クーリングオフについて</h4>
          <div class="collapse multi-collapse answer" id="faq4_3">
            <p>クーリングオフは、訪問販売・電話勧誘販売などにおいて消費者を保護するための制度です。<br>当サイトを含めた通信販売にクーリングオフは適用されません。あらかじめご了承ください。</p>
          </div>
        </div>
      </div>
      <div class="container normal">
        <div class="faq-others">
          <h3>発送時期の変更・発売の中止</h3>
          <h4>● 発送時期の変更</h4>
          <p>ご注文いただいた商品の発送時期が変更された場合は、注文時に登録されたメールアドレス宛にお知らせします。その場合、キャンセル・返金等はお受けできかねます。ご了承ください。</p>
          <ul class="asterisk small mt-0">
            <li>複数商品を同時にご注文の場合は、発送時期がもっとも遅い商品にあわせたお届けとなります。</li>
          </ul>
          <h4>● 発送時期の変更</h4>
          <p>ご注文いただいた商品の発売が中止になった場合は、注文時に登録されたメールアドレス宛にお知らせします。対象商品のご注文はキャンセルとさせていただきます。</p>
          <ul class="asterisk small">
            <li>複数商品を同時にご注文の場合は、その他商品のご注文は継続されます。</li>
            <li>すでにお支払い済みの商品が発売中止になった場合は、返金させていただきます。<br>返金方法は、ご注文時に登録されたメールアドレスあてにお送りします。</li>
          </ul>
          <p>プライバシーポリシー<br><a :href="cart+'/v2/help/privacy.aspx?ccode=BYU'" target="_blank">こちら</a>よりご確認ください。</p>
          <p>特定商取引法に基づく表示<br><a :href="cart+'/v2/help/tokutei.aspx?ccode=BYU'" target="_blank">こちら</a>よりご確認ください。</p>
        </div>
      </div>
    </div>
    <!-- ▲ 通販ご利用ガイド -->
    
    
  </main>
</template>

<script>
  export default{
    data(){
      return{
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        isLogin:false,
        rtn:'',
      }
    },
    created(){
      this.rtn = encodeURIComponent(this.cms+'/system/login?action=login')
      this.chkLogin()
    },
    methods:{
      async chkLogin(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=static', { withCredentials: true })
          if(response.data.pageMeta.isAuthenticated){
            this.isLogin = true
          }else{
            this.isLogin = false
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      }
    },
  }
</script>