<template>
  <main id="main">  
    <div class="single-content">
      
      <!-- ▼ タイトル部分 -->
      <div class="container">
        <header>
          <div class="row">
            <div class="col-lg-12"><h1 class="birthday">{{title}}</h1></div>
          </div>
        </header>
      </div>
      <!-- ▲ タイトル部分 -->
      
      <!-- ▼ テキスト -->
      <div class="container contents px-lg-5">
        <div class="slider-container px-lg-5 pb-0">
          <ul>
            <slick ref="slick" :options="slickOptions" class="slider kaiho">
              <li v-for="(image,index) in images" :key="index">
                <picture>
                  <img :src="host+image" :alt="'会報誌_'+index" width="700" height="990" class="my-0">
                </picture>
              </li>
            </slick>
          </ul>
        </div>
      </div>
      <!-- ▲ テキスト -->
      
      <!-- ▼ 一覧に戻る -->
      <div class="container minimum">
        <div class="back text-center">
          <div class="row">
            <div class="col-6" :style="{visibility : isFirst ? 'hidden' : 'visible'}">
              <a class="canClick btn-theme auto w-100" @click="pageMove('pre')">前へ</a>
            </div>
            <div class="col-6" :style="{visibility : isLast ? 'hidden' : 'visible'}">
              <a class="canClick btn-theme auto w-100" @click="pageMove('next')">次へ</a>
            </div>
            <div class="col-12 mt-4">
              <a class="canClick btn-theme auto w-100" @click="tonlList()">一覧に戻る</a>
            </div>
          </div>
        </div>
      </div>
      <!-- ▲ 一覧に戻る -->
      
    </div>
    
  </main>
</template>


<script>
  import slick from 'vue-slick'
  export default{
    components:{
      slick,
    },
    data(){
      return{
        slickOptions:{
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: true,
          dots: false,
          fade: true,
        },
        host: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        num: this.$store.getters.get_pnum,
        id: this.$route.params['nlid'],
        pMeta:[],
        listContents:[],
        bodyContents:[],
        uInfo:[],
        fcNlList:[],
        detailBody:[],
        title:'',
        images:[],
        year:new Date().getFullYear(),
        month:new Date().getMonth()+1,
        day:new Date().getDate(),
        hour:new Date().getHours(),
        minutes:new Date().getMinutes(),
        seconds:new Date().getSeconds(),
        lastPage:Number,
        fadeapp:true,
        isFirst:true,
        isLast:true,
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    methods:{
       async getData(){
        try{
          const nlList = await this.$axios.get(this.$uri+'/data_output?type=newsletter', { withCredentials: true })
          const nlBody = await this.$axios.get(this.$uri+'/data_output?type=newsletter&id='+this.id, { withCredentials: true })
          this.bodyContents = nlBody.data.contents
          this.pMeta = nlList.data.pageMeta
          let loginAuth = this.pMeta.isAuthenticated
          this.uInfo = this.pMeta.userInfo
          this.listContents = nlList.data.contents
          this.getPageCount()
          if(nlList.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.fcNlList = this.listContents.filter((v)=> v.type == 'newsletter')
            this.detailBody = this.bodyContents[0].content
            this.title = this.detailBody.title
            Object.keys(this.detailBody).forEach((v)=>{
              if(v.match(/^image/g) != void 0){
                this.images.push(this.detailBody[v])
              }
            })
            window.scrollTo(0,0)
            this.reInit()
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      pageMove(to){
        // scrollTo(0,0)
        let nextid
        if(to == 'pre'){
          for(var i=0; i<this.listContents.length; i++){
            if(this.listContents[i].id == this.id){
              nextid = this.listContents[i-1].id
            }
          }
        }else if(to == 'next'){
          for(var k=0; k<this.listContents.length; k++){
            if(this.listContents[k].id == this.id){
              nextid = this.listContents[k+1].id
            }
          }
        }
        this.images.splice(-this.images.length)
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/newsletter/detail/'+nextid});
        this.$router.push('/fanclubtop/newsletter/detail/'+nextid)
      },
      getPageCount(){
        if(this.listContents.length>1){
          for(var i=0; i<this.listContents.length; i++){
            if(this.listContents[i].id == this.id){
              if(i == 0){
                this.$nextTick(() => {
                  this.isFirst = true
                  this.isLast = false
                })
              }else if(i == this.listContents.length-1){
                this.$nextTick(() => {
                  this.isLast = true
                  this.isFirst = false
                })
              }else{
                this.$nextTick(() => {
                  this.isFirst = false
                  this.isLast = false
                })
              }
            }
          }
        }else{
          this.isFirst = true
          this.isLast = true
        }
      },
      chkNew(date){
        if(this.day.length==1){
          this.day = '0'+this.day
        }
        if(this.month.length==1){
          this.month = '0'+this.month
        }
        let now = Date.parse(this.year+'-'+this.month+'-'+this.day)
        let release = Date.parse(date)
        if(now>=release && now<release+604800000){
          return true
        }else{
          return false
        }
      },
      tonlList(){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/newsletter/list/'+this.num});
        this.$router.push('/fanclubtop/newsletter/list/'+this.num)
      },
      datefilter(date){
        if(date != void 0){
          return date.replace(/-/g,'.')
        }
      },
      next() {
        this.$refs.slick.next();
      },
      prev() {
        this.$refs.slick.prev();
      },
      reInit() {
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          this.$refs.slick.create();
        });
      },
    },
    watch:{
      $route(){
        this.id = this.$route.params['nlid']
        this.getData()
      }
    }
  }
</script>