<template>
  <main id="main" class="pt-4 pb-0">
  
    <!-- ▼ ボタン＆バナー -->
    <div class="container normal mb-5">
      <div class="row gx-5">
        <div class="col-md-6 mb-4 mb-md-5" v-if="!isLogin"><a :href="cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+rtn" class="btn-arrow"><span class="tag">会員の方</span><br><span class="big">ログイン</span>はこちら</a></div>
        <div class="col-md-6 mb-4 mb-md-5" v-if="!isLogin"><a :href="cart+'/v2/fcJoin.aspx?ccode=BYU'" class="btn-arrow transparent"><span class="tag">入会ご希望の方</span><br><span class="big">入会</span>はこちら</a></div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 29.3" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1839" data-name="グループ 1839"><g id="FAQ" class="cls-1"><g class="cls-1"><path class="cls-2" d="M8.5,8.23v6.3H7.25V8.23Zm-.43,0H10.9V9.37H8.07Zm0,2.54h2.74v1.08H8.07Z"/><path class="cls-2" d="M13,12.45l0,.29-.82,1.79H10.78L14,7.86l3.21,6.67H15.7l-.8-1.72v-.35L14,10.29Zm-.56-.37h3l.16,1H12.32Z"/><path class="cls-2" d="M17.48,10.06a3.22,3.22,0,0,1,.71-1,3.27,3.27,0,0,1,1.06-.68,3.78,3.78,0,0,1,2.65,0A3.22,3.22,0,0,1,23,9a3.18,3.18,0,0,1,.68,1,3.26,3.26,0,0,1,.25,1.31,3.6,3.6,0,0,1-.24,1.32A3,3,0,0,1,23,13.75a3.27,3.27,0,0,1-1.05.7,3.62,3.62,0,0,1-1.33.25,3.68,3.68,0,0,1-1.35-.25,3.21,3.21,0,0,1-1.06-.7,3.07,3.07,0,0,1-.7-1.05,3.52,3.52,0,0,1-.24-1.32A3.34,3.34,0,0,1,17.48,10.06Zm1.27,2.2a1.87,1.87,0,0,0,1.09,1.11,2.11,2.11,0,0,0,.8.16,2.07,2.07,0,0,0,.8-.15,1.78,1.78,0,0,0,1-1.05,2.43,2.43,0,0,0,.14-.82,2.66,2.66,0,0,0-.14-.85,2.31,2.31,0,0,0-.41-.7,2.07,2.07,0,0,0-.65-.48,2.16,2.16,0,0,0-.86-.17,1.89,1.89,0,0,0-.79.16,1.76,1.76,0,0,0-.64.43,2,2,0,0,0-.41.66,2.43,2.43,0,0,0-.14.82A2.3,2.3,0,0,0,18.75,12.26Zm2.82-.43,2.94,2.78H23.07l-2.9-2.78Z"/></g></g><path class="cls-2" d="M8.06,29.3V23H5.5A5.52,5.52,0,0,1,0,17.5V5.5A5.52,5.52,0,0,1,5.5,0h19A5.52,5.52,0,0,1,30,5.5v12A5.52,5.52,0,0,1,24.5,23H14.35ZM9.56,20h1.5v2.05l2-2.05H24.5A2.51,2.51,0,0,0,27,17.5V5.5A2.52,2.52,0,0,0,24.5,3H5.5A2.51,2.51,0,0,0,3,5.5v12A2.51,2.51,0,0,0,5.5,20Z"/></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                ファンクラブよくある質問
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq/FC" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 29.65" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1842" data-name="グループ 1842"><path id="パス_2370" data-name="パス 2370" class="cls-1" d="M24.91,17.74a11.57,11.57,0,0,1-12.8,9.79l8.4-12A10.16,10.16,0,0,1,19.09,14l-2.66,3.81L11,10h5.14L14,13.11l1.24,1.77,2.45-3.51A9.93,9.93,0,0,1,17,8H9A1,1,0,0,0,8,9a1,1,0,0,0,.19.58l7,10L10,27.05a10.86,10.86,0,0,1-2.49-1.16l5.64-8.06-1.24-1.77-1.24,1.77L4.48,9A11.51,11.51,0,0,1,17.42,5.29a9.8,9.8,0,0,1,.74-1.88,13.53,13.53,0,1,0,8.08,17.34A13.39,13.39,0,0,0,26.93,18,9.84,9.84,0,0,1,24.91,17.74ZM2,16.12A11.54,11.54,0,0,1,3.3,10.87L9.41,19.6,5.86,24.68A11.44,11.44,0,0,1,2,16.12Z"/><path id="パス_2371" data-name="パス 2371" class="cls-1" d="M27,0a8,8,0,1,0,8,8A8,8,0,0,0,27,0Zm-.1,13a1.22,1.22,0,1,1,1.22-1.22h0A1.22,1.22,0,0,1,26.93,13Zm1.91-5.35c-.6.46-.82.63-.82,1.22C28,9.32,28,10,27,10c-.82,0-1.12-.27-1.12-1.07a2.43,2.43,0,0,1,1-2.1c1-.7,1.25-.9,1.25-1.26,0-.64-.86-.64-1-.64-1.06,0-1.13.6-1.17,1s-.53.56-1.12.56c-.38,0-1.14-.08-1.14-.83s.76-2.68,3.51-2.68c2.1,0,3.22,1.3,3.22,2.58,0,1-.51,1.35-1.55,2.18Z"/></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                ファンクラブご利用ガイド
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-4 mb-4 mb-md-5">
          <a href="/faq/EC" class="btn-banner">
            <div class="row align-items-center">
              <div class="col-2 col-md-12 mb-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 30.34" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1834" data-name="グループ 1834"><g id="グループ_1833" data-name="グループ 1833"><path id="パス_2372" data-name="パス 2372" class="cls-1" d="M22.32,23.48a3.39,3.39,0,0,0-2.61,1.23H11.65a3.43,3.43,0,0,0-1.92-1.16l-.41-2H24.59l.61-4.13a9.49,9.49,0,0,1-2.27-1l-.41,2.76H16.78V16.16h5.83a10,10,0,0,1-2.5-2.4H16.78V10.81h1.84a9.36,9.36,0,0,1-.41-2.4H6.65l-.6-2.95H0v2.4H4.08L7.36,23.94a3.42,3.42,0,1,0,5.09,3.18h6.46a3.42,3.42,0,1,0,3.41-3.64Zm-7.93-4.37H8.83l-.6-2.95h6.16Zm-7.25-8.3h7.25v2.95H7.74ZM10,27.11a1,1,0,0,1-2,0,.65.65,0,0,1,0-.2,1,1,0,1,1,2.05,0,.78.78,0,0,1,0,.21Zm12.29.82a1,1,0,0,1-1-.82,1.18,1.18,0,0,1,0-.2,1,1,0,0,1,1-1h0a1,1,0,0,1,0,2.05Z"/><path id="パス_2373" data-name="パス 2373" class="cls-1" d="M28.08,0A7.92,7.92,0,1,0,36,7.92,7.91,7.91,0,0,0,28.08,0ZM28,12.93a1.21,1.21,0,1,1,1.21-1.21h0A1.21,1.21,0,0,1,28,12.93Zm1.89-5.3c-.59.45-.81.62-.81,1.2,0,.41,0,1.07-1,1.07-.82,0-1.12-.27-1.12-1.07a2.4,2.4,0,0,1,1-2.07c1-.7,1.24-.9,1.24-1.26,0-.63-.86-.63-1-.63-1,0-1.11.6-1.16,1s-.52.55-1.1.55c-.38,0-1.14-.08-1.14-.82S25.5,2.9,28.22,2.9c2.09,0,3.2,1.29,3.2,2.57,0,.94-.51,1.33-1.54,2.16Z"/></g></g></g></g></svg>
              </div>
              <div class="col-10 col-md-12 text-center">
                通販ご利用ガイド
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- ▲ ボタン＆バナー -->
    
    <!-- ▼ ファンクラブご利用ガイド -->
    <div id="info">
      <div class="container title-block">
        <h2><div class="container normal">ファンクラブご利用ガイド</div></h2>
      </div>
      <div class="container normal faq">
        <h3>入会手続き</h3>
        <div class="faq-others mb-4 mt-1">
          <p>下記、3通りの方法がございます。</p>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq1_1" aria-expanded="false" aria-controls="faq1_1">内田雄馬公式サイトからのお申し込み</h4>
          <div class="collapse multi-collapse answer" id="faq1_1">
            <ul class="number">
              <li><a href="/tokuten" target="_blank">こちら</a>のページより「申し込みはこちら」のバナーへ進み、会員規約をご確認の上、「同意する」をクリックしてください。</li>
              <li>メールアドレスを入力して入会案内メールを受信してください。
                <ul class="asterisk small">
                  <li>ここで入力したメールアドレスが会員専用ページへのログインアカウントとなります。</li>
                </ul>
              </li>
              <li>入会案内メールの本文に記述されたURLにアクセスして入会手続きを行ってください。</li>
              <li>入会金・会費の支払い方法を選択してください。</li>
              <li>入金後、「入金手続き完了」メールが配信されますので、会員番号をご確認ください。</li>
            </ul>
            <h5>【 支払い方法 】</h5>
            <h5>● クレジットカードでのお支払い</h5>
            <p>入会申し込みが完了した時点で入金・決済が完了となり、会員番号が発行されます。<br>ご利用されたクレジットカード情報が登録され、次年度以降はクレジットカードでの自動継続となります。</p>
            <h5>● コンビニエンスストアでのお支払い</h5>
            <p>入会申し込みが完了した時点では手続きは完了しておりません。<br>必ずコンビニエンスストアにて入金をしてください。<br>お支払いに必要な支払い番号は、「入会申込み受付完了」メールまたは、入会申し込み時点で登録した“メールアドレス”と“パスワード（お客様ご自身で設定いただく半角英数字混合8～20文字）”にてファンクラブ会員専用ページにログイン後、「入会・継続お申込み」から「該当する支払番号の「詳細」」をクリックして その中に記述された支払番号で支払期限までにお支払いください。</p>
            <ul class="asterisk small">
              <li>お支払い期限はお申し込み日から3日以内となります。<br>支払期限までにご入金されなかった場合は、入会申し込みが自動的にキャンセルされます。<br>再度、入会を希望される場合は、手順1からやり直してください。</li>
              <li>内田雄馬公式サイトからの申し込みの場合、別途コンビニエンスストア手数料はかかりません。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq1_2" aria-expanded="false" aria-controls="faq1_2">オンライン（ローチケ）からのお申し込み</h4>
          <div class="collapse multi-collapse answer" id="faq1_2">
            <p>下記URLへアクセスして、お申し込みください</p>
            <a href="http://l-tike.com/fc/uchidayuma-new/" target="_blank">http://l-tike.com/fc/uchidayuma-new/</a>
            <ul class="asterisk small mt-4">
              <li>会費のお支払いは、クレジット決済のみになります。</li>
              <li>入会金・年会費のほかに申込手数料440円（税込）がかかります。</li>
              <li>「入会手続き完了」メールは配信されませんので、お手元に届く会員証にて会員番号をご確認ください。</li>
              <li>入会特典は約1ヶ月～1ヶ月半ほどでお届けいたします。</li>
            </ul>
          </div>
        </div>
        <div class="faq-container">
          <h4 data-bs-toggle="collapse" data-bs-target="#faq1_3" aria-expanded="false" aria-controls="faq1_3">ローソン・ミニストップ店頭Loppi端末からのお申し込み</h4>
          <div class="collapse multi-collapse answer" id="faq1_3">
            <p>ローソン・ミニストップ店頭Loppiトップのメニュー画面よりお申し込みください。</p>
            <ul class="number">
              <li>「各種サービスメニュー」をタッチ</li>
              <li>「スポーツ/コンサート/演劇・ミュージカル・お笑い/会員向けサービス/ファンクラブ入会・継続」をタッチ</li>
              <li>「各種会員向けサービス受付/ファンクラブ入会・継続」をタッチ</li>
              <li>「ファンクラブ入会・継続、スポーツ・イベント等参加申込」をタッチ</li>
              <li>「ジャンル：音楽 あ行」、または「キーワード：うちだゆうま」を入力し、内田雄馬をタッチ</li>
              <li>「新規申込」を選択し、お申込を開始</li>
              <li>画面の案内に従いお客様情報を入力後、申込券を受取り、30分以内にレジカウンターにて代金をお支払いください。</li>
            </ul>
            <ul class="asterisk small">
              <li>入会金・年会費のほかに申込手数料440円（税込）がかかります。</li>
              <li>ドメイン指定受信・メール指定受信をご利用の方は、「l-tike.com」、「lawsonticket.jp」、「ent.lawson.co.jp」を受信できるよう設定してください。</li>
              <li>「入会手続き完了」メールは配信されませんので、お手元に届く会員証にて会員番号をご確認ください。</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container normal">
        <div class="faq-others">
          <h3>期限切れ後の入会手続き</h3>
          <p>下記手順でお手続きいただきますと、以前の登録情報を引き継いで新しくご利用いただくことが可能です。ただし、こちらの手続きを行っても、以前の会員番号を引き継いでのご利用はできません。入会後は新しい会員番号となります。</p>
          <ul class="number">
            <li>こちらのページより「以前の会員番号で登録したメールアドレス」と「ご登録いただいたパスワード（お客様ご自身で設定いただく半角英数字混合8～20文字）」でログインしてください。</li>
            <li>マイページ内「ファンクラブ情報」の「新規入会受付」から入会手続きを行ってください。<br>以前の会員番号で初回ログイン登録を行っていない場合は、通常の入会方法にてお手続きを行ってください。</li>
          </ul>
          <p>なお、以前の会員番号を引き継いでの再入会は受け付けておりません。入会後は新しい会員番号となります。</p>
        </div>
      </div>
      <div class="container normal">
        <div class="faq-others">
          <h3>継続手続き</h3>
          <p>会員期限の約3ヶ月前より継続手続きが可能です。<br>下記の方法にてお手続きください。</p>
          <ul class="number">
            <li><a :href="cart+'/v2/member/fcKiyaku.aspx?ccode=BYU&fcm=BYU'" target="_blank">こちら</a>のページより会員規約をご確認の上、「継続申込み」ボタンをクリックしてください（会員専用ページにログイン後、マイページ内「ファンクラブ情報」の「継続手続き」からもお手続き可能です）。</li>
            <li>会費の支払い方法を選択してください。</li>
            <li>入金・決済完了後、「継続手続き完了」メールが配信されます。会員期限はマイページに記載されていますので、延長されているかご確認ください。</li>
          </ul>
          <h4>【 支払い方法 】</h4>
          <h4>● クレジットカードでのお支払い</h4>
          <p>継続申し込みが完了した時点で決済が完了となり、会員期限が延長されます。<br>ご利用されたクレジットカード情報が登録され、次年度以降はクレジットカードでの自動継続となります。</p>
          <h4>● コンビニエンスストアでのお支払い</h4>
          <p>継続申し込みが完了した時点ではまだ会員期限は延長されません。必ずコンビニエンスストアにて入金をしてください。<br>「継続申し込み受付完了」メール、またはマイページ内「各種履歴」の「入会・継続お申込み」から「該当する支払番号の「詳細」」を表示してその中に記述された払込票番号で支払期限までにお支払いください。<br>支払期限までにご入金されなかった場合は、継続申し込みが自動的にキャンセルされます。<br>再度、継続を希望される場合は、手順1からやり直してください。</p>
          <ul class="asterisk small">
            <li>会員期限終了後は、新規入会手続きをお願いいたします。</li>
          </ul>
        </div>
      </div>
      <div class="container normal">
        <div class="faq-others">
          <h3>クレジットカードによる自動継続</h3>
          <p>クレジットカードによる自動継続とは、クレジットカード情報をあらかじめ登録しておくことで、登録したクレジットカードにて自動で継続手続きを行うシステムです。<br>クレジットカード決済でご入会・ご継続された方は、ご利用されたクレジットカード情報が登録され、次年度以降はクレジットカードでの自動継続となります。</p>
          <p>会員専用ページにログイン後、マイページ内「ファンクラブ情報」の「クレジットカード情報の登録・変更・削除」から設定変更が可能です。</p>
          <p>登録後はクレジットカード情報を削除（登録解除）しない限り、毎年決済がかかります。継続をご希望でない場合は、必ず削除（登録解除）手続きを行ってください。<br>自動継続実施日に何らかの理由で決済エラーになった場合、会員期限までにご自身で手続きをしないと会員期限が切れてしまいます。ご注意ください。</p>
          <p><strong>＜自動継続実施日＞</strong></p>
          <ul class="dot mt-0">
            <li>「会員期限前々月の末日」までにクレジットカード情報の登録を行った場合<br>→「会員期限前月の1日」</li>
            <li>「会員期限前月の1日から会員期限前月末」までにクレジットカード情報の登録を行った場合<br>→「会員期限当月の1日」</li>
            <li>「会員期限当月の1日から会員期限」までにクレジットカード情報の登録を行った場合<br>→「自動継続設定時」</li>
          </ul>
          <p>自動継続を希望されない場合は、<br>必ず「会員期限前々月の末日」までに、会員専用ページにログイン後、マイページ内「ファンクラブ情報」の「クレジットカード情報の登録・変更・削除」からクレジットカード情報の削除を行なってください。</p>
          <p>例：会員期限「2024年11月30日」の場合の継続手続き日<br>2024年9月30日までに設定→2024年10月1日<br>2024年10月1日～2024年10月31日までに設定→2024年11月1日<br>2024年11月1日～2024年11月30日までに設定→設定時に即時決済</p>
          <ul class="asterisk small mt-2">
            <li>自動継続を希望されない場合は「2024年9月30日」までにクレジットカード情報を削除してください</li>
          </ul>
          <ul class="asterisk small">
            <li>自動継続完了後、「ご継続手続き完了のご案内」メールが配信されます。メールが届かない場合は、正常に継続のお手続きが完了していない場合がございますので、マイページにて会員期限をご確認いただくか、会員期限が切れる前にファンクラブにお問い合わせください。</li>
            <li>自動継続手続き完了後のキャンセル・返金はいたしかねます。</li>
          </ul>
        </div>
      </div>
      <div class="container normal">
        <div class="faq-others">
          <h3>お支払い方法詳細</h3>
          <h4>● クレジットカードでのお支払い</h4>
          <p>下記のカードおよび下記の表示のある提携カードがご利用いただけます。</p>
          <p><img src="/images/credit.png" alt="利用可能なクレジットカード" width="285" height="61"></p>
          <h4>● コンビニエンスストアでのお支払い</h4>
          <p>下記のコンビニエンスストアをご利用いただけます。店頭にて会費をお支払いください。</p>
          <p><img src="/images/convenience.png" alt="利用可能なクレジットカード" width="402" height="60"></p>
          <ul class="asterisk small">
            <li>コンビニへの代金のお支払い期限はお申込み日から3日以内となります。</li>
            <li>別途コンビニエンスストアの手数料は掛かりません。</li>
          </ul>
          <h4>【 ご利用方法 】</h4>
          <h4>● ファミリーマート</h4>
          <ul class="number">
            <li>店内に設置されているFamiポート端末トップページより「代金支払い」を選択してください。</li>
            <li>メールに記載の"受付番号（6桁）"を入力し、「OK」を選択してください。</li>
            <li>ファンクラブに登録の"電話番号"を入力し、「OK」を選択してください。</li>
            <li>お支払い内容を確認のうえ、「OK」を選択してください。</li>
            <li>出力される「申込券」を持って、レジにて30分以内にお支払いください。</li>
          </ul>
          <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/familymart.html" target="_blank">こちら</a>にてご確認ください。</p>
          <ul class="asterisk small">
            <li>お支払いは、現金もしくはファミマTカードでのクレジット決済となります。</li>
            <li>代金と引き換えに「受領書」をお渡ししますので、必ずお受取りください。</li>
              <li>「受領書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
          </ul>
          <h4>● ローソン・ミニストップ（MINISTOP Loppi設置店）</h4>
          <ul class="number">
            <li>店内に設置されているLoppi端末より「各種番号をお持ちの方」を選択してください。</li>
              <li>メールに記載の"受付番号（6桁）"を入力し、「次へ」を選択してください。</li>
              <li>ファンクラブに登録の“電話番号”を入力し、「次へ」を選択してください。</li>
            <li>お申し込み内容を確認のうえ、「了解」を選択してください。</li>
            <li>出力される「申込券」を持って、レジにて30分以内にお支払いください。</li>
          </ul>
          <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/lawson.html" target="_blank">こちら</a>にてご確認ください。</p>
          <ul class="asterisk small">
            <li>お支払いは、現金のみとなります。</li>
            <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
            <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
          </ul>
          <h4>● デイリーヤマザキ・ヤマザキデイリーストア</h4>
          <p>オンライン決済番号、もしくはご購入履歴画面から決済画面を印刷したものをレジにてご提示ください。店員に「オンライン決済」とお伝えの上、レジにて代金をお支払いください。お支払いは現金のみとなります。</p>
          <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/yamazaki.html" target="_blank">こちら</a>にてご確認ください。</p>
          <ul class="asterisk small">
            <li>お支払いは現金のみとなります。</li>
            <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
            <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
          </ul>
          <h4>● セイコーマート</h4>
          <ul class="number">
            <li>レジにて「インターネット支払い」とお伝えください。</li>
            <li>メールに記載の"受付番号（6桁）"を入力してください。</li>
            <li>ファンクラブに登録の"電話番号"を入力してください。</li>
            <li>注文内容をご確認の上、代金をお支払いください。お支払いは現金のみとなります。</li>
          </ul>
          <p>詳しくは<a href="https://www.veritrans.co.jp/user_support/seicomart.html" target="_blank">こちら</a>にてご確認ください。</p>
          <ul class="asterisk small">
            <li>お支払いは現金のみとなります。</li>
            <li>代金と引き換えに「領収書」をお渡ししますので、必ずお受取りください。</li>
            <li>「領収書」は、入会・継続の手続きが完了するまで、もしくは商品が届くまで保管をお願いいたします。</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- ▲ ファンクラブご利用ガイド -->
    
    
  </main>
</template>

<script>
  export default{
    data(){
      return{
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        isLogin:false,
        rtn:'',
      }
    },
    created(){
      this.rtn = encodeURIComponent(this.cms+'/system/login?action=login')
      this.chkLogin()
    },
    methods:{
      async chkLogin(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=static', { withCredentials: true })
          if(response.data.pageMeta.isAuthenticated){
            this.isLogin = true
          }else{
            this.isLogin = false
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      }
    },
  }
</script>