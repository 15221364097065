<template>
  <main id="main" class="pt-4">

  <!-- ▼ ボタン＆バナー -->
  <div class="container normal mb-5">
    <div class="row">
      <div class="col-md-6 mb-4 mb-md-5 pe-md-4"><a :href="cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+rtn" class="btn-arrow"><span class="tag">会員の方</span><br><span class="big">ログイン</span>はこちら</a></div>
      <div class="col-md-6 mb-4 mb-md-5 ps-md-4"><a href="/tokuten" class="btn-arrow transparent"><span class="tag">入会ご希望の方</span><br><span class="big">入会</span>はこちら</a></div>
      <div class="col-md-6 mb-4 mb-md-5" v-for="(banner,index) in bList" :key="index" :class="chkNum(index)">
        <a :href="banner.content.bannerLink">
          <picture>
            <img :src="banner.content.bannerImage" :alt="banner.type" width="960" height="220" class="w-100">
          </picture>
        </a>
      </div>
    </div>
  </div>
  <!-- ▲ ボタン＆バナー -->
  
  <!-- ▼ お知らせ -->
  <div id="info">
    <div class="container title-block">
      <h2><div class="container normal">Info</div></h2>
    </div>
    <div class="container normal">
      <div class="thread">
        <!-- ▼ 繰り返しここから -->
        <article v-for="(news,index) in nList.slice(0,5)" :key="index">
          <div class="container normal">
            <div class="row">
              <div class="col-12 col-sm-auto pe-0 order-2 order-sm-1"><span class="date">{{datefilter(news.content.date)}}</span></div>
              <!-- ▼ 新着記事に以下挿入 -->
              <div class="col-auto order-1 order-sm-2" v-if="chkNew(news.content.date)"><span class="new">New!</span></div>
              <!-- ▲ 新着記事に以下挿入 -->
              <div class="col-12 order-3">
                <div :id="'accordion_'+index">
                  <h3 class="title"><a data-bs-toggle="collapse" :data-bs-target="'#collapse_'+index" aria-expanded="false" :aria-controls="'collapse_'+index" class="canClick">{{news.content.title}}</a></h3>
                  <div :id="'collapse_'+index" class="accordion-collapse collapse" aria-labelledby="heading_0" :data-bs-parent="'#accordion_'+index" v-html="news.content.text">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <!-- ▲ 繰り返しここまで -->
        <!-- ※ 開閉式（不使用ですが念の為）
        <article>
          <div class="container normal">
            <div class="row">
              <div class="col-12 col-sm-auto pe-0 order-2 order-sm-1"><span class="date">2021.08.05</span></div>
              <div class="col-12 order-3">
                <div id="accordionExample_2">
                    <h3 class="title" id="heading_2">
                        <a href="#" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="false" aria-controls="collapse_2">記事のタイトルが入りますテキストは仮です</a>
                    </h3>
                    <div id="collapse_2" class="accordion-collapse collapse" aria-labelledby="heading_2" data-bs-parent="#accordionExample_2">
                        <p>記事の本文が入りますテキストは仮です、記事の本文が入りますテキストは仮です、記事の本文が入りますテキストは仮です、記事の本文が入りますテキストは仮です、記事の本文が入りますテキストは仮です、記事の本文が入りますテキストは仮です、記事の本文が入りますテキストは仮です</p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </article>
         -->

        <!-- ▼ 会員限定 -->
        <div class="private">
          <div class="sub-title">
            <div class="container normal">
              <div class="row">
                <div class="col-12">会員限定</div>
              </div>
            </div>
          </div>
          <!-- ▼ 繰り返しここから -->
          <article v-for="fcnews in fcnList.slice(0,5)" :key="fcnews.index">
            <div class="container normal">
              <div class="row">
                <div class="col-12 col-sm-auto pe-0 order-2 order-sm-1"><span class="date">{{datefilter(fcnews.content.date)}}</span></div>
                <div class="col-auto order-1 order-sm-2" v-if="chkNew(fcnews.content.date)"><span class="new">New!</span></div>
                <div class="col-12 order-3">
                  <h3 class="title"><a class="canClick" @click="toFcInfo(fcnews.id)">{{fcnews.content.title}}</a></h3>
                </div>
              </div>
            </div>
          </article>
          <!-- ▲ 繰り返しここまで -->
        </div>
        <!-- ▲ 会員限定 -->
      </div>
    </div>
  </div>
  <!-- ▲ お知らせ -->
  
  <!-- ▼ 会員特典 -->
  <section id="benefits" class="container">
    <h2>会員特典</h2>
    <div class="block bg-theme shadow-inner">
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon text-center col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/phone.svg" width="23" height="34"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>ファンクラブ<br>会員専用ページへのアクセス</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/ticket.svg" width="37" height="22"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>ライブ・イベントの<br>チケット先行優先予約</h3>
                <p class="memo">（抽選になる場合もございます）</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/cake.svg" width="34" height="32"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>バースデーメールの送付</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/mic.svg" width="30" height="35"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>ファンクラブ<br>限定イベントへの参加</h3>
                <p class="memo">（不定期/抽選になる場合もございます）</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/book.svg" width="32" height="28"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>オールカラーの<br>ファンクラブ会報誌の発行</h3>
                <p class="memo">（不定期/年3回）</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/cart.svg" width="33" height="31"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>ファンクラブ<br>限定グッズの販売</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/present.svg" width="31" height="33"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>入会及び継続時に<br>特典プレゼント</h3>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  <!-- ▲ 会員特典 -->
  
  <!-- ▼お友達紹介キャンペーン -->
  <section id="campign">
    <h2>お友達紹介キャンペーン</h2>
    <div class="container nomal">
      <div class="row align-items-center">
        <div class="col-12 col-md-6">
          <div class="h-100 px-0">
            <img src="/images/top/FriendCampaign_img02.png" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="h-100 px-md-0">
            <img src="/images/top/FriendCampaign_txt.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ▲お友達紹介キャンペーン -->

  <!-- ▼ 会員専用ページ コンテンツ -->
  <section id="advantage">
    <h2>会員専用ページ コンテンツ</h2>
    <div class="container normal">
      <div class="row">
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>LETTER</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center">内田雄馬からみなさんへの<br><span class="marker">お手紙を掲載</span>します！</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>MOVIE</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center">Beside YUでしか観られない<br><span class="marker">限定ムービー</span>を公開！</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>DIARY</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center">内田雄馬の<br class="d-sm-none d-md-block d-lg-none"><span class="marker">何気ない日常</span>を切り取った写真を<br>スタッフがPICK UP♪</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>WALL PAPER</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center">ここだけの<span class="marker">壁紙ダウンロード！</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>デジタル会員証</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center"><span class="marker">あなただけのデジタル会員証</span>が<br>表示されます</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 etc">
            <h3>etc……</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ▲ 会員専用ページ コンテンツ -->
  
  <!-- ▼ 注意書き -->
  <div class="container normal">
    <div class="attention top mt-0">
      <p>※特典内容は予告なく変更する場合がございます。</p>
    </div>
  </div>
  <!-- ▲ 注意書き -->
  
</main>
</template>

<script>
  export default{
    data(){
      return{
        cart: process.env.VUE_APP_CARTURI,
        host: process.env.VUE_APP_IAM,
        cms: process.env.VUE_APP_CLIENTAPI,
        pMeta:[],
        contents:[],
        bList:[],
        nList:[],
        fcnList:[],
        nowYear:new Date().getFullYear(),
        nowMonth:String(new Date().getMonth()+1),
        nowDay:String(new Date().getDate()),
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    mounted(){
      window.scrollTo(0,0)
    },
    methods:{
      toFcInfo(id){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/info/detail/'+id});
        this.$router.push('/fanclubtop/info/detail/'+id)
      },
      async getData(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=sitetop')
          this.pMeta = response.data.pageMeta
          this.contents = response.data.contents
          if(response.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else{
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login')
            this.bList = this.contents.filter((v)=> v.type == 'banner')
            this.nList = this.contents.filter((v)=> v.type == 'info')
            this.fcnList = this.contents.filter((v)=> v.type == 'fcinfo')
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      chkNew(date){
        if(this.nowDay.length==1){
          this.nowDay = '0'+this.nowDay
        }
        if(this.nowMonth.length==1){
          this.nowMonth = '0'+this.nowMonth
        }
        let now = Date.parse(this.nowYear+'-'+this.nowMonth+'-'+this.nowDay)
        let release = Date.parse(date)
        if(now>=release && now<release+604800000){
          return true
        }else{
          return false
        }
      },
      chkNum(n){
        if(n%2 === 0){
          return 'pe-md-4'
        }else{
          return 'ps-md-4'
        }
      },
      datefilter(date){
        return date.replace(/-/g,'.')
      },
    }
  }
</script>