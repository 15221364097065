<template>
	<span>
		<header id="topHeader" v-if="isTop">
			<h1>
				<picture> 
					<source type="image/jpeg"
					media="(min-width: 768px)"
					srcset="/images/main_pc02.jpg">
					<img src="/images/main_sp02.jpg" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="1340" height="680" class="w-100">
				</picture>
			</h1>
			<div class="line container">
				<img src="/images/line.png" alt="line" width="380" height="138">
			</div>
		</header>

		<header id="memHeader" v-if="isMem">
			<div class="container">
				<h1>
					<picture> 
						<source type="image/jpeg" media="(min-width: 768px)" srcset="/images/member/mv_pc02.jpg">
						<img src="/images/member/mv_sp02.jpg" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="1280" height="1080" class="w-100">
					</picture>
				</h1>
				<div class="line container">
					<img src="/images/line.png" alt="line" width="380" height="138">
				</div>
			</div>
		</header>

		<span v-if="isOth">
			<div class="logo-container text-md-center container-fluid">
				<a href="/fanclubtop" class="logo">
					<img src="/images/logo.png" alt="Beside YU - YUMA UCHIDA OFFICIAL FANCLUB" width="460" height="58">
				</a>
				<!-- ▼ 会員情報(PC) -->
				<div class="member-info" :class="isMessage ? '' : 'messageOff'">
					<table>
						<tbody>
							<tr>
								<th class="member-number">No.</th>
								<td>{{uInfo.fc_no}}</td>
							</tr>
							<tr>
								<th>お名前</th>
								<td class="member-name">{{uInfo.user_name}}</td>
							</tr>
							<tr>
								<th :class="isDeadMark ? 'deadline':''">有効期限</th>
								<td :class="isThisMonth ? 'text-red':''">{{uInfo.fc_expire_on | dateFilter}}</td>
							</tr>
						</tbody>
					</table>
					<div v-if="isMessage" class="member-right">
						<div class="msg"><span>メッセージ</span>
							<div class="login_msg">
								<p>{{ expireMessage }}</p>
							</div>
						</div>
						<div class="member-info-button">
							<a :href="cart+'/v2/member/MyPage.aspx?ccode=BYU'" class="mypage">
								<img src="/images/mypage.svg" alt="" width="10">マイページ</a>
							<a class="logout canClick" @click="logout()">
								<img src="/images/logout.svg" alt="" width="13">ログアウト</a>
						</div>
					</div>
					<div v-else class="member-info-button">
						<a :href="cart+'/v2/member/MyPage.aspx?ccode=BYU'" class="mypage">
							<img src="/images/mypage.svg" alt="" width="10">マイページ</a>
						<a class="logout canClick" @click="logout()">
							<img src="/images/logout.svg" alt="" width="13">ログアウト</a>
					</div>
				</div>
				<!-- ▲ 会員情報(PC) -->
			</div>
			<header id="header">
				<nav class="navbar navbar-expand-lg navbar-light">
					<div class="d-lg-none toggle-container">
						<div class="container justify-content-end">
							<button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="ナビゲーションの切替">
								<span class="navbar-toggler-icon">
									<span></span>
									<span></span>
									<span></span>
								</span>
							</button>
						</div>
					</div>
					<div class="container justify-content-end list-container">
						<div class="collapse navbar-collapse" id="navbarNav">
							<ul class="navbar-nav">
								<li class="nav-item nav-top">
									<a class="nav-link canClick" aria-current="page" @click="pageMove('')">Top</a>
								</li>
								<li class="nav-item nav-info">
									<a class="nav-link canClick" @click="pageMove('/info/list')">Info</a>
								</li>
								<li class="nav-item nav-letter">
									<a class="nav-link canClick" @click="pageMove('/letter')">Letter</a>
								</li>
								<li class="nav-item nav-pic">
									<a class="nav-link canClick" @click="pageMove('/pic/list')">PIC</a>
								</li>
								<li class="nav-item nav-movie">
									<a class="nav-link canClick" @click="pageMove('/movie/list')">Movie</a>
								</li>
								<li class="nav-item nav-wallpaper">
									<a class="nav-link canClick" @click="pageMove('/wallpaper/list')">Wallpaper</a>
								</li>
								<li class="nav-item nav-blog">
									<a class="nav-link canClick" @click="pageMove('/blog/list')">Staff BLOG</a>
								</li> 
								<li class="nav-item nav-goods">
									<a class="nav-link canClick" @click="pageMove('/goods/list')">Goods</a>
								</li>
								<li class="nav-item nav-kaihou">
									<a class="nav-link canClick" @click="pageMove('/newsletter/list')">会報</a>
								</li>
								<li class="nav-item nav-logout">
									<a class="nav-link canClick" @click="logout()">ログアウト</a>
								</li>
							</ul>
						</div><!-- /.navbar-collapse -->
					</div>
				</nav>
			</header>
		</span>
	</span>
</template>

<script>
	export default{
		filters: {
			dateFilter(v){
				if(v){
					return v.replace(/\//g,'.')
				}
			}
		},
		data(){
			return{
				cart: process.env.VUE_APP_CARTURI,
				path: this.$route.path,
				isTop: false,
				isMem: false,
				isOth: false,
				loginAuth:false,
				isMessage: false,
				isDeadMark: false,
				isThisMonth: false,
				uInfo:[],
				expireMessage: '',
				menuUpdate:[],
			}
		},
		created(){
			// this.chkLogin()
			this.chkPath()
			if(this.path != '/' && this.path.indexOf('/keizoku') == -1 && this.path.indexOf('/tokuten') == -1 && this.path.indexOf('/faq') == -1){
				this.chkMaintenance()
			}
		},
		mounted(){
			this.chkActive()
		},
		methods:{
			async chkMaintenance(){
				try{
					const response = await this.$axios.get(this.$uri+'/data_output', { withCredentials: true})
					this.menuUpdate = response.data.pageMeta.menuUpdates
					this.uInfo = response.data.pageMeta.userInfo
					if(response.data.siteMeta.isInMaintenance){
						window.location.href="/maintenance/maintenance.html"
					}else{
						let menuList = document.getElementsByClassName('nav-link')
						Object.keys(this.menuUpdate).forEach((v,index)=>{
							if(this.menuUpdate[v]){
								menuList[index+1].classList.add('new')
							}
						})
						this.chkExpire(this.uInfo.fc_expire_on)
					}
				}catch(e){
					window.location.href="/error/504.html"
				}
			},
			chkExpire(expire){
				const now = new Date()
				const ex = new Date(expire)

				if(now <= ex){
					//期限以内
					if(now.getFullYear() === ex.getFullYear()) {
						//同年内
						now.setDate('1')
						//各月1日以降のフック
						if(now.getMonth() >= ex.getMonth()-2 && now.getMonth() <= ex.getMonth()){
							//2か月以内同月まで
							this.isDeadMark = true
							if(now.getMonth() == ex.getMonth()-1){
								//前月
								this.isMessage = true
								this.expireMessage = '継続手続き受付中！マイページから継続手続きを行ってください'
							}else if(now.getMonth() == ex.getMonth()){
								//当月
								this.isMessage = true
								this.isThisMonth = true
								this.expireMessage = 'まもなく会員期限が終了します！マイページから継続手続きを行ってください'
							}
						}
					} else if(now.getFullYear() == ex.getFullYear()-1) {
						//年跨ぎ
						if((now.getMonth() == 10 && ex.getMonth() == 0) || (now.getMonth() == 11 && ex.getMonth() == 1)) {
							//"現在11月期限1月"と"現在12月期限2月"の2か月後
							this.isDeadMark = true
						} else if(now.getMonth() == 11 && ex.getMonth() == 0) {
							//"現在12月期限1月"の1か月後
							this.isDeadMark = true
							this.isMessage = true
							this.expireMessage = '継続手続き受付中！マイページから継続手続きを行ってください'
						}
						//同月だったら同じ年なので省略
						//年末と年始以外は比較の必要がないので省略
					}
				} else {
					//期限以降
					this.isDeadMark = true
					this.isThisMonth = true
				}
			},
			chkPath(){
				this.path = this.$route.path
				if(this.path == '/'){
					this.isTop = true
				}else if(this.path == '/tokuten' || this.path == '/keizoku' || this.path == '/faq' || this.path == '/faq/EC' || this.path == '/faq/FC'){
					this.isMem = true
				}else{
					this.isTop = false
					this.isMem = false
					this.isOth = true
				}
			},
			chkActive(){
				let active = document.getElementsByClassName('active')
				if(active.length>0){
					for(var i=0; i<active.length; i++){
						active[i].classList.remove('active')
					}
				}
				this.addActive()
			},
			addActive(){
				var sub
				this.path = this.$route.path
				if(this.path.indexOf('/info') != -1){
					sub = document.getElementsByClassName('nav-info')
				}else if(this.path.indexOf('/letter') != -1){
					sub = document.getElementsByClassName('nav-letter')					
				}else if(this.path.indexOf('/pic') != -1){
					sub = document.getElementsByClassName('nav-pic')					
				}else if(this.path.indexOf('/movie') != -1){
					sub = document.getElementsByClassName('nav-movie')					
				}else if(this.path.indexOf('/wallpaper') != -1){
					sub = document.getElementsByClassName('nav-wallpaper')					
				}else if(this.path.indexOf('/blog') != -1){
					sub = document.getElementsByClassName('nav-blog')					
				}else if(this.path.indexOf('/goods') != -1){
					sub = document.getElementsByClassName('nav-goods')					
				}else if(this.path.indexOf('/newsletter') != -1){
					sub = document.getElementsByClassName('nav-kaihou')					
				}else if(this.path.indexOf('/bc') != -1 || this.path.indexOf('/questionnaire') != -1 ){
					sub=''
				}else{
					sub = document.getElementsByClassName('nav-top')
				}
				if(sub.length>0){
					for(var i=0; i<sub.length; i++){
						sub[i].firstChild.classList.add('active')
					}
				}
			},
			pageMove(to){
				this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop'+to});
				window.location.href= '/fanclubtop'+to
			},
			logout(){
				window.location.href = this.$uri+'/logout'
			},
		},
		watch:{
			'$route' :function(){
				this.path = this.$route.path
				if(this.path != '/'){
					this.chkMaintenance()
				}
				this.chkMaintenance()
				this.chkActive()
			}
		},
	}
</script>

<style scoped>
.messageOff.member-info{
	width: 34.6%;
	min-width: 450px;
}
.messageOff.member-info table{
	width: 63.8%; 
}
.messageOff .member-info-button{
	flex-direction: column;
	justify-content: space-evenly;
	width: 32.8%;
}
.logo-container .messageOff .mypage,
.member-info-sp .messageOff .mypage{
  width: 100%;
	padding: 1rem 2rem 0.86rem 2.71rem;
	height: 40px;
}
.logo-container .messageOff .logout,
.member-info-sp .messageOff .logout{
	width: 100%;
	height: 30px;
}
.messageOff.member-info-sp_area{
	padding-top: 1.3rem;
}
.messageOff .member-info-sp .member-info-button{
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}
.member-info td.text-red {
	color: #e01f17;
}
</style>