<template>
  <main id="main">
    <div class="single-content">
    
      <!-- ▼ メニューリンク -->
      <div class="container">
        <div class="row mx-lg-0 menu-links">
          <div class="col-md-auto mb-4 mb-md-0">
            <a :href="cart+'/v2/member/OrderList.aspx?ccode=BYU'" class="btn-border">申し込み履歴を見る</a>
          </div>
          <div class="col-md-auto mb-4 mb-md-0">
            <a :href="cart+'/v2/ShoppingCart.aspx?ccode=BYU'" class="btn-border">カートを見る</a>
          </div>
          <div class="col-md-auto">
            <a href="/fanclubtop/goods/list" class="btn-border">商品一覧</a>
          </div>
        </div>
      </div>
      <!-- ▲ メニューリンク -->
    
      <!-- ▼ 商品情報 -->
      <div class="container goods-content">
        <div class="row align-items-center">
          <div class="col-12 col-md-7 pe-md-5 mb-4 mb-md-0">
            <div class="slider-container">
              <ul class="slider">
                <slick ref="slick" :options="slickOptions" class="slider kaiho">
                  <li v-for="(image,index) in images" :key="index">
                    <picture>
                      <img :src="host+image.url" :alt="'goods_'+index" width="566" height="477">
                    </picture>
                  </li>
                </slick>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="goods-data">
              <div class="category">{{category}}</div>
              <h1>{{title}}</h1>
              <p v-html="description"></p>
              <div class="cost">¥ {{price | addcomma}}<span class="tax">（税込）</span></div>
              <table class="table">
                <tbody>
                  <tr v-for="(v,index) in variations" :key="index">
                    <td class="name">{{v.title}}</td>
                    <td class="input"><input type="text" class="form-input" v-model="qtybox[index]" @change="chgQty(index)" :disabled="chkStock(index)"></td>
                    <td class="button"><button class="btn-theme" @click="cartIn(v.productCode,index)" :disabled="chkStock(index)">カートに入れる</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- ▲ 商品情報 -->
      
      <!-- ▼ 注意事項 -->
      <div class="container mb-5">
        <div class="attention mt-md-0">
          <ul class="asterisk">
            <li>商品の画像は実際の色味やデザインとは異なる場合がございます。</li>
            <li>実際の商品には”SAMPLE”の表示はございません。</li>
            <li>表示価格は全て消費税込みです。</li>
            <li>表示画像の複製・転載・配布・加工などの二次利用を禁止します。</li>
          </ul>
        </div>
      </div>
      <!-- ▲ 注意事項 -->
      
      <!-- ▼ 一覧に戻る -->
      <div class="container minimum">
        <div class="back text-center">
          <div class="row">
            <div class="col-12">
              <a class="btn-theme auto w-100 canClick" @click="togoods()">一覧に戻る</a>
            </div>
          </div>
        </div>
      </div>
      <!-- ▲ 一覧に戻る -->
      
    </div>
    
  </main>
</template>

<script>
  import slick from 'vue-slick'
  export default{
    components:{
      slick,
    },
    data(){
      return{
        slickOptions:{
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: true,
          dots: true,
          fade: true,
          customPaging: function(slick,index) {
            // スライダーのインデックス番号に対応した画像のsrcを取得
            var targetImage = slick.$slides.eq(index).find('img').attr('src')
            // slick-dots > li の中に上記で取得した画像を設定
            return '<img src=" ' + targetImage + ' "/>'
          },
        },
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        cartUrl:'/v2/ShoppingCart.aspx?scode=SBYU&pcode=',
        host: process.env.VUE_APP_IAM,
        num: this.$store.getters.get_pnum,
        cat: '',
        id: this.$route.params['goodsid'],
        pMeta:[],
        bodyContents:[],
        variations:[],
        uInfo:[],
        date:'',
        title:'',
        price:'',
        category:'',
        images:[],
        description:'',
        qtybox:[],
        qtyLimit:[],
        qtyUrl: '&qty=',
        rtnUrl: '&rtn=',
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    methods:{
       async getData(){
        try{
          const goodsBody = await this.$axios.get(this.$uri+'/data_output?type=goods&id='+this.id, { withCredentials: true })
          this.pMeta = goodsBody.data.pageMeta
          this.uInfo = this.pMeta.userInfo
          let loginAuth = this.pMeta.isAuthenticated
          this.bodyContents = goodsBody.data.contents[0]
          if(goodsBody.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.category = this.bodyContents.categories[0].displayName
            this.title = this.bodyContents.title
            this.price = this.bodyContents.variations[0].price
            this.images = this.bodyContents.images
            this.description = this.bodyContents.ProductDescription1
            this.variations = this.bodyContents.variations
            if(this.variations.length==1){
              this.qtybox[0] = 1
              this.qtyLimit[0] = Number(this.variations[0].quantityLimit)
            }else{
              for(var i=0; i<this.variations.length; i++){
                this.qtybox[i] = 1
                this.qtyLimit[i] = Number(this.variations[i].quantityLimit)
              }
            }
            window.scrollTo(0,0)
            this.rtn = encodeURIComponent(this.host+'/fanclubtop/goods/list/'+this.$store.getters.get_pnum+'?cat='+this.$store.getters.get_category)
            this.reInit()
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      chkStock(i){
        let stock = this.variations[i].stockStatus
        if(stock == 1){
          return false
        }else{
          return true
        }
      },
      chgQty(index){
        if(Number(this.qtybox[index]) < 1){
          this.qtybox.splice(index,1,1)
        }else if(Number(this.qtybox[index]) > Number(this.qtyLimit[index])){
          this.qtybox.splice(index,1,this.qtyLimit[index])
        }
      },
      cartIn(code,index){
        this.cartInURL = this.cart + this.cartUrl + code + this.qtyUrl + this.qtybox[index] + this.rtnUrl + this.rtn
        window.location.href=this.cartInURL
      },
      togoods(){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': '/fanclubtop/goods/list/'+this.num+'?cat='+this.$store.getters.get_category});
        this.$router.push('/fanclubtop/goods/list/'+this.num+'?cat='+this.$store.getters.get_category)
      },
      next() {
        this.$refs.slick.next()
      },
      prev() {
        this.$refs.slick.prev()
      },
      reInit() {
        this.$refs.slick.destroy()
        this.$nextTick(() => {
          this.$refs.slick.create()
        });
      },
    },
    filters: {
      addcomma: function (value) {
        if (!value) return ''
        return value.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,')
      },
    },
  }
</script>