<template>
  <main id="main">
    <div class="single-content">
      
      <!-- ▼ タイトル部分 -->
      <div class="container">
        <header>
          <div class="row">
            <div class="col-12 date">{{datefilter(date)}}</div>
            <div class="col-lg-10"><h1>{{title}}</h1></div>
            <div class="col-lg-2"><a class="canClick category" @click="catMove()">{{category}}</a></div>
          </div>
        </header>
      </div>
      <!-- ▲ タイトル部分 -->
      
      <!-- ▼ 動画 -->
      <div class="container">
        <div class="main-visual">
          <div class="video-block"><iframe :src="'https://player.vimeo.com/video/'+detailBody.movie+'?h=432cc529a3'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
        </div>
      </div>
      <!-- ▲ 動画 -->
      
      <!-- ▼ 注意事項 -->
      <div class="container">
        <div class="attention">
          <p>※次の事項に該当する、もしくはその恐れのある画像の使用は禁止いたします。<br>下記の使用が確認された場合、今後の掲載ができなくなることもございます。</p>
          <ul class="">
            <li>・映像・画像の複製、転載、加工、配布(ウェブサイト、SNSを含む)</li>
            <li>・イメージを損なう使用</li>
            <li>・営利目的の使用</li>
            <li>・その他、ファンクラブの意図に反する使用</li>
          </ul>
        </div>
      </div>
      <!-- ▲ 注意事項 -->
      
      <!-- ▼ 一覧に戻る -->
      <div class="container minimum">
        <div class="back text-center">
          <div class="row">
            <div class="col-6" :style="{visibility : isFirst ? 'hidden' : 'visible'}">
              <a class="canClick btn-theme auto w-100" @click="pageMove('pre')">前へ</a>
            </div>
            <div class="col-6" :style="{visibility : isLast ? 'hidden' : 'visible'}">
              <a class="canClick btn-theme auto w-100" @click="pageMove('next')">次へ</a>
            </div>
            <div class="col-12 mt-4">
              <a class="btn-theme auto w-100 canClick" @click="toMovie()">一覧に戻る</a>
            </div>
          </div>
        </div>
      </div>
      <!-- ▲ 一覧に戻る -->
      
    </div>
  </main>
</template>


<script>
  export default{
    data(){
      return{
        host: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        num: this.$store.getters.get_pnum,
        cat: '',
        id: this.$route.params['movieid'],
        pMeta:[],
        listContents:[],
        bodyContents:[],
        uInfo:[],
        fcmList:[],
        detailBody:[],
        disList:[],
        date:'',
        title:'',
        category:'',
        year:new Date().getFullYear(),
        month:new Date().getMonth()+1,
        day:new Date().getDate(),
        hour:new Date().getHours(),
        minutes:new Date().getMinutes(),
        seconds:new Date().getSeconds(),
        lastPage:Number,
        fadeapp:true,
        isFirst:true,
        isLast:true,
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    mounted(){
      this.setJS()
    },
    methods:{
       async getData(){
        try{
          const movieList = await this.$axios.get(this.$uri+'/data_output?type=movie', { withCredentials: true })
          const movieBody = await this.$axios.get(this.$uri+'/data_output?type=movie&id='+this.id, { withCredentials: true })
          this.pMeta = movieList.data.pageMeta
          this.uInfo = this.pMeta.userInfo
          let loginAuth = this.pMeta.isAuthenticated
          this.listContents = movieList.data.contents
          this.bodyContents = movieBody.data.contents
          this.getPageCount()
          if(movieList.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.fcmList = this.listContents.filter((v)=> v.type == 'movie')
            this.detailBody = this.bodyContents[0].content
            this.date = this.detailBody.date
            this.title = this.detailBody.title
            if(this.$store.getters.get_category == 'all'){
              this.cat = 'all'
            }else{
              this.cat = this.bodyContents[0].categories[0].name
            }
            this.category = this.bodyContents[0].categories[0].displayName
            if(this.cat == 'all'){
              this.disList = this.fcmList
            }else{
              this.disList = this.fcmList.filter((v)=> v.categories[0].name == this.cat)              
            }
            this.getPageCount()
            window.scrollTo(0,0)
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      setJS(){
        let fjsID = document.createElement('script')
        fjsID.setAttribute('src','https://player.vimeo.com/api/player.js')
        document.getElementsByClassName('main-visual')[0].appendChild(fjsID)
      },
      pageMove(to){
        // scrollTo(0,0)
        let nextid
        if(to == 'pre'){
          for(var i=0; i<this.listContents.length; i++){
            if(this.listContents[i].id == this.id){
              nextid = this.listContents[i-1].id
            }
          }
        }else if(to == 'next'){
          for(var k=0; k<this.listContents.length; k++){
            if(this.listContents[k].id == this.id){
              nextid = this.listContents[k+1].id
            }
          }
        }
        this.$router.push('/fanclubtop/movie/detail/'+nextid)
      },
      getPageCount(){
        if(this.disList.length>1){
          for(var i=0; i<this.disList.length; i++){
            if(this.disList[i].id == this.id){
              if(i == 0){
                this.$nextTick(() => {
                  this.isFirst = true
                  this.isLast = false
                })
              }else if(i == this.disList.length-1){
                this.$nextTick(() => {
                  this.isLast = true
                  this.isFirst = false
                })
              }else{
                this.$nextTick(() => {
                  this.isFirst = false
                  this.isLast = false
                })
              }
            }
          }
        }else{
          this.isFirst = true
          this.isLast = true
        }
      },
      chkNew(date){
        if(this.day.length==1){
          this.day = '0'+this.day
        }
        if(this.month.length==1){
          this.month = '0'+this.month
        }
        let now = Date.parse(this.year+'-'+this.month+'-'+this.day)
        let release = Date.parse(date)
        if(now>=release && now<release+604800000){
          return true
        }else{
          return false
        }
      },
      toMovie(){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path':'/fanclubtop/movie/list/'+this.num+'?cat='+this.cat});
        this.$router.push('/fanclubtop/movie/list/'+this.num+'?cat='+this.cat)
      },
      catMove(){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path':'/fanclubtop/movie/list/1?cat='+this.bodyContents[0].categories[0].name});        
        this.$router.push('/fanclubtop/movie/list/1?cat='+this.bodyContents[0].categories[0].name)
      },
      datefilter(date){
        if(date != void 0){
          return date.replace(/-/g,'.')
        }
      },
    },
    watch:{
      $route(){
        this.id = this.$route.params['movieid']
        this.getData()
      }
    }
  }
</script>