//import Vue from 'vue'

const state = {
	pagenum:1,
	category:'all',
}

const mutations = {
	'PAGE_MOVE'(state, val){
		state.pagenum = val
	},
	'CHANGE_CATEGORY'(state, val){
		state.category = val
	},
};

const actions = {
	set_page:({commit},val) => {
		commit('PAGE_MOVE',val)
	},
	slect_category:({commit},val) => {
		commit('CHANGE_CATEGORY',val)
	},
};

const getters = {
	get_pnum: state => {
		return state.pagenum
	},
	get_category: state => {
		return state.category
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
}