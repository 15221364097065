<template>
  <main id="main" class="pt-4">
  
  <!-- ▼ キャッチ -->
  <div class="catch">
    <div class="container text-center">
      <p>一緒に目的地をめざせる場所。<br>
      そのハンドルを握るのは内田雄馬。<br>
      誰も置いていかない様に、<br>
      内田雄馬が連れて行ってくれる。<br>
      助手席や、後部座席など居心地の良い席を<br class="d-md-none">選んで座って欲しい。<br>
      みなさんがナビゲーションできる位置で、<br>
      一緒に挑戦を続けられるように・・・</p>
      <p>キミのそばに / 内田雄馬のそばに<br><span class="font-weight-600">Beside YU</span></p>
    </div>
  </div>
  <!-- ▲ キャッチ -->
  
  <!-- ▼ 会員特典 -->
  <section id="benefits" class="container">
    <h2>会員特典</h2>
    <div class="block bg-theme shadow-inner">
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon text-center col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/phone.svg" width="23" height="34"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>ファンクラブ<br>会員専用ページへのアクセス</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/ticket.svg" width="37" height="22"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>ライブ・イベントの<br>チケット先行優先予約</h3>
                <p class="memo">（抽選になる場合もございます）</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/cake.svg" width="34" height="32"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>バースデーメールの送付</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/mic.svg" width="30" height="35"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>ファンクラブ<br>限定イベントへの参加</h3>
                <p class="memo">（不定期/抽選になる場合もございます）</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/book.svg" width="32" height="28"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>オールカラーの<br>ファンクラブ会報誌の発行</h3>
                <p class="memo">（不定期/年3回）</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mb-3">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/cart.svg" width="33" height="31"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>ファンクラブ<br>限定グッズの販売</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="block h-100 menu">
            <div class="menu-icon col-12 px-0">
              <div class="icon">
                <object type="image/svg+xml" data="/images/top/present.svg" width="31" height="33"></object>
              </div>
            </div>
            <div class="col-12 p-0 d-table">
              <div class="d-table-cell">
                <h3>入会及び継続時に<br>特典プレゼント</h3>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  <!-- ▲ 会員特典 -->
    
  <!-- ▼お友達紹介キャンペーン -->
  <section id="campign">
    <h2>お友達紹介キャンペーン</h2>
    <div class="container nomal">
      <div class="row align-items-center">
        <div class="col-12 col-md-6">
          <div class="h-100 px-0">
            <img src="/images/top/FriendCampaign_img02.png" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="h-100 px-md-0">
            <img src="/images/top/FriendCampaign_txt.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ▲お友達紹介キャンペーン -->

  <!-- ▼ 会員専用ページ コンテンツ -->
  <section id="advantage">
    <h2>会員専用ページ コンテンツ</h2>
    <div class="container normal">
      <div class="row">
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>LETTER</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center">内田雄馬からみなさんへの<br><span class="marker">お手紙を掲載</span>します！</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>MOVIE</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center">Beside YUでしか観られない<br><span class="marker">限定ムービー</span>を公開！</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>DIARY</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center">内田雄馬の<br class="d-sm-none d-md-block d-lg-none"><span class="marker">何気ない日常</span>を切り取った写真を<br>スタッフがPICK UP♪</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>WALL PAPER</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center">ここだけの<span class="marker">壁紙ダウンロード！</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 line">
            <div class="col-12 px-0">
              <h3>デジタル会員証</h3>
            </div>
            <div class="col-12 p-0 d-table flex-grow-1">
              <div class="d-table-cell">
                <p class="text-center"><span class="marker">あなただけのデジタル会員証</span>が<br>表示されます</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4 mb-lg-5">
          <div class="block h-100 etc">
            <h3>etc……</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ▲ 会員専用ページ コンテンツ -->
  
  
  <!-- ▼ 会報 -->
  <section class="container normal">
    <div class="container-block border">
      <h2>Beside YUでしか見られない内田雄馬がたくさんつまった会報誌をお届けします♪</h2>
      <div class="row">
        <div class="order-2 order-md-1 col-md-7 pe-md-5">
          <p class="mb-l">booknumber：07（2024年2月発行）</p>
          <ul class="dot mb-l">
            <li>3rd ALBUM『Y』撮影レポート</li>
            <li>写真集「＆JOY」撮影オフショット</li>
            <li>3rd ALBUM『Y』未公開アザーカットのグラビア</li>
            <li>「Beside YU “2nd” Fanclub Event ～YUnion～」グッズ撮影レポート</li>
            <li>EVENT REPORT</li>
            <li>イラスト投稿コーナー</li>
          </ul>
          <ul class="small asterisk">
            <li v-if="!isTerm">2024 年 3 月 1 日以降にご入会された方は booknumber：08 よりお届けいたします。</li>
            <li v-if="isTerm">「YUMA UCHIDA LIVE “VS YUMA 001- 内田真礼」開催入会キャンペーンとして、5 月 30 日 ～ 6 月 10 日に入会した方には、入会特典と一緒にお送りします！</li>
          </ul>
        </div>
        <div class="order-1 order-md-2 col-md-5 mb-4 mb-md-0">
          <img src="/images/booknumber07.jpg" alt="会報" width="700" height="500" class="w-100">
        </div>
      </div>
    </div>
  </section>
  <!-- ▲ 会報 -->
  
  <!-- ▼ 注意書き -->
  <section class="bg-gray">
    <div class="container normal">
      <div class="container-block wide">
        <h2>注意事項</h2>
        <ul class="dot mini">
          <li>会員期間はご入金月の1日から1年間です。</li>
          <li>会員規約をよくご覧になり、ご了承の上お申し込みくださいますようお願い申し上げます。</li>
          <li>お申し込みいただいた時点で会員規約に同意されたことといたします。また、ご入金後のキャンセルや返金、途中退会は理由を問わず受付いたしかねます。</li>
          <li>会員番号はお申込み順の発行となります。</li>
          <li>お客様にご入力いただいた氏名・住所等が、そのままファンクラブに登録されます。</li>
          <li>パソコンまたはスマートフォン、タブレット等でインターネットを利用できる環境にないと、会員特典の一部を受けられない場合があります。</li>
          <li>「<a href="mailto:&#109;&#97;&#105;&#108;&#64;&#98;&#101;&#115;&#105;&#100;&#101;&#121;&#117;&#46;&#99;&#108;&#117;&#98;">&#109;&#97;&#105;&#108;&#64;&#98;&#101;&#115;&#105;&#100;&#101;&#121;&#117;&#46;&#99;&#108;&#117;&#98;</a>」より登録のメールアドレス宛に確認のメールが自動配信されます。必ず受信できるよう、受信機器の設定をあらかじめご確認ください。</li>
          <li>ファンクラブでは、おひとりにつき1つのみ会員番号を発行しております。既に会員期限が切れている方で再度入会をご希望の方は、新規入会としてお申し込みくださいますようお願い申し上げます。</li>
          <li>ご入会はファンクラブからの発送物を日本国内にて受け取ることができる方のみに限らせていただいております。予めご了承ください。<br>Due to our delivery service management, residential address in Japan is required to join our club.</li>
        </ul>
      </div>
    </div>
  </section>
  <!-- ▲ 注意書き -->
  
  <!-- ▼ 会費 -->
  <section class="container normal">
    <h2>会費</h2>
    <div class="container-block border fee">
      <div class="row justify-content-center align-items-center">
        <div class="col-auto"><div class="tag">初年度会費</div></div>
        <div class="col-auto ps-0 ps-md-3"><div class="cost">¥ 5,500（税込）</div></div>
        <div class="col-12 text-center small">（入会金￥1,100＋年会費￥4,400/税込）</div>
      </div>
    </div>
  </section>
  <!-- ▲ 会費 -->
  
  <div class="container text-center">
    <a :href="cart+'/v2/fcjoin.aspx?ccode=BYU'" class="btn-arrow no-bg">お申し込みはこちら</a>
  </div>
  
  
</main>
</template>

<script>
  export default{
    data(){
      return{
        cart: process.env.VUE_APP_CARTURI,
      }
    },
    computed: {
      isTerm() {
        const date = new Date()
        const start = new Date(2024, 4, 30, 21, 0, 0)
        const limit = new Date(2024, 5, 11, 0, 0, 0)
        if(date >=start && date <= limit){ return true }
        else { return false }
      }
    },
  }
</script>