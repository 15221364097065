<template>
	<main id="main">
		<div class="container">

			<!-- ▼ タイトル -->
			<div class="row title-wrapper m-0">
				<div class="title-content">
					<h1 class="questionnaire"><div class="container visual">Beside YU 発足 2 周年記念 会員限定生配信「雄馬くんに質問」</div></h1>
				</div>
			</div>
			<!-- ▲ タイトル -->
			
			<!-- ▼ コンテンツ -->
			<div class="main-content questionnaire pt-0">
				<div class="container visual">
					<div class="container medium">
						<!-- ▼ 冒頭テキスト -->
						<p>11月29日（金）20:00から行われる、Beside YU 発足3周年記念 会員限定生配信内でご紹介する、内田雄馬への質問を大募集します！</p>
						<p>内田雄馬に聞きたいことを1つ書いてお送りください。<br>
						みなさんからのたくさんの質問、お待ちしています！！</p>
						<!-- ▲ 冒頭テキスト -->
						
						<!-- ▼ 応募締切 -->
						<div class="oubo">
							<p>【募集期間】</p>
							<p>2024年11月21日（木）18:00 ～ 2024年11月26日（火）23:59</p>
						</div>
						<!-- ▲ 応募締切 -->

						<!-- ▼ 注意事項 -->
						<p>【注意事項】</p>
						<ul class="asterisk mt-0 text-small">
							<li>おひとり様１回のみのご応募となります。</li>
							<li>会報、ファンクラブページ（WEB）に掲載する可能性があります。</li>
							<li>メッセージは編集して掲載する場合があります。</li>

						</ul>
						<!-- ▲ 注意事項 -->
						<p class="font-weight-500">【 <span class="text-orange">※</span> 】がついている項目は、入力必須です。</p>
					</div>
					
					<hr>
					
					<div class="container medium">
						<!-- ▼ アンケートフォーム -->
						<form method="post" action="https://survey.modd.com/public/webapi/surveys/c6c1bbb1-6f49-4e22-acc9-8d755638ff15/answers/form" enctype="application/x-www-form-urlencoded">
							
							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2 class="confirm"><span class="required-mark">※</span>Q1.内田雄馬への質問をお書きください。（150文字以内） 文字以内）</h2>
								<div class="answer"><p>{{data[1]}}</p></div>
							</div>
							<!-- ▲ テキストエリア -->
							
							<!-- ▼ テキストエリア -->
							<div class="item">
								<h2 class="confirm">Q2.ペンネームをお書きください（50 字以内）</h2>
								<p>※記載がない場合は、イニシャルで紹介する場合がございます。</p>
								<div class="answer">
									<p>{{data[2]}}</p>
								</div>
							</div>
							<!-- ▲ テキストエリア -->
							
							<input type="hidden" name="i00_userid" :value="data[0]" hidden>
							<input type="hidden" name="i01_textarea01" :value="data[1]" hidden>
							<input type="hidden" name="i02_textarea02" :value="data[2]" hidden>

							<div class="pt-2 pt-md-5">
								<div class="row justify-content-center mt-5">
									<div class="col-auto">
										<a class="canClick btn-border big auto" @click="backInput">戻る</a>
									</div>
									<div class="col-auto">
										<button type="submit" style="border:none; background:unset;"><a class="canClick btn-theme auto px-5">回答する</a></button>
									</div>
								</div>
							</div>

						</form>
						<!-- ▲ アンケートフォーム -->
					</div>
				</div>
			</div>
			<!-- ▲ コンテンツ -->
		</div>
	</main>
</template>

<script>
	export default{
		data(){
			return{
				data:[],
				referrer:this.$router.referrer,
			}
		},
		created(){
			// scrollTo(0,0)
			this.chkReferrer()
		},
		methods:{
			chkReferrer(){
				if(this.referrer.path != '/fanclubtop/questionnaire/questionforyuma20241129'){
					window.location.href = '/fanclubtop'
				}else{
					this.getData()
				}
			},
			getData(){
				this.data = this.$store.getters.get_qnforyuma2024
				
			},
			backInput(){
				this.$router.push('/fanclubtop/questionnaire/questionforyuma20241129')
			},
		},
	}
</script>