<template>
  <main id="main" class="pt-4 pb-0">
  
  <!-- ▼ ボタン＆バナー -->
  <div class="container normal mb-5">
    <div class="row gx-5">
      <div class="col-md-6 mb-4 mb-md-5" v-if="!isLogin"><a :href="cart+'/Login.aspx?ccode=BYU&ReturnUrl='+rtn" class="btn-arrow"><span class="tag">会員の方</span><br><span class="big">ログイン</span>はこちら</a></div>
      <div class="col-md-6 mb-4 mb-md-5" v-if="!isLogin"><a :href="cart+'/fcJoin.aspx?ccode=BYU'" class="btn-arrow transparent"><span class="tag">入会ご希望の方</span><br><span class="big">入会</span>はこちら</a></div>
      <div class="col-md-4 mb-4 mb-md-5">
        <a href="/faq" class="btn-banner">
          <div class="row align-items-center">
            <div class="col-2 col-md-12 mb-md-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 29.3" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1839" data-name="グループ 1839"><g id="FAQ" class="cls-1"><g class="cls-1"><path class="cls-2" d="M8.5,8.23v6.3H7.25V8.23Zm-.43,0H10.9V9.37H8.07Zm0,2.54h2.74v1.08H8.07Z"/><path class="cls-2" d="M13,12.45l0,.29-.82,1.79H10.78L14,7.86l3.21,6.67H15.7l-.8-1.72v-.35L14,10.29Zm-.56-.37h3l.16,1H12.32Z"/><path class="cls-2" d="M17.48,10.06a3.22,3.22,0,0,1,.71-1,3.27,3.27,0,0,1,1.06-.68,3.78,3.78,0,0,1,2.65,0A3.22,3.22,0,0,1,23,9a3.18,3.18,0,0,1,.68,1,3.26,3.26,0,0,1,.25,1.31,3.6,3.6,0,0,1-.24,1.32A3,3,0,0,1,23,13.75a3.27,3.27,0,0,1-1.05.7,3.62,3.62,0,0,1-1.33.25,3.68,3.68,0,0,1-1.35-.25,3.21,3.21,0,0,1-1.06-.7,3.07,3.07,0,0,1-.7-1.05,3.52,3.52,0,0,1-.24-1.32A3.34,3.34,0,0,1,17.48,10.06Zm1.27,2.2a1.87,1.87,0,0,0,1.09,1.11,2.11,2.11,0,0,0,.8.16,2.07,2.07,0,0,0,.8-.15,1.78,1.78,0,0,0,1-1.05,2.43,2.43,0,0,0,.14-.82,2.66,2.66,0,0,0-.14-.85,2.31,2.31,0,0,0-.41-.7,2.07,2.07,0,0,0-.65-.48,2.16,2.16,0,0,0-.86-.17,1.89,1.89,0,0,0-.79.16,1.76,1.76,0,0,0-.64.43,2,2,0,0,0-.41.66,2.43,2.43,0,0,0-.14.82A2.3,2.3,0,0,0,18.75,12.26Zm2.82-.43,2.94,2.78H23.07l-2.9-2.78Z"/></g></g><path class="cls-2" d="M8.06,29.3V23H5.5A5.52,5.52,0,0,1,0,17.5V5.5A5.52,5.52,0,0,1,5.5,0h19A5.52,5.52,0,0,1,30,5.5v12A5.52,5.52,0,0,1,24.5,23H14.35ZM9.56,20h1.5v2.05l2-2.05H24.5A2.51,2.51,0,0,0,27,17.5V5.5A2.52,2.52,0,0,0,24.5,3H5.5A2.51,2.51,0,0,0,3,5.5v12A2.51,2.51,0,0,0,5.5,20Z"/></g></g></g></svg>
            </div>
            <div class="col-10 col-md-12 text-center">
              ファンクラブよくある質問
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mb-4 mb-md-5">
        <a href="/faq/FC" class="btn-banner">
          <div class="row align-items-center">
            <div class="col-2 col-md-12 mb-md-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 29.65" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1842" data-name="グループ 1842"><path id="パス_2370" data-name="パス 2370" class="cls-1" d="M24.91,17.74a11.57,11.57,0,0,1-12.8,9.79l8.4-12A10.16,10.16,0,0,1,19.09,14l-2.66,3.81L11,10h5.14L14,13.11l1.24,1.77,2.45-3.51A9.93,9.93,0,0,1,17,8H9A1,1,0,0,0,8,9a1,1,0,0,0,.19.58l7,10L10,27.05a10.86,10.86,0,0,1-2.49-1.16l5.64-8.06-1.24-1.77-1.24,1.77L4.48,9A11.51,11.51,0,0,1,17.42,5.29a9.8,9.8,0,0,1,.74-1.88,13.53,13.53,0,1,0,8.08,17.34A13.39,13.39,0,0,0,26.93,18,9.84,9.84,0,0,1,24.91,17.74ZM2,16.12A11.54,11.54,0,0,1,3.3,10.87L9.41,19.6,5.86,24.68A11.44,11.44,0,0,1,2,16.12Z"/><path id="パス_2371" data-name="パス 2371" class="cls-1" d="M27,0a8,8,0,1,0,8,8A8,8,0,0,0,27,0Zm-.1,13a1.22,1.22,0,1,1,1.22-1.22h0A1.22,1.22,0,0,1,26.93,13Zm1.91-5.35c-.6.46-.82.63-.82,1.22C28,9.32,28,10,27,10c-.82,0-1.12-.27-1.12-1.07a2.43,2.43,0,0,1,1-2.1c1-.7,1.25-.9,1.25-1.26,0-.64-.86-.64-1-.64-1.06,0-1.13.6-1.17,1s-.53.56-1.12.56c-.38,0-1.14-.08-1.14-.83s.76-2.68,3.51-2.68c2.1,0,3.22,1.3,3.22,2.58,0,1-.51,1.35-1.55,2.18Z"/></g></g></g></svg>
            </div>
            <div class="col-10 col-md-12 text-center">
              ファンクラブご利用ガイド
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-4 mb-4 mb-md-5">
        <a href="/faq/EC" class="btn-banner">
          <div class="row align-items-center">
            <div class="col-2 col-md-12 mb-md-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 30.34" width="28"><g id="レイヤー_2" data-name="レイヤー 2"><g id="Parts"><g id="グループ_1834" data-name="グループ 1834"><g id="グループ_1833" data-name="グループ 1833"><path id="パス_2372" data-name="パス 2372" class="cls-1" d="M22.32,23.48a3.39,3.39,0,0,0-2.61,1.23H11.65a3.43,3.43,0,0,0-1.92-1.16l-.41-2H24.59l.61-4.13a9.49,9.49,0,0,1-2.27-1l-.41,2.76H16.78V16.16h5.83a10,10,0,0,1-2.5-2.4H16.78V10.81h1.84a9.36,9.36,0,0,1-.41-2.4H6.65l-.6-2.95H0v2.4H4.08L7.36,23.94a3.42,3.42,0,1,0,5.09,3.18h6.46a3.42,3.42,0,1,0,3.41-3.64Zm-7.93-4.37H8.83l-.6-2.95h6.16Zm-7.25-8.3h7.25v2.95H7.74ZM10,27.11a1,1,0,0,1-2,0,.65.65,0,0,1,0-.2,1,1,0,1,1,2.05,0,.78.78,0,0,1,0,.21Zm12.29.82a1,1,0,0,1-1-.82,1.18,1.18,0,0,1,0-.2,1,1,0,0,1,1-1h0a1,1,0,0,1,0,2.05Z"/><path id="パス_2373" data-name="パス 2373" class="cls-1" d="M28.08,0A7.92,7.92,0,1,0,36,7.92,7.91,7.91,0,0,0,28.08,0ZM28,12.93a1.21,1.21,0,1,1,1.21-1.21h0A1.21,1.21,0,0,1,28,12.93Zm1.89-5.3c-.59.45-.81.62-.81,1.2,0,.41,0,1.07-1,1.07-.82,0-1.12-.27-1.12-1.07a2.4,2.4,0,0,1,1-2.07c1-.7,1.24-.9,1.24-1.26,0-.63-.86-.63-1-.63-1,0-1.11.6-1.16,1s-.52.55-1.1.55c-.38,0-1.14-.08-1.14-.82S25.5,2.9,28.22,2.9c2.09,0,3.2,1.29,3.2,2.57,0,.94-.51,1.33-1.54,2.16Z"/></g></g></g></g></svg>
            </div>
            <div class="col-10 col-md-12 text-center">
              通販ご利用ガイド
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <!-- ▲ ボタン＆バナー -->
  
  <!-- ▼ 継続案内 -->
  <div id="info">
    <div class="container title-block">
      <h2><div class="container normal">継続案内</div></h2>
    </div>
    <section class="container normal">
      <h2 class="mt-5 pt-3">会費</h2>
      <div class="container-block border fee py-4">
        <div class="row justify-content-center align-items-center">
          <div class="col-auto"><div class="tag">年会費</div></div>
          <div class="col-auto ps-0 ps-md-3"><div class="cost">¥ 4,400（税込）</div></div>
        </div>
      </div>
    </section>
  </div>
  <!-- ▲ 継続案内 -->
  
  <!-- ▼ お申し込み方法 -->
  <section class="bg-gray pb-5 pb-md-0">
    <div class="container normal">
      <div class="container-block wide no-border mb-0">
        <h2>お申し込み方法</h2>
        <div class="text-content">
          <p class="mb-4">下記URLへアクセスして、会員期限内にお申し込みください<br><a :href="cart+'/v2/member/fcKiyaku.aspx?ccode=BYU'">https://cart.besideyu.club/v2/member/fcKiyaku.aspx?ccode=BYU</a></p>
          <p class="mb-4">（例）会員期限が2022/11/30の場合、2022/11/30まで</p>
          <p class="mb-4">
            <span class="danger">会員期間を過ぎた場合は「新規入会」手続きが必要となります。</span><br>
            <span class="danger">新規入会の場合、以前の会員番号はご利用いただけなくなります。</span>
          </p>
          <ul class="dot mini">
            <li>携帯電話（フィーチャーフォン）からはご利用いただけません。</li>
            <li>会費のお支払いは「クレジットカード決済」「コンビニエンスストア支払い」となります。</li>
            <li>年会費のほかに申込手数料220円（税込）がかかります。</li>
          </ul>
          
          <h3 class="mt-6">会員期限が終了した方</h3>
          <p class="mb-4">会員期間終了後は、新規入会手続きをお願いいたします。<br>（例）会員期間が2022/11/30の場合、2022/12/1以降、継続のお手続はできません。<br>その場合、新しい会員番号を付与させていただくため、以前の会員番号はご利用いただけなくなります。<br>予めご了承ください。詳しくは入会案内をご確認ください。</p>
          <ul class="dot mini">
            <li>継続手続きは、ご入金されてから全ての手続きが完了するまでに2週間程度お時間をいただく場合がございます。</li>
            <li>会員期間終了間際に継続手続きをされた場合、会員期間が切れてから、継続手続きが完了するまでの間、会員限定ページにはログインができない場合がございます。</li>
            <li>なお、オンライン手続き以外の継続手続き方法（会場）でお手続きされた場合、継続手続き完了のお知らせは出しておりません。</li>
            <li>最新の会員期間は、ファンクラブ会員専用ページにログインしてご確認ください。</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- ▲ お申し込み方法 -->
  
  
</main>
</template>

<script>
  export default{
    data(){
      return{
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        isLogin:false,
        rtn:'',
      }
    },
    created(){
      this.rtn = encodeURIComponent(this.cms+'/system/login?action=login')
      this.chkLogin()
    },
    methods:{
      async chkLogin(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=static', { withCredentials: true })
          if(response.data.pageMeta.isAuthenticated){
            this.isLogin = true
          }else{
            this.isLogin = false
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      }
    },
  }
</script>