//import Vue from 'vue'

const state = {
	postData:[
	'',
	'',
    '',
	'',
	]
}

const mutations = {
	'CHANGE_QNKARAOKE2024'(state, val){
		state.postData = val
	},
};

const actions = {
	set_qnkaraoke2024:({commit},val) => {
		commit('CHANGE_QNKARAOKE2024',val)
	},
};

const getters = {
	get_qnkaraoke2024: state => {
		return state.postData
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
}