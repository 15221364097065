<template>
  <main id="main">
    <div class="single-content">
      
      <!-- ▼ タイトル部分 -->
      <div class="container">
        <header>
          <div class="row">
            <div class="col-lg-12"><h1 class="birthday">{{title}}</h1></div>
          </div>
        </header>
      </div>
      <!-- ▲ タイトル部分 -->
      
      <!-- ▼ 注意事項 -->
      <div class="container">
        <div class="attention my-5">
          <p>雄馬くんから特別なメッセージが届いています♪</p>
        </div>
      </div>
      <!-- ▲ 注意事項 -->
      
      <!-- ▼ 画像？ -->
      <div class="container">
        <div class="my-5" v-html="body">
        </div>
      </div>
      <!-- ▲ 画像 -->
      
      <!-- ▼ 注意事項 -->
      <div class="container">
        <div class="attention my-5">
          <p>※次の事項に該当する、もしくはその恐れのある画像の使用は禁止いたします。<br>下記の使用が確認された場合、今後の掲載ができなくなることもございます。</p>
          <ul class="">
            <li>・画像の複製、転載、加工、配布（ウェブサイト、SNSを含む）</li>
            <li>・イメージを損なう使用</li>
            <li>・営利目的の使用</li>
            <li>・その他、ファンクラブの意図に反する使用</li>
          </ul>
        </div>
      </div>
      <!-- ▲ 注意事項 -->
      
    </div>
    
  </main>
</template>

<script>
  export default{
    data(){
      return{
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        content:[],
        title:'',
        body:'',
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    methods:{
      async getData(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output/?type=bc', { withCredentials: true })
          let loginAuth = response.data.pageMeta.isAuthenticated
          if(response.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else if(response.data.pageMeta.metaInfo.birthday_error){
            window.location.href = "/fanclubtop"
          }else{
            this.content = response.data.contents[0].content
            this.title = this.content.title
            this.body = this.content.text
            window.scrollTo(0,0)
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
    }
  }
</script>