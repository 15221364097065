<template>
  <main id="main">
    <div class="container">
    
      <!-- ▼ タイトル -->
      <div class="row title-wrapper m-0">
        <div class="title-content">
          <h1><div class="container visual">Wallpaper</div></h1>
        </div>
      </div>
      <!-- ▲ タイトル -->
      
      <div class="row">
        <div class="main-content pt-0">
          <div class="container visual" :class="fadeapp ? 'appear':'vanish'">
          
            <!-- ▼ 一覧 -->
            <div class="row wallpaper mx-lg-0">
              <!-- ▼ 繰り返しここから -->
              <div class="col-12 col-md-6 col-lg-4 mb-4" v-for="wp in fcwList.slice(minSlice,minSlice+perPage)" :key="wp.index">
                <article>
                  <h2>{{wp.content.title}}</h2>
                  <div class="row">
                    <div class="col-7">
                      <picture>
                        <img :src="host+wp.content.image" :alt="wp.content.title" width="180" height="285">
                      </picture>
                    </div>
                    <div class="col-5 ps-0">
                      <ul>
                        <li><a target="_blank" :href="host+wp.content.image1792x828+'?width=828'" class="btn-size" :class="chkAble(wp.content.image1792x828)">1792 × 828</a></li>
                        <li><a target="_blank" :href="host+wp.content.image2048x1536+'?width=1536'" class="btn-size" :class="chkAble(wp.content.image2048x1536)">2048 × 1536</a></li>
                        <li><a target="_blank" :href="host+wp.content.image1080x1980+'?width=1980'" class="btn-size" :class="chkAble(wp.content.image1080x1980)">1080 × 1980</a></li>                        
                      </ul>
                    </div>
                  </div>
                </article>
              </div>
              <!-- ▲ 繰り返しここまで -->
            </div>
            <!-- ▲ 一覧 -->
            
            <!-- ▼ 注意書き -->
            <div class="row mx-lg-0">
              <div class="col-12">
                <div class="attention">
                  <p>※お好みの壁紙サイズのリンクをクリックし、表示された画像を保存してお使いください。<br>
                  ※画像は、会員様個人の端末に限りダウンロードおよび使用を許可するものです。<br>
                  ※次の事項に該当する、もしくはその恐れのある画像の使用は禁止いたします。<br>下記の使用が確認された場合、今後の掲載ができなくなることもございます。</p>
                  <ul class="">
                    <li>・画像の複製、転載、加工、配布(ウェブサイト、SNSを含む)</li>
                    <li>・イメージを損なう使用</li>
                    <li>・営利目的の使用</li>
                    <li>・その他、ファンクラブの意図に反する使用</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- ▲ 注意書き -->
            
          </div>
        </div>
      </div>
        
      <!-- ▼ ページャー -->
      <div id="pager" :hidden="!isVisible">
        <ul class="list-group list-group-horizontal justify-content-center">
          <li class="list-group-item before" :class="isFirst ? 'none':''"><a class="canClick" @click="preClick()"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16.8" width="13" height="18"><g id="レイヤー_2" data-name="レイヤー 2"><g id="レイヤー_2-2" data-name="レイヤー 2"><path id="パス_714" data-name="パス 714" class="cls-1" d="M4.7,8.4l2.84-2L9.3,5.18v6.45L7.54,10.4,4.7,8.4M0,8.4l6,4.2,6,4.2V0L6,4.2,0,8.4Z"/></g></g></svg></span></a></li>
          <li class="list-group-item" v-if="(lastPage>5)&&(curPage>=4)"><a class="dotted"><span>…</span></a></li>
          <span v-for="page of lastPage" :key="page.index">
            <li class="list-group-item" v-if="chkVisiblePage(page)" :class="activeNum(page)"><a class="canClick" @click="pageClick(page)"><span>{{page}}</span></a></li>
          </span>
          <li class="list-group-item" v-if="(lastPage>5) && (Number(curPage)+2<lastPage)"><a class="dotted"><span>…</span></a></li>
          <li class="list-group-item after" :class="isLast ? 'none':''"><a class="canClick" @click="nextClick()"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16.8" width="13" height="18"><g id="レイヤー_2" data-name="レイヤー 2"><g id="レイヤー_2-2" data-name="レイヤー 2"><path id="パス_714" data-name="パス 714" class="cls-1" d="M7.3,8.4l-2.84,2L2.7,11.63V5.18L4.46,6.41l2.84,2m4.7,0L6,4.2,0,0V16.8l6-4.2,6-4.2Z"/></g></g></svg></span></a></li>
        </ul>
      </div>
      <!-- ▲ ページャー -->
      
    </div>
    
  </main>
</template>

<script>
  export default{
    data(){
      return{
        host: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        pMeta:[],
        contents:[],
        uInfo:[],
        fcwList:[],
        year:new Date().getFullYear(),
        month:new Date().getMonth()+1,
        day:new Date().getDate(),
        hour:new Date().getHours(),
        minutes:new Date().getMinutes(),
        seconds:new Date().getSeconds(),
        num:Number,
        minSlice:Number,
        perPage:9,
        curPage:Number,
        lastPage:Number,
        fadeapp:true,
        isFirst:false,
        isLast:false,
        isVisible:false,
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    methods:{
       async getData(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output?type=wallpaper', { withCredentials: true })
          this.pMeta = response.data.pageMeta
          this.uInfo = this.pMeta.userInfo
          let loginAuth = this.pMeta.isAuthenticated
          this.contents = response.data.contents
          if(response.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.fcwList = this.contents.filter((v)=> v.type == 'wallpaper')
            this.getPageCount()
            this.setPaging()
            window.scrollTo(0,0)
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
      setPaging(){
        this.num = this.$route.params['num']
        if(isNaN(this.num)){
          this.num = this.$store.getters.get_pnum
        }
        if(this.num <= this.lastPage){
          this.$store.commit('PAGE_MOVE',this.num)
          this.minSlice = (this.num-1)*this.perPage;
          this.curPage = this.num;
        }else{
          this.minSlice = 0;
          this.curPage = 1;
          this.$store.commit('PAGE_MOVE',Number(this.curPage))
        }
      },
      pageClick(page){
        this.fadeOut()
        // scrollTo(0,0)
        if(page<this.curPage){
          this.curPage=this.curPage-1
        }else if(page>this.curPage){
          this.curPage=this.curPage+1
        }
        this.minSlice = (page-1)*this.perPage;
        this.curPage = Number(page);
        this.$store.commit('PAGE_MOVE',this.curPage)
        this.tagMove({path:'/fanclubtop/wallpaper/list/'+page, params:{}})
        setTimeout(this.fadeIn,400)
        //window.history.pushState(null,null,'/products/'+page);
      },
      preClick(){
        if(!(Number(this.curPage)<=1)){
          this.pageClick(Number(Number(this.curPage))-1);
        }
      },
      nextClick(){
        if(Number(this.curPage)!==this.lastPage){
          this.pageClick(Number(Number(this.curPage))+1);
        }
      },
      activeNum(page){
        if (Number(this.curPage)==1) {
          this.isFirst = true
          this.isLast = false
        }else if(Number(this.curPage)==this.lastPage){
          this.isLast = true
          this.isFirst = false
        }else{
          this.isFirst = false
          this.isLast = false
        }
        if(page==Number(this.curPage)){
          return 'active';
        }
      },
      getPageCount(){
        this.lastPage = Math.ceil(this.fcwList.length / this.perPage);
        if(this.lastPage<=1){
          this.isVisible=false;
        }else{
          this.isVisible=true;
        }
      },
      chkVisiblePage(num){
        if(this.lastPage>5){
          //総ページ５以上
          if(num>=Number(this.curPage)-2 && num<=Number(this.curPage)+2){
            //総ページ５以上 && 現在の前後２ページ
            return true
          }else if(Number(this.curPage)>this.lastPage-4){
            if(Number(this.curPage)>=this.lastPage-1){
              if(this.lastPage-num<=4){
                return true
              }else{
                return false
              }
            }else{
              return false
            }
          }else if(Number(this.curPage)<=2){
            if(num<=5){
              return true
            }else{
              return false
            }
          }else{
            return false
          }
        } else {
          //5ページまでは無条件にok
          return true
        }
      },
      chkAble(val){
        if(val == ""){
          return "disable"
        }
      },
      tagMove(to){
        this.$gtag.query('config', 'G-7QXE3V2MV2', {'page_path': to.path});
        this.$router.push({'path':to.path, 'params':to.params})
      },
      fadeIn(){
        this.fadeapp = true
      },
      fadeOut(){
        this.fadeapp = false
      },
    }
  }
</script>