<template>
  <main id="main">
    <div class="single-content">
      
      <!-- ▼ タイトル部分 -->
      <div class="container">
        <header>
          <div class="row">
            <div class="col-12 date">{{date}}</div>
            <div class="col-lg-12"><h1 class="birthday">{{content.title}}</h1></div>
          </div>
        </header>
      </div>
      <!-- ▲ タイトル部分 -->
      
      <!-- ▼ 画像 -->
      <div class="container narrow">
        <div class="main-visual" onCopy="return false;" v-html="content.text">
        </div>
      </div>
      <!-- ▲ 画像 -->
      
    </div>
    
  </main>
</template>

<script>
  export default{
    data(){
      return{
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        content:[],
        date:'',
        rtn:'',
      }
    },
    created(){
      this.getData()
    },
    methods:{
      async getData(){
        try{
          const response = await this.$axios.get(this.$uri+'/data_output/?type=letter', { withCredentials: true })
          let loginAuth = response.data.pageMeta.isAuthenticated
          if(response.data.siteMeta.isInMaintenance){
            window.location.href="/maintenance/maintenance.html"
          }else if(!loginAuth){
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          }else{
            this.content = response.data.contents[0].content
            this.date = this.content.date.replace(/-/g,'.')
            window.scrollTo(0,0)
          }
        }catch(e){
          window.location.href="/error/504.html"
        }
      },
    }
  }
</script>